import { ButtonProps } from "@mui/material";
import CclButton from "./cclButton";

export const CclUnrestrictedOutlinedButton: React.FC<ButtonProps> = (props) => {
    return (
        <CclButton
            restricted={false}
            mode="secondary"
            {...props}
        >
            {props.children}
        </CclButton>
    );
};

export default CclUnrestrictedOutlinedButton;
