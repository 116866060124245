import * as React from "react";
import { AlertColor, Box, Grid, Stack } from "@mui/material";
import FlexGrid from "../../../../layouts/flexGrid";
import ApplicationUsersDataGrid from "./applicationUsersDataGrid";
import {
    useGetCclSystemQuery,
    useLazyGetGroupsByTenantIdQuery,
    useLazyGetCclUserApplicationAccountsQuery,
    useSearchCclApplicationUsersMutation,
    useUpdateUserAccountMutation,
} from "../../../../services/cclTokenedGrandCentralApi";
import CclErrorDialog from "../../../../components/common/cclErrorDialog";
import {
    ApplicationUsersSearchListConfigState,
    updateApplicationsUsersSearchParams,
} from "../../../../app/slices/applicationUsersSearchListConfigSlice";
import { useDispatch, useSelector } from "react-redux";
import {
    ApplicationGroups,
    PersonGrandCentralDocument,
} from "../../../../services/types/search.service.types";
import { Application } from "../../../../services/types/cclGrandCentralApiTypes";
import {
    ApplicationAccount,
    UpdateUserAccountRequest,
    UpdateUserAccountRequestPayload,
} from "../../../../services/types/rtkQueryTypes";
import { AccessEventApplicationDetails } from "../../../../services/types/accessEventTypes";
import PageLoader from "../../../../components/common/pageLoader";
import useLogAccessEvent from "../../../../hooks/useLogAccessEvent";
import CclDoubleTextSearchBar from "../../../../components/common/cclLandingPageSearchBars/cclDoubleTextSearchBar";
import CclTextSearchBar from "../../../../components/common/cclLandingPageSearchBars/cclTextSearchBar";
import UserEditApplicationDrawer from "../../../users/userDetail/applications/userEditApplicationDrawer";
import CclButton from "../../../../components/common/cclButtons/cclButton";
import CclSearchDefault from "../../../../components/common/cclSearchDefault";
import ComponentLoader from "../../../../components/common/componentLoader";
import CclAlertSnackbar from "../../../../components/common/cclAlertSnackbar";

interface ApplicationUsersPanelProps {
    application: Application | undefined;
}

const ApplicationUsersPanel: React.FC<ApplicationUsersPanelProps> = (props) => {
    const dispatch = useDispatch();
    const config: ApplicationUsersSearchListConfigState = useSelector(
        (state: any) => state.applicationUsersListConfig
    );

    const [users, setUsers] = React.useState<PersonGrandCentralDocument[]>([]);
    const [selectedUserEmail, setSelectedUserEmail] = React.useState<string>("");
    const [showLoader, setShowLoader] = React.useState<boolean>(false);
    const [showEditDrawer, setShowEditDrawer] = React.useState<boolean>(false);
    const [showSearchToBroad, setShowSearchToBroad] = React.useState<boolean>(false);
    const [showSnackbar, setShowSnackbar] = React.useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = React.useState<AlertColor | undefined>("info");
    const [userAccount, setUserAccount] = React.useState<ApplicationAccount | null>(null);

    const { data: system } = useGetCclSystemQuery(props.application?.systemId ?? "", {
        skip: props.application === undefined,
    });
    const [getGroups, { data: tenantGroups }] = useLazyGetGroupsByTenantIdQuery();
    const [getUserAccount, { data: userAccounts }] = useLazyGetCclUserApplicationAccountsQuery();
    const [searchUsers, { isLoading, isSuccess }] = useSearchCclApplicationUsersMutation();
    const { logEvent } = useLogAccessEvent();
    const [updateUserAccount, { isLoading: updateIsLoading }] = useUpdateUserAccountMutation();

    React.useEffect(() => {
        if (userAccounts === undefined || system === undefined) return;

        const useraccts = userAccounts.filter((a) =>
            system?.tenants.some((t) => t.tenantId === a.applicationId)
        );
        if (useraccts.length > 0) {
            // for now just take the first tenant/user. This is almost always right but would fail if a person is a user under multiple tenants in the application (really system in GC)
            setUserAccount(useraccts[0] ?? null);
            getGroups(useraccts[0].applicationId)
                .unwrap()
                .then(() => {
                    setShowEditDrawer(true);
                    setShowLoader(false);
                });
        }
    }, [userAccounts, system, getGroups]);

    const ShowSnackbarElement = (message: string, severity: AlertColor) => {
        setShowSnackbar(true);
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
    };

    const handleSearchUsers = (firstName: string, lastName: string, email: string) => {
        if (props.application && props.application.applicationId.length > 0) {
            searchUsers({
                emailAddress: email,
                firstName: firstName,
                lastName: lastName,
                applicationId: props.application.applicationId,
            })
                .unwrap()
                .then((response) => {
                    const newUsers = response === undefined ? [] : [...response];
                    setUsers(newUsers);
                    if (response && response.length >= 30000) setShowSearchToBroad(true);
                });
            dispatch(
                updateApplicationsUsersSearchParams({
                    FirstName: firstName ?? "",
                    LastName: lastName ?? "",
                    Email: email ?? "",
                    IsEmailSearchActive: email !== undefined && email?.length > 0,
                })
            );
        }
    };

    const updateUserApplication = (
        enabled: boolean,
        newGroups: string[] | undefined,
        expiration: Date | null
    ) => {
        if (userAccount === null) return;
        const addGroups: string[] =
            newGroups?.filter((g) => !userAccount?.groups?.includes(g)) ?? [];
        const addGroupIds: string[] =
            addGroups.length === 0
                ? []
                : tenantGroups
                      ?.filter((g) => addGroups.some((ag) => ag === g.name))
                      .map((g) => g.groupId) ?? [];
        const removeGroups: string[] =
            userAccount?.groups?.filter((g) => !newGroups?.includes(g)) ?? [];
        const removeGroupIds: string[] =
            removeGroups.length === 0
                ? []
                : tenantGroups
                      ?.filter((g) => removeGroups.some((ag) => ag === g.name))
                      .map((g) => g.groupId) ?? [];

        let payload: UpdateUserAccountRequestPayload = {
            accountId: userAccount.accountId,
            enabled: enabled,
            expirationDate: expiration,
            addToGroups: addGroupIds,
            removeFromGroups: removeGroupIds,
        };

        let request: UpdateUserAccountRequest = {
            userId: userAccount.userId,
            payload: payload,
        };

        updateUserAccount(request)
            .unwrap()
            .then(() => {
                let oldAppAccount = users.find((u) => u.accountId === userAccount.accountId);

                const evtData: AccessEventApplicationDetails = {
                    applicationName: props.application?.systemName ?? "",
                    applicationId: userAccount.applicationId,
                    imKey: +(oldAppAccount?.imKey ?? "-1"),
                    email: oldAppAccount?.accountEmails[0].Email ?? "unknown",
                };
                logEvent("UserApplicationAccountEdited", evtData);

                // update user record. NOTE: This assumes a user is only a member of a single tenant in a system.
                if (oldAppAccount == null) return;
                let newUsers = users.filter((u) => u.accountId !== userAccount.accountId);
                let newAppAccount: PersonGrandCentralDocument = {
                    accountId: oldAppAccount.accountId,
                    firstName: oldAppAccount.firstName,
                    lastName: oldAppAccount.lastName,
                    accountEmails: [...oldAppAccount.accountEmails],
                    dateCreated: oldAppAccount.dateCreated,
                    accountApplications: [
                        {
                            applicationName: oldAppAccount.accountApplications[0].applicationName,
                            applicationId: oldAppAccount.accountApplications[0].applicationId,
                            applicationEnabled: enabled,
                            applicationGroups:
                                newGroups?.map((g) => {
                                    return { groupName: g } as ApplicationGroups;
                                }) ?? [],
                        },
                    ],
                    imKey: oldAppAccount.imKey,
                };
                newUsers.push(newAppAccount);
                setUsers([...newUsers]);
                ShowSnackbarElement("Account Updated", "success");
                setShowEditDrawer(false);
            })
            .catch(() => {
                ShowSnackbarElement("Account Update Failed", "error");
                setShowEditDrawer(false);
            });
    };

    const handleEditBtnClick = (userEmail: string) => {
        setSelectedUserEmail(userEmail);
        setShowLoader(true);
        getUserAccount(userEmail);
    };

    return (
        <Stack width={1} height={1} spacing={2}>
            {showLoader ? <PageLoader msg={""} /> : null}
            {userAccount !== null && tenantGroups !== undefined ? (
                <UserEditApplicationDrawer
                    emailAddress={selectedUserEmail}
                    userAccount={userAccount}
                    open={showEditDrawer}
                    onOk={updateUserApplication}
                    onCancel={() => setShowEditDrawer(false)}
                    tenantGroups={
                        [...tenantGroups!]?.sort((a, b) => {
                            return a.name > b.name ? 1 : -1;
                        })!
                    }
                    updating={updateIsLoading}
                />
            ) : null}
            {showSnackbar ? (
                <CclAlertSnackbar
                    open={true}
                    onClose={() => setShowSnackbar(false)}
                    message={snackbarMessage}
                    severity={snackbarSeverity}
                />
            ) : null}
            <CclErrorDialog
                open={showSearchToBroad}
                title={"Too Many Results"}
                msg={
                    "There are too many results for this search. Consider adjusting your search parameters and searching again."
                }
                onOk={() => setShowSearchToBroad(false)}
            />
            <Box width={1} sx={{ display: "flex", flexDirection: "row" }}>
                <CclTextSearchBar
                    initialSearchTerm={config.Email}
                    searchLabel="Enter User Email"
                    executeSearch={(searchTerm: string) => handleSearchUsers("", "", searchTerm)}
                />
                <CclDoubleTextSearchBar
                    initialFieldOneSearchTerm={config.FirstName}
                    fieldOneSearchLabel={"Enter First Name"}
                    initialFieldTwoSearchTerm={config.LastName}
                    fieldTwoSearchLabel={"Enter Last Name"}
                    executeSearch={(fieldOneSearchTerm: string, fieldTwoSearchTerm: string) =>
                        handleSearchUsers(fieldOneSearchTerm, fieldTwoSearchTerm, "")
                    }
                    requireBothFields={false}
                    suppressErrors={false}
                />
                <Grid
                    container
                    spacing={1}
                    padding={2}
                    sx={{ maxWidth: "fit-content", height: "100%" }}
                >
                    <Grid item>
                        <CclButton
                            onClick={() => handleSearchUsers("", "", "")}
                            aria-label="Get All Users"
                            disabled={props.application === undefined}
                            sx={{
                                height: 1,
                            }}
                            restricted={false}
                            mode={"primary"}
                        >
                            Get All
                        </CclButton>
                    </Grid>
                </Grid>
            </Box>
            {isLoading ? (
                <ComponentLoader msg={"Loading"} />
            ) : isSuccess ? (
                <FlexGrid>
                    <ApplicationUsersDataGrid
                        users={users}
                        isLoading={isLoading}
                        showResultBar={false}
                        resultBarText={""}
                        editButtonHandler={handleEditBtnClick}
                    />
                </FlexGrid>
            ) : (
                <CclSearchDefault
                    line1="Start searching for Users"
                    line2="Enter User Email or enter First and Last Name and click Search or click Get All"
                    hasButtons={false}
                />
            )}
        </Stack>
    );
};

export default ApplicationUsersPanel;
