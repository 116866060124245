import * as React from "react";
import { Grid, Stack } from "@mui/material";
import { useGetCclParticipantsByEventKeyQuery } from "../../../../../services/cclTokenedEnterpriseParticipantApi";
import { SessionDetailAssignedCoach } from "../../../../../services/types/sessionDetailTypes";
import ComponentLoader from "../../../../../components/common/componentLoader";
import { PersonInfo } from "../../../../../models/personInfo";
import NwaCoachAssignmentParticipantList from "./nwaCoachAssignmentParticipantList";
import NwaCoachAssignmentList from "./nwaCoachAssignmentList";

interface NwaCoachAssignmentSubPanelProps {
    sessionId: string;
    assignedCoaches: SessionDetailAssignedCoach[];
    updateCoachAssignment: (
        paxImKey: number,
        coachImKey: number,
        isPrimary: boolean,
        selected: boolean
    ) => void | undefined;
    removeCoachesAssignments: (coach: PersonInfo, isPrimary: boolean) => void;
    assignAllToCoach: (coach: PersonInfo) => void;
}

const NwaCoachAssignmentSubPanel: React.FC<NwaCoachAssignmentSubPanelProps> = (props) => {
    const [selectedCoach, setSelectedCoach] = React.useState<PersonInfo | null>(null);
    const {
        data: allParticipants,
        isLoading: paxLoading,
        isSuccess: paxSuccess,
    } = useGetCclParticipantsByEventKeyQuery(props.sessionId, { skip: props.sessionId === "" });

    return (
        <Stack height={1} width={1} sx={{ justify: "space-between" }}>
            <Grid
                container
                spacing={2}
                alignItems="stretch"
                sx={{ justify: "space-between", flexGrow: 1 }}
            >
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{ display: "flex", flexDirection: "column", width: 1, height: 1 }}
                >
                    {paxSuccess && (
                        <NwaCoachAssignmentList
                            sessionId={props.sessionId}
                            allParticipants={allParticipants?.filter(
                                (p) => p.registrationStatus === "Confirmed"
                            )}
                            assignedCoachList={props.assignedCoaches}
                            onCoachSelect={(coach: PersonInfo | null) => setSelectedCoach(coach)}
                            unassignCoachesParticipants={(coach: PersonInfo) =>
                                props.removeCoachesAssignments(coach, false)
                            }
                        />
                    )}
                    {paxLoading && <ComponentLoader msg={"Loading Coaches"} />}
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{ display: "flex", flexDirection: "column", width: 1, height: 1 }}
                >
                    {paxSuccess && (
                        <NwaCoachAssignmentParticipantList
                            allParticipants={allParticipants?.filter(
                                (p) => p.registrationStatus === "Confirmed"
                            )}
                            assignedCoaches={props.assignedCoaches}
                            selectedCoach={selectedCoach}
                            onPaxChange={props.updateCoachAssignment}
                            assignAllToCoach={props.assignAllToCoach}
                        />
                    )}
                    {paxLoading && <ComponentLoader msg={"Loading Participants"} />}
                </Grid>
            </Grid>
        </Stack>
    );
};

export default NwaCoachAssignmentSubPanel;
