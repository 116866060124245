import { add, startOfToday } from "date-fns";

export function getDateAdjusted(offsetDays: number): Date {
    return add(startOfToday(), {
        days: offsetDays,
    });
}

export function getDateStringAdjusted(offsetDays: number): string {
    var dt = add(startOfToday(), {
        days: offsetDays,
    });

    return dt.toISOString();
}
