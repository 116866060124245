import * as React from "react";
import {
    DataGridPro,
    GRID_CHECKBOX_SELECTION_COL_DEF,
    GridColDef,
    GridColumnGroup,
    GridColumnGroupingModel,
    GridColumnVisibilityModel,
    GridRenderCellParams,
    GridSelectionModel,
} from "@mui/x-data-grid-pro";
import { Alert, Link } from "@mui/material";
import { Stack } from "@mui/system";
import { ProgramParticipantScoringStatus } from "../../../../../../services/types/scoringStatusTypes";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AssessmentStatusGroupHeader from "./assessmentStatusGroupHeader";
import RaterStatusPill from "./raterStatusPill";
import ScoringStatusPill from "./scoreStatusPill";
import SelfStatusPill, { SelfStatusPillProps } from "./selfStatusPill";
import AssessmentStatusDataGridToolbar from "./assessmentStatusDataGridToolbar";
import RaterSummaryStatusPill from "./raterSummaryStatusPill";
import { useGetAppUrlsQuery } from "../../../../../../services/cclTokenedSettingsApi";
import { AccessEventSessionDetails } from "../../../../../../services/types/accessEventTypes";
import useLogAccessEvent from "../../../../../../hooks/useLogAccessEvent";

export interface InstrumentVisibility {
    visible: boolean;
    collapsed: boolean;
    showAllRaters: boolean;
    id: number;
    name: string;
}

export interface InstrumentInfo {
    instrumentId: number;
    hasRaters: boolean;
    name: string;
    shortName: string;
    raters: InstrumentRaterInfo[];
}

export interface InstrumentRaterInfo {
    raterId: number;
    raterName: string;
    minRequired: number;
    minRecommended: number;
    received: number;
    receivedLate: number;
}

export interface AssessmentStatusDataGridProps {
    instruments: InstrumentInfo[];
    participants: ProgramParticipantScoringStatus[];
    isLoading: boolean;
    showAllRatersByDefault: boolean;
    sessionKey: number | undefined;
    sessionCode: string | undefined;
    onScorePressed: (selectedParticipantIds: number[]) => void;
    readOnly?: boolean;
}

const AssessmentStatusDataGrid: React.FC<AssessmentStatusDataGridProps> = (props) => {
    const { logEvent } = useLogAccessEvent();
    const singleParticipant = props.participants.length === 1;
    const noInstrumentWithRaters = props.instruments.filter((i) => i.hasRaters).length === 0;
    const { data: urls, isSuccess } = useGetAppUrlsQuery();
    const [selectedParticipantIds, setSelectedParticipantIds] = React.useState<number[]>(
        singleParticipant
            ? props.participants.map((p) => {
                  return p.participantId;
              })
            : []
    );
    const [columnViewStatus, setColumnViewStatus] = React.useState<InstrumentVisibility[]>([]);
    const [columnVisibilityModel, setColumnVisibilityModel] =
        React.useState<GridColumnVisibilityModel>({});

    const colDefs: GridColDef[] = React.useMemo(() => {
        let columns: GridColDef[] = [];
        if (
            props?.instruments != null &&
            props.instruments.length > 0 &&
            columnViewStatus.length > 0
        ) {
            if (!singleParticipant) {
                columns = columns.concat([
                    {
                        ...GRID_CHECKBOX_SELECTION_COL_DEF,
                    },
                ]);
            }

            columns = columns.concat([
                {
                    field: "participant",
                    headerName: "Participant",
                    type: "string",
                    valueGetter: (params) => {
                        return `${params.row.firstName} ${params.row.lastName}`;
                    },
                    renderCell: (params: GridRenderCellParams) => (
                        <Link href={`/registration/${params.row.participantId}`}>
                            {params.value}
                        </Link>
                    ),
                    flex: 1.0,
                },
                {
                    field: "actions",
                    headerName: "",
                    type: "string",
                    renderCell: (params: GridRenderCellParams) => <MoreHorizIcon />,
                    width: 40,
                    disableColumnMenu: true,
                    sortable: false,
                },
            ]);

            props.instruments.forEach((inst, index) => {
                if (inst.hasRaters) {
                    // add the columns we know we need
                    columns = columns.concat([
                        {
                            field: `${inst.instrumentId}.scorable`,
                            headerName: "Scorability",
                            type: "string",
                            renderCell: (params: GridRenderCellParams) => (
                                <ScoringStatusPill
                                    status={getScorability(params.row, inst.instrumentId) ?? 0}
                                />
                            ),
                            flex: 0.85,
                            disableColumnMenu: true,
                            sortable: false,
                        },
                        {
                            field: `${inst.instrumentId}.self`,
                            headerName: "Self",
                            type: "string",
                            renderCell: (params: GridRenderCellParams) => (
                                <SelfStatusPill
                                    {...getSelfStatusParams(params.row, inst.instrumentId)}
                                />
                            ),
                            flex: 0.85,
                            disableColumnMenu: true,
                            sortable: false,
                        },
                    ]);

                    const raters =
                        props.instruments.find((i) => i.instrumentId === +inst.instrumentId)
                            ?.raters ?? [];
                    if (raters.length > 1) {
                        // add summary raters column unless there's only one type of rater (besides self)
                        columns.push({
                            field: `${inst.instrumentId}.raters`,
                            headerName: "Raters",
                            headerClassName: "greyCell",
                            cellClassName: "greyCell",
                            type: "string",
                            renderCell: (params: GridRenderCellParams) => (
                                <RaterSummaryStatusPill
                                    participant={params.row}
                                    instrumentId={inst.instrumentId}
                                />
                            ),
                            flex: 0.85,
                            disableColumnMenu: true,
                            sortable: false,
                        });
                    }

                    raters
                        .filter((r) => r != null)
                        .forEach((r, index) => {
                            const classname = index % 2 === 0 ? "greyCell" : undefined;
                            columns.push({
                                field: `${inst.instrumentId}.${r?.raterName.toLowerCase()}`,
                                headerName: r?.raterName ?? "Unknown Rater Type",
                                headerClassName: classname,
                                cellClassName: classname,
                                type: "string",
                                renderCell: (params: GridRenderCellParams) => (
                                    <RaterStatusPill
                                        participant={params.row}
                                        instrumentId={inst.instrumentId}
                                        raterId={r.raterId}
                                    />
                                ),
                                flex: 0.85,
                                disableColumnMenu: true,
                                sortable: false,
                            });
                        });
                } else {
                    columns = columns.concat([
                        {
                            field: `${inst.instrumentId}.self`,
                            headerName: "Self",
                            type: "string",
                            renderCell: (params: GridRenderCellParams) => (
                                <SelfStatusPill
                                    {...getSelfStatusParams(params.row, inst.instrumentId)}
                                />
                            ),
                            flex: 0.85,
                            disableColumnMenu: true,
                            sortable: false,
                        },
                    ]);
                }
            });
        }
        return columns;
    }, [props.instruments, columnViewStatus.length, singleParticipant]);

    const generateVisibilityModel = React.useCallback(() => {
        let newVisibilityModel = {};
        props.instruments.forEach((inst) => {
            const viewStatus = columnViewStatus.find((i) => i.id === inst.instrumentId);

            Object.assign(newVisibilityModel, {
                [`${inst.instrumentId}.self`]:
                    viewStatus != null ? !viewStatus.collapsed && viewStatus.visible : true,
            });
            if (inst.hasRaters) {
                const raters = inst.raters.filter((r) => r.raterName.toLowerCase() !== "self");
                const raterSummaryVisible =
                    viewStatus != null
                        ? !viewStatus.collapsed && !viewStatus.showAllRaters && viewStatus.visible
                        : !props.showAllRatersByDefault;
                const indRatersVisible =
                    viewStatus != null
                        ? !viewStatus.collapsed && viewStatus.showAllRaters && viewStatus.visible
                        : props.showAllRatersByDefault;

                Object.assign(newVisibilityModel, {
                    [`${inst.instrumentId}.scorable`]: viewStatus?.visible ?? true,
                });
                if (raters.length > 1) {
                    Object.assign(newVisibilityModel, {
                        [`${inst.instrumentId}.raters`]: raterSummaryVisible,
                    });
                    raters.forEach((raterCol) => {
                        Object.assign(newVisibilityModel, {
                            [`${inst.instrumentId}.${raterCol.raterName.toLowerCase()}`]:
                                indRatersVisible,
                        });
                    });
                } else if (raters.length === 1) {
                    Object.assign(newVisibilityModel, {
                        [`${inst.instrumentId}.${raters[0].raterName.toLowerCase()}`]:
                            !viewStatus?.collapsed,
                    });
                }
            }
        });
        return newVisibilityModel;
    }, [props.instruments, props.showAllRatersByDefault, columnViewStatus]);

    React.useEffect(() => {
        if (props.instruments == null || props.instruments.length <= 0) {
            setColumnViewStatus([]);
            setColumnVisibilityModel({});
        } else if (columnViewStatus.length === 0) {
            setColumnViewStatus(
                props.instruments.map((i) => {
                    return {
                        visible: true,
                        id: i.instrumentId,
                        name: i.name,
                        collapsed: false,
                        showAllRaters: props.showAllRatersByDefault ?? false,
                    };
                })
            );
        }
        setColumnVisibilityModel(generateVisibilityModel());
    }, [
        columnViewStatus,
        generateVisibilityModel,
        props.instruments,
        props.showAllRatersByDefault,
    ]);

    const getScorability = (
        pax: ProgramParticipantScoringStatus,
        instrumentId: number
    ): number | undefined => {
        const paxInstrument = pax.instruments.find((i) => i.instrumentId === +instrumentId);
        if (paxInstrument == null) return 0;
        if (paxInstrument.selfAssessmentStatusTypeId === 5) return 5;
        if (paxInstrument.scoringStatus === 2) return 2;
        if (paxInstrument.reportQualityTypeId === 4) return 1;
        return 0;
    };

    const handleEvent = (buttonName: string) => {
        if (buttonName === "viewscoring" && isSuccess && props.sessionKey != null) {
            const evtData: AccessEventSessionDetails = {
                projectCode: props.sessionCode ?? "unknown",
            };
            logEvent("SessionViewedInCCLScoring", evtData);
            window.open(`${urls["CCLScoring"]}/program-status/${props.sessionKey}`, "_blank");
        } else if (buttonName === "score") {
            props.onScorePressed(selectedParticipantIds);
        }
    };

    const getSelfStatusParams = (
        pax: ProgramParticipantScoringStatus,
        instrumentId: number
    ): SelfStatusPillProps => {
        const paxInstrument = pax.instruments.find((i) => i.instrumentId === +instrumentId);
        const st = paxInstrument?.selfAssessmentStatusTypeId ?? 0;
        const numRaters = paxInstrument?.supportedRaterTypeCount ?? 0;
        const scrd =
            paxInstrument?.scoringStatus == null ? false : paxInstrument.scoringStatus === 2;
        return { status: st, hasRaters: numRaters > 1, scored: scrd };
    };

    const assessmentVisibilityChange = (visible: boolean, instrumentId: number) => {
        const inst = props.instruments.find((i) => i.instrumentId === instrumentId);
        if (inst == null) return;
        let newViewStatus = columnViewStatus.filter((vs) => vs.id !== instrumentId);
        let colViewStatus = columnViewStatus.find((vs) => vs.id === instrumentId);
        if (colViewStatus == null) {
            newViewStatus.push({
                id: inst.instrumentId,
                name: inst.name,
                visible: visible,
                collapsed: !inst.hasRaters,
                showAllRaters: !inst.hasRaters,
            });
        } else {
            newViewStatus.push({
                id: colViewStatus.id,
                name: colViewStatus.name,
                visible: visible,
                collapsed: colViewStatus.collapsed,
                showAllRaters: colViewStatus.showAllRaters,
            });
        }
        setColumnViewStatus(newViewStatus);
    };

    const handleDetailLevelChange = (detailLevelSetting: number) => {
        const collapsed: boolean = detailLevelSetting === 0;
        const showAllRaterCategories = detailLevelSetting === 2;
        const withRaters = props.instruments.filter((i) => i.hasRaters);
        let newViewStatus = columnViewStatus.filter(
            (vs) => withRaters.find((i) => vs.id === i.instrumentId) == null
        );
        withRaters.forEach((i) => {
            const currViewStatus = columnViewStatus.find((s) => s.id === i.instrumentId);
            newViewStatus.push({
                id: i.instrumentId,
                name: i.name,
                visible: currViewStatus?.visible ?? true,
                collapsed: collapsed,
                showAllRaters: showAllRaterCategories,
            });
        });
        setColumnViewStatus(newViewStatus);
    };

    let colGroupingModel: GridColumnGroupingModel = [];

    if (props.instruments.length > 0) {
        let columnGroup: GridColumnGroup[] = props.instruments.map((inst, index) => {
            let children = [{ field: `${inst.instrumentId}.self` }];
            if (inst.hasRaters) {
                children.push({ field: `${inst.instrumentId}.scorable` });
                const raters =
                    props.instruments.find((i) => i.instrumentId === +inst.instrumentId)?.raters ??
                    [];
                if (raters.length > 1) children.push({ field: `${inst.instrumentId}.raters` });

                raters.forEach((r) => {
                    children.push({ field: `${inst.instrumentId}.${r.raterName.toLowerCase()}` });
                });
            }

            return {
                groupId: inst.shortName,
                children: children,
                renderHeaderGroup: (params) => {
                    return (
                        <AssessmentStatusGroupHeader
                            {...params}
                            hoverTitle={`${inst.name} (${inst.shortName})`}
                        />
                    );
                },
            };
        });
        colGroupingModel = columnGroup.sort((a: GridColumnGroup, b: GridColumnGroup) =>
            a.groupId.localeCompare(b.groupId)
        );
    }

    if (
        props.instruments == null ||
        props.instruments.length === 0 ||
        props.participants == null ||
        props.participants.length === 0
    ) {
        return (
            <Stack width={1} spacing={2}>
                <Alert severity="info">
                    No assessment status information found for this session.
                </Alert>
            </Stack>
        );
    }
    const sortedParticipants = [...props.participants].sort((a, b) =>
        a.firstName.localeCompare(b.firstName)
    );

    return (
        <Stack width={1} spacing={2}>
            <DataGridPro
                getRowId={(row) => row.participantId}
                experimentalFeatures={{ columnGrouping: true }}
                loading={props.isLoading}
                components={{ Toolbar: AssessmentStatusDataGridToolbar }}
                componentsProps={{
                    toolbar: {
                        anySelected: selectedParticipantIds.length > 0,
                        handleButtonClick: (buttonName: string) => handleEvent(buttonName),
                        instrumentVisibility: columnViewStatus,
                        onAssessmentFilterChange: assessmentVisibilityChange,
                        defaultDetailLevel: 1,
                        handle360DetailChange: handleDetailLevelChange,
                        showDetailLevelSelect: !noInstrumentWithRaters,
                        readOnly: props.readOnly,
                        sessionCode: props.sessionCode,
                        sessionKey: props.sessionKey,
                    },
                }}
                onSelectionModelChange={(ids: GridSelectionModel) => {
                    setSelectedParticipantIds([...ids.map((i) => i as number)]);
                }}
                checkboxSelection={!singleParticipant}
                columnGroupingModel={colGroupingModel}
                columnVisibilityModel={columnVisibilityModel}
                columns={colDefs}
                rows={sortedParticipants}
                disableSelectionOnClick={true}
                sx={{
                    "& .greyCell": {
                        backgroundColor: "rgb(235, 235, 235)",
                    },
                }}
                autoHeight
            />
        </Stack>
    );
};

export default AssessmentStatusDataGrid;
