import * as React from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Box,
    Stack,
    Typography,
} from "@mui/material";
import ScoringStatusPanel from "./scoringStatus/scoringStatusPanel";
import RaterManagementPanel from "../../../registrations/registrationDetail/raterManagement/raterManagementPanel";
import { SessionDetailType } from "../../../../services/types/sessionDetailTypes";
import CclGenericAccordian from "../../../../components/common/cclGenericAccordian";
import { ExpandMore } from "@mui/icons-material";

interface AssessmentsPanelProps {
    sessionDetails: SessionDetailType | undefined;
    readOnly?: boolean;
}

const AssessmentsPanel: React.FC<AssessmentsPanelProps> = (props) => {
    const [expanded, setExpanded] = React.useState(true);

    if (props.sessionDetails === undefined) {
        return (
            <Box>
                <Alert severity="error">Error: Session data not found!</Alert>
            </Box>
        );
    }

    return (
        <Stack width={1}>
            <Accordion
                key={`status-${props.sessionDetails.sessionKey}`}
                expanded={expanded}
                onChange={() => setExpanded(!expanded)}
            >
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography sx={{ width: "100%", marginLeft: "15px", fontWeight: "bolder" }}>
                        Assessments
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ScoringStatusPanel
                        sessionKey={props.sessionDetails.sessionKey}
                        sessionCode={props.sessionDetails.sessionCode}
                        readOnly={props.readOnly}
                    />
                </AccordionDetails>
            </Accordion>
            <CclGenericAccordian headingText="Raters" defaultExpanded={true}>
                <RaterManagementPanel
                    sessionKey={props.sessionDetails.sessionKey}
                    programCode={props.sessionDetails.sessionCode}
                    readOnly={props.readOnly}
                />
            </CclGenericAccordian>
        </Stack>
    );
};

export default AssessmentsPanel;
