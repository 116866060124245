import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Stack } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import CclButton from "./cclButtons/cclButton";

export type CclGenericConfirmationDialogProps = {
    open: boolean;
    title?: string | undefined;
    msg?: string | undefined;
    onCancel: () => void;
    onOk: () => void;
};

const CclGenericConfirmationDialog: React.FC<CclGenericConfirmationDialogProps> = (props) => {
    const defaultTitle: string = "Are you sure you would like to continue?";
    const defaultMsg: string = "This will cause changes to be committed to the system.";
    const [title, setTitle] = React.useState<string>(defaultTitle);
    const [message, setMessage] = React.useState<string>(defaultMsg);

    React.useEffect(() => {
        if (props.title == null || props.title === "") {
            setTitle(defaultTitle);
        } else {
            setTitle(props.title);
        }
    }, [props.title]);

    React.useEffect(() => {
        if (props.msg == null || props.msg === "") {
            setMessage(defaultMsg);
        } else {
            setMessage(props.msg);
        }
    }, [props.msg]);

    const handleClose = (evt: any) => {
        if (evt.target.id === "ok-btn") {
            if (props.onOk) {
                props.onOk();
            }
        } else {
            if (props.onCancel) {
                props.onCancel();
            }
        }
    };

    return (
        <Dialog
            open={props.open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <Stack direction="row" alignItems={"center"}>
                    <InfoIcon color="primary" fontSize="large" sx={{ pr: 1 }} />
                    <DialogContentText id="alert-dialog-description">
                        <span>{message}</span>
                    </DialogContentText>
                </Stack>
            </DialogContent>

            <DialogActions>
                <CclButton restricted={false} mode="secondary" id="cancel-btn" onClick={handleClose}>
                    Cancel
                </CclButton>
                <CclButton restricted={false} mode="primary" id="ok-btn" onClick={handleClose}>
                    Okay
                </CclButton>
            </DialogActions>
        </Dialog>
    );
};

export default CclGenericConfirmationDialog;
