import * as React from "react";
import { Box, ToggleButtonGroup, ToggleButton } from "@mui/material";
import { DetailsTabPanel } from "../../common/detailTabPanel/detailTabPanel";
import IndividualApplicationLogsCard from "./individualApplicationLogsCard/individualApplicationLogsCard";
import IndividualJournalEntryTimelineCard from "./individualJournalEntryTimelineCard/individualJournalEntryTimelineCard";
import {
    UserClaimsService,
    eGroupIds,
} from "../../../services/currentUserService/currentUserService";

interface IndividualHistoryPanelProps {
    imKey: string;
    emailAddress: string;
    idPrefix: string;
    onCreateJournalEntry: () => void;
    journalTabKey: number;
}

const IndividualHistoryPanel: React.FC<IndividualHistoryPanelProps> = (props) => {
    const claimsService = new UserClaimsService();
    const [alignment, setAlignment] = React.useState<number>(0);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number | null) => {
        if (newValue != null) setAlignment(newValue);
    };

    // Tablist
    let tablist: [string, React.ReactNode][] = [
        [
            "Events",
            <IndividualApplicationLogsCard imKey={props.imKey} emailAddress={props.emailAddress} />,
        ],
        [
            "Journal",
            <IndividualJournalEntryTimelineCard
                key={props.journalTabKey}
                imKey={props.imKey}
                emailAddress={props.emailAddress}
                onCreateJournalEntry={props.onCreateJournalEntry}
            />,
        ],
    ];

    if (claimsService.IsUserInGroups([eGroupIds.eClientAdmin])) {
        tablist = tablist.filter((x) => x[0] !== "Events");
    }

    return (
        <Box
            sx={{
                width: 1,
                height: 1,
                m: 2,
                borderWidth: 0,
                display: "flex",
                flexDirection: "column",
            }}
        >
            <Box display={"flex"} justifyContent={"center"} sx={{ borderWidth: 0, flexGrow: 1 }}>
                <ToggleButtonGroup
                    color="primary"
                    value={alignment}
                    exclusive
                    onChange={handleTabChange}
                    sx={{ mb: 3 }}
                >
                    {tablist.map((tab, i) => (
                        <ToggleButton key={i} id={`${props.idPrefix}-tab-${i}`} value={i}>
                            {tab[0]}
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
            </Box>
            {tablist.map((tab, i) => (
                <DetailsTabPanel
                    detailType={props.idPrefix}
                    key={tab[0]}
                    value={alignment}
                    index={i}
                >
                    {tab[1]}
                </DetailsTabPanel>
            ))}
        </Box>
    );
};

export default IndividualHistoryPanel;
