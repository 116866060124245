import * as React from "react";
import {
    Stack,
    FormControlLabel,
    Checkbox,
    Box,
    Typography,
    AlertColor,
    Alert,
    AlertTitle,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { HandbookContractData } from "./signContractStepper";
import { FileDownloadService } from "../../../../../../services/fileDownloadService/fileDownloadService";
import { Contract } from "../../../../../../services/types/enterpriseCoachingApiTypes";
import CclUnrestrictedLoadingButton from "../../../../../../components/common/cclButtons/cclUnrestrictedLoadingButton";
import CclUnrestrictedOutlinedButton from "../../../../../../components/common/cclButtons/cclUnrestrictedOutlinedButton";
import CclRestrictedButton from "../../../../../../components/common/cclButtons/cclRestrictedButton";

export interface HandbookStepPanelProps {
    pkey: number;
    handbookData: HandbookContractData;
    onNextPanel: (nextStep: number, newdata: HandbookContractData) => void;
    onPreviousPanel: () => void;
    contract: Contract | null;
    activeStep: number;
    nextStep: number;
}

export const HandbookStepPanel: React.FC<HandbookStepPanelProps> = (props) => {
    const downloadService = new FileDownloadService();

    const [downloading, setDownloading] = React.useState<boolean>(false);
    const [alertOpen, setAlertOpen] = React.useState<boolean>(false);
    const [alertMsg, setAlertMsg] = React.useState<string>("");
    const [alertTitle, setAlertTitle] = React.useState<string>("");
    const [alertSeverity, setAlertSeverity] = React.useState<AlertColor>("success");

    const [handbookChecked, setHandbookChecked] = React.useState<boolean | undefined>(
        props.handbookData?.agreeToHandbook
    );
    const [handbookChangesChecked, setHandbookChangesChecked] = React.useState<boolean | undefined>(
        props.handbookData?.agreeToHandbookChanges
    );

    React.useEffect(() => {
        if (props.nextStep !== -1) handleNext(props.nextStep);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.nextStep]);

    const downloadHandbook = () => {
        if (props?.contract == null) return;

        setDownloading(true);
        setAlertOpen(true);
        setAlertMsg("Downloading handbook...");
        setAlertTitle("Download Handbook");
        setAlertSeverity("info");

        downloadService
            .DownloadContractorsHandbook(props.contract.year.toString())
            .then(() => {
                setAlertMsg("Successfully downloaded handbook.");
                setAlertSeverity("success");
                setDownloading(false);
            })
            .catch((error) => {
                const msg = error.message ?? "Unknown error";
                setAlertMsg(`Failed to downloaded handbook (error: ${msg})`);
                setAlertSeverity("error");
                setDownloading(false);
            });
    };

    const handleNext = (nxtStep?: number) => {
        let ns = nxtStep !== undefined ? nxtStep : props.activeStep + 1;
        let newdata: HandbookContractData = {
            agreeToHandbook: handbookChecked,
            agreeToHandbookChanges: handbookChangesChecked,
        };

        props.onNextPanel(ns, newdata);
    };

    return (
        <Stack direction={"column"}>
            <Box
                component="form"
                sx={{
                    width: "100%",
                    borderStyle: "solid",
                    borderRadius: 3,
                    borderWidth: 1,
                    borderColor: "lightgray",
                    p: 4,
                    mt: 5,
                    mb: 3,
                }}
            >
                <Stack direction={"column"} width={1} spacing={3}>
                    <Typography variant="h5">
                        Please review the Contractor Handbook and indicate you will abide by it.
                    </Typography>
                    <Typography variant="body2" sx={{ pb: 3, color: "text.secondary" }}>
                        Closing the screen will stop the Sign Contract process but you will be able
                        to restart it again.
                    </Typography>
                    <CclUnrestrictedLoadingButton
                        onClick={downloadHandbook}
                        loading={downloading}
                        sx={{ mb: 3, width: 0.5 }}
                    >
                        Download And Review Contractor Handbook
                    </CclUnrestrictedLoadingButton>
                    {alertOpen ? (
                        <Alert
                            severity={alertSeverity}
                            sx={{ mb: 3, width: 0.5 }}
                            onClose={() => setAlertOpen(false)}
                        >
                            <AlertTitle>{alertTitle}</AlertTitle>
                            {alertMsg}
                        </Alert>
                    ) : null}
                    <Typography>By signing the CCL Independent Contractor Agreement:</Typography>
                    <FormControlLabel
                        label={
                            <Box
                                sx={{
                                    p: 0,
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <Typography variant="body2" sx={{ display: "inline" }}>
                                    I agree to abide by the Contractor Handbook
                                </Typography>
                                <Typography
                                    variant="h5"
                                    color={"error"}
                                    sx={{
                                        pl: 1,
                                        display: "inline",
                                        textDecoration: "bold",
                                    }}
                                >
                                    *
                                </Typography>
                            </Box>
                        }
                        control={
                            <Checkbox
                                name="handbook-checkbox"
                                checked={handbookChecked}
                                onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                                    setHandbookChecked(evt.target.checked)
                                }
                                sx={{ pl: 3, fontSize: "0.8125rem" }}
                            />
                        }
                    />
                    <FormControlLabel
                        label={
                            <Box
                                sx={{
                                    pt: 0,
                                    pb: 0,
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <Typography variant="body2" sx={{ display: "inline" }}>
                                    I agree to abide by changes as notified by CCL
                                </Typography>
                                <Typography
                                    variant="h5"
                                    color={"error"}
                                    sx={{
                                        pl: 1,
                                        display: "inline",
                                        textDecoration: "bold",
                                    }}
                                >
                                    *
                                </Typography>
                            </Box>
                        }
                        control={
                            <Checkbox
                                name="handbook-changes-checkbox"
                                checked={handbookChangesChecked}
                                onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                                    setHandbookChangesChecked(evt.target.checked)
                                }
                                sx={{ pl: 3, fontSize: "0.8125rem" }}
                            />
                        }
                    />
                </Stack>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <CclUnrestrictedOutlinedButton
                    onClick={() => props.onPreviousPanel()}
                    startIcon={<NavigateBeforeIcon />}
                    sx={{ mr: 1 }}
                >
                    Back
                </CclUnrestrictedOutlinedButton>
                <Box sx={{ flex: "1 1 auto" }} />
                <CclRestrictedButton onClick={() => handleNext()} endIcon={<NavigateNextIcon />}>
                    Next
                </CclRestrictedButton>
            </Box>
        </Stack>
    );
};

export default HandbookStepPanel;
