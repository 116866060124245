import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Alert, AlertColor, AlertTitle, CircularProgress, DialogActions } from "@mui/material";
import CclButton from "./cclButtons/cclButton";

export type CclStatusDialogProps = {
    open: boolean;
    title?: string | undefined;
    msg?: string | undefined;
    enableOk?: boolean;
    onOk: () => void;
    severity: AlertColor;
};

const CclStatusDialog: React.FC<CclStatusDialogProps> = (props) => {
    let title: string = "";

    if (props.title == null || props.title === "") {
        switch (props.severity) {
            case "warning":
                // setTitle("Warning");
                title = "Warning";
                break;
            case "error":
                // setTitle("Error");
                title = "Error";
                break;
            case "info":
            case "success":                
                // setTitle("Information");
                title = "Information";
                break;
            // case "success":
            //     // setTitle("Success");
            //     title = "Success";
            //     break;
        }
    } else {
        title = props.title;
    }

    const message: string = props.msg != null && props.msg !== "" ? props.msg : "Status";

    const handleClose = (evt: any) => {
        if (props.onOk) {
            props.onOk();
        }
    };

    return (
        <Dialog
            open={props.open}
            aria-labelledby="status-dialog-title"
            aria-describedby="status-dialog-description"
        >
            <DialogContent>
                <Alert
                    severity={props.severity === "success" ? "info" : props.severity}
                    icon={props.severity === "info" ? <CircularProgress size={20} /> : null}
                >
                    <AlertTitle>{title}</AlertTitle>
                    {message}
                </Alert>
            </DialogContent>
            {props.enableOk ? (
                <DialogActions>
                    <CclButton restricted={false} mode="primary" id="ok-btn" onClick={handleClose}>
                        Okay
                    </CclButton>
                </DialogActions>
            ) : null}
        </Dialog>
    );
};

export default CclStatusDialog;
