import React, { useState } from "react";
import { Config } from "./config";
import { useGetAllApplicationFeaturesQuery } from "./services/cclTokenedApplicationFeaturesApi";
import { ApplicationFeature } from "./services/types/applicationFeatureTypes";

type FeatureFlagType = {
    envType: string;
    allFeatures: ApplicationFeature[];
    documentsLegacy: ApplicationFeature;
    documentsBeta: ApplicationFeature;
    resend: ApplicationFeature;
    scoring: ApplicationFeature;
};

export const FeatureFlag = React.createContext<FeatureFlagType>(null as unknown as FeatureFlagType);

type FeatureProviderProps = {
    children?: React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined;
};

export const FeatureFlagProvider = (props: FeatureProviderProps) => {
    const envType = Config.REACT_APP_APPLICATION_BUILD_ID?.split("-")[0];

    const { data: allFeatures, isLoading, refetch } = useGetAllApplicationFeaturesQuery();

    React.useEffect(() => {
        if (!allFeatures || isLoading) {
            if (!allFeatures && !isLoading) {
                refetch();
            }
            return;
        }
        const tempFeatures: FeatureFlagType = {
            envType: envType ? envType : "prod",
            allFeatures: allFeatures,
            documentsLegacy: {} as ApplicationFeature,
            documentsBeta: {} as ApplicationFeature,
            resend: {} as ApplicationFeature,
            scoring: {} as ApplicationFeature,
        };

        allFeatures.forEach((feature) => {
            if (feature.tag === "documentsLegacy") {
                tempFeatures.documentsLegacy = feature;
            } else if (feature.tag === "documentsBeta") {
                tempFeatures.documentsBeta = feature;
            } else if (feature.tag === "resend") {
                tempFeatures.resend = feature;
            } else if (feature.tag === "scoringFunctions") {
                tempFeatures.scoring = feature;
            }
        });

        setFeatures(tempFeatures);
        // eslint-disable-next-line
    }, [allFeatures, isLoading, envType]);

    const [features, setFeatures] = useState({
        envType: envType ? envType : "prod",
        allFeatures: allFeatures ? allFeatures : [],
        documentsLegacy: null as unknown as ApplicationFeature,
        documentsBeta: null as unknown as ApplicationFeature,
        resend: null as unknown as ApplicationFeature,
        scoring: null as unknown as ApplicationFeature,
    });

    return <FeatureFlag.Provider value={features}>{props.children}</FeatureFlag.Provider>;
};
