import * as React from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from "@mui/material";
import { GrandCentralConnection } from "../../../../services/types/cclGrandCentralApiTypes";
import CclUnrestrictedOutlinedButton from "../../../../components/common/cclButtons/cclUnrestrictedOutlinedButton";
import CclRestrictedButton from "../../../../components/common/cclButtons/cclRestrictedButton";

export interface UpdateLoginApplicationConnectionDialogProps {
    open: boolean;
    onClose: () => void;
    conn: GrandCentralConnection;
    onSave: (updatedConn: GrandCentralConnection) => void;
}

export const UpdateLoginApplicationConnectionDialog: React.FC<
    UpdateLoginApplicationConnectionDialogProps
> = (props) => {
    const inputRef = React.useRef<HTMLInputElement>(null);

    const onUpdateCallback = () => {
        if (inputRef.current === null || inputRef.current.value === "") return;
        let newConnection: GrandCentralConnection = JSON.parse(JSON.stringify(props.conn));
        newConnection.connectionId = inputRef.current.value;
        props.onSave(newConnection);
        props.onClose();
    };

    return (
        <Dialog open={props.open} onClose={props.onClose} maxWidth={"sm"} fullWidth={true}>
            <DialogTitle>Update Connection</DialogTitle>
            <DialogContent sx={{ marginTop: "10px" }}>
                <Stack spacing={3}>
                    <TextField
                        required
                        id="edit-connection"
                        label="Update Connection"
                        variant="standard"
                        defaultValue={props.conn.connectionId}
                        inputRef={inputRef}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <CclUnrestrictedOutlinedButton onClick={props.onClose}>
                    Cancel
                </CclUnrestrictedOutlinedButton>
                <CclRestrictedButton onClick={() => onUpdateCallback()}>
                    Update Callback
                </CclRestrictedButton>
            </DialogActions>
        </Dialog>
    );
};

export default UpdateLoginApplicationConnectionDialog;
