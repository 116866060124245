import * as React from "react";
import { useState } from "react";
import { Box, Card, Grid, Stack, Typography } from "@mui/material";
import { SimpleBreadcrumbs } from "../../components/navigation/simpleBreadcrumbs";
import StandardLayout from "../../layouts/standard";
import {
    useGetCclParticipantsByDateMutation,
    useLazyGetCclParticipantsByEmailQuery,
} from "../../services/cclTokenedEnterpriseParticipantApi";
import RegistrationsDataGridPro from "./registrationList";
import { useDispatch, useSelector } from "react-redux";
import { updateRegistrationSearchParams } from "../../app/slices/registrationListConfigSlice";
import { getDateStringAdjusted } from "../../utilities/dateHelpers";
import CclDateSearchBar from "../../components/common/cclLandingPageSearchBars/cclDateSearchBar";
import CclTextSearchBar from "../../components/common/cclLandingPageSearchBars/cclTextSearchBar";
import ComponentLoader from "../../components/common/componentLoader";
import CclSearchDefault from "../../components/common/cclSearchDefault";
import { useParams } from "react-router-dom";

export type RegistrationDetailProps = {
    searchparam: string;
};

export const Registrations: React.FC = () => {
    // redux state
    const dispatch = useDispatch();
    const config = useSelector((state: any) => state.registrationListConfig);

    const [isDateSearchActive, setIsDateSearchActive] = useState<boolean>(
        config.IsDateSearchActive
    );

    const params = useParams<RegistrationDetailProps>();

    const [searchEmail, setSearchEmail] = useState<string>(
        config.IsDateSearchActive ? "" : config.EmailSearch
    );
    const [
        runSearch,
        {
            data: paxByDate,
            isLoading: paxIsLoading,
            isError: paxIsError,
            error: paxError,
            isSuccess: paxDateIsSuccess,
        },
    ] = useGetCclParticipantsByDateMutation();

    const [
        runEmailSearch,
        { data: paxByEmail, isLoading, isError, error, isSuccess: paxEmailIsSuccess },
    ] = useLazyGetCclParticipantsByEmailQuery();

    const clearDateSearch = () => {
        // this is a hack to overcome an error in the mui datepicker that won't allow us to set an invalid date to null.
        // We have to first set a valid date, then go to null
    };

    React.useEffect(() => {
        if (!params.searchparam) {
            return;
        }
        setSearchEmail(params.searchparam ?? "");
        setIsDateSearchActive(false);
        getRegistrationsByEmail(params.searchparam ?? "");
        //eslint-disable-next-line
    }, [params]);

    const getRegistrationsByDate = async (startDate: Date | null, endDate: Date | null) => {
        if (startDate == null || endDate == null) {
            return;
        }
        setSearchEmail("");
        setIsDateSearchActive(true);
        runSearch({
            start: startDate.toISOString(),
            end: endDate.toISOString(),
        });
        dispatch(
            updateRegistrationSearchParams({
                StartDate: startDate.toISOString(),
                EndDate: endDate.toISOString(),
                EmailSearch: searchEmail,
                IsDateSearchActive: isDateSearchActive,
            })
        );
    };

    const getRegistrationsByEmail = async (email?: string) => {
        if (
            (searchEmail == null || searchEmail.trim() === "") &&
            (email == null || email?.trim() === "")
        ) {
            return;
        }
        clearDateSearch();
        setIsDateSearchActive(false);
        const emailValue = searchEmail ? searchEmail.trim() : email ? email.trim() : "";
        const dateSearch = email ? false : isDateSearchActive;

        if (email) {
            runEmailSearch(email);
        } else {
            runEmailSearch(emailValue);
            dispatch(
                updateRegistrationSearchParams({
                    StartDate: getDateStringAdjusted(-2), // api requires some date but it won't be used in search
                    EndDate: getDateStringAdjusted(2), // api requires some date but it won't be used in search
                    EmailSearch: emailValue,
                    IsDateSearchActive: dateSearch,
                })
            );
        }
    };

    const breadcrumbs = (
        <SimpleBreadcrumbs>
            <Typography variant="h6" color="text.primary">
                Registrations
            </Typography>
        </SimpleBreadcrumbs>
    );

    if (!isError && !paxIsError) {
        return (
            <StandardLayout breadcrumbs={breadcrumbs}>
                <Stack height={1} width={1}>
                    <Card
                        sx={{
                            height: 1,
                            width: 1,
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                            <CclTextSearchBar
                                initialSearchTerm={searchEmail}
                                searchLabel="Enter Participant Email"
                                executeSearch={getRegistrationsByEmail}
                            />
                            <CclDateSearchBar
                                initialStartDate={null}
                                initialEndDate={null}
                                startDateLabel="Earliest Start Date"
                                endDateLabel="Latest Start Date"
                                executeSearch={getRegistrationsByDate}
                                allowNullDates={false}
                            />
                        </Box>
                        {isLoading || paxIsLoading ? (
                            // search, or loading in progress
                            <ComponentLoader msg={"Loading"} />
                        ) : paxDateIsSuccess || paxEmailIsSuccess || paxByDate || paxByEmail ? (
                            <Grid
                                sx={{
                                    height: 1,
                                    width: 1,
                                    padding: 2,
                                    paddingTop: 0,
                                }}
                            >
                                <RegistrationsDataGridPro
                                    loading={paxIsLoading || isLoading}
                                    registrations={isDateSearchActive ? paxByDate : paxByEmail}
                                    showResultBar={false}
                                    resultBarText=""
                                />
                            </Grid>
                        ) : (
                            <CclSearchDefault
                                line1="Start searching for Registrations"
                                line2="Enter Participant Email or enter a Date Range and click Search"
                            />
                        )}
                    </Card>
                </Stack>
            </StandardLayout>
        );
    }

    if (isError || paxIsError) {
        return (
            <StandardLayout breadcrumbs={breadcrumbs}>
                <span>{`Error Loading Registrations: ${paxIsError ? paxError : error}`}</span>
            </StandardLayout>
        );
    }

    return (
        <StandardLayout breadcrumbs={breadcrumbs}>
            <span>Unknown Error Loading Registrations</span>
        </StandardLayout>
    );
};

export default Registrations;
