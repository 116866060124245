import * as React from "react";
import { Box, Tab } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import CclResourcesTabPanel from "./cclResources/cclResourcesTabPanel";
import PartnerResourcesTabPanel from "./partnerResources/partnerResourcesTabPanel";
import { updateResourcesPageSelectedTab } from "../../app/slices/resourcesPageSlice";
import BioSearchTabPanel from "./bioSearch/bioSearchTabPanel";
import ContractSearchTabPanel from "./contractSearch/contractTabPanel";
import WorkPoolsTabPanel from "./workPools/workPoolsTabPanel";

export type ResourcesTabsProps = {
    defaultTab: string;
    isNwaAdmin: boolean;
    partnerId: string | null;
    partnerName: string | null;
    isCCPUser: boolean;
    isCCLAdmin: boolean;
};

export const ResourcesTabs: React.FC<ResourcesTabsProps> = (props) => {
    const stateSelector = useSelector((state: any) => state.resourcesPage);

    // redux state
    const dispatch = useDispatch();

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        if (!props.isNwaAdmin) dispatch(updateResourcesPageSelectedTab(newValue));
    };

    /*
        Resource Tab Values:
            CCL Resources       - 1
            Bios                - 2
            Partner Resources   - 3
            Contracts           - 4
            Work Pools          - 6
    */

    const partnerTabLabel = props.isCCLAdmin ? "Partners" : props.partnerName ?? "Partners";
    return (
        <TabContext
            value={
                stateSelector.selectedTab === "-1" ? props.defaultTab : stateSelector.selectedTab
            }
        >
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList onChange={handleTabChange} aria-label="Session View Tabs">
                    {!props.isNwaAdmin ? (
                        <Tab
                            label="CCL"
                            id="ccl-resources"
                            value="1"
                            aria-controls="ccl-resources"
                            sx={{ fontSize: "14px", textTransform: "none" }}
                        />
                    ) : null}
                    {!props.isCCPUser ? (
                        <Tab
                            label={partnerTabLabel}
                            id="partner-resources"
                            value="3"
                            aria-controls="partner-resources"
                            sx={{ fontSize: "14px", textTransform: "none" }}
                        />
                    ) : null}
                    {props.isCCLAdmin || props.isCCPUser ? (
                        <Tab
                            label={"Work Pools"}
                            id="work-pool-search"
                            value="6"
                            aria-controls="work-pool-search"
                            sx={{ fontSize: "14px", textTransform: "none" }}
                        />
                    ) : null}
                    {!props.isNwaAdmin ? (
                        <Tab
                            label={"Bios"}
                            id="bio-search"
                            value="2"
                            aria-controls="bio-search"
                            sx={{ fontSize: "14px", textTransform: "none" }}
                        />
                    ) : null}
                    {!props.isNwaAdmin && !props.isCCPUser ? (
                        <Tab
                            label={"Contracts"}
                            id="contract-search"
                            value="4"
                            aria-controls="contract-search"
                            sx={{ fontSize: "14px", textTransform: "none" }}
                        />
                    ) : null}
                </TabList>
            </Box>
            <Box sx={{ width: 1, height: 1 }}>
                {!props.isNwaAdmin ? (
                    <TabPanel value="1" sx={{ width: 1, height: 1 }}>
                        <Box sx={{ width: 1, height: 1, display: "flex" }}>
                            <CclResourcesTabPanel />
                        </Box>
                    </TabPanel>
                ) : null}
                {props.isCCLAdmin || props.isCCPUser ? (
                    <TabPanel value="6" sx={{ width: 1, height: 1 }}>
                        <Box sx={{ width: 1, height: 1, display: "flex" }}>
                            <WorkPoolsTabPanel />
                        </Box>
                    </TabPanel>
                ) : null}
                {!props.isNwaAdmin ? (
                    <TabPanel value="2" sx={{ width: 1, height: 1 }}>
                        <Box sx={{ width: 1, height: 1, display: "flex" }}>
                            <BioSearchTabPanel />
                        </Box>
                    </TabPanel>
                ) : null}
                {!props.isNwaAdmin && !props.isCCPUser ? (
                    <TabPanel value="4" sx={{ width: 1, height: 1 }}>
                        <Box sx={{ width: 1, height: 1, display: "flex" }}>
                            <ContractSearchTabPanel
                                isNwaAdmin={props.isNwaAdmin}
                                partnerId={props.partnerId}
                            />
                        </Box>
                    </TabPanel>
                ) : null}
                {!props.isCCPUser ? (
                    <TabPanel value="3" sx={{ width: 1, height: 1 }}>
                        <Box sx={{ width: 1, height: 1, display: "flex" }}>
                            <PartnerResourcesTabPanel
                                isNwaAdmin={props.isNwaAdmin}
                                partnerId={props.partnerId}
                            />
                        </Box>
                    </TabPanel>
                ) : null}
            </Box>
        </TabContext>
    );
};
export default ResourcesTabs;
