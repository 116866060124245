import * as React from "react";
import { DataGridPro, GridColDef, GridColumns, GridSelectionModel } from "@mui/x-data-grid-pro";
import FlexGrid from "../../../../layouts/flexGrid";
import { Participant } from "../../../../services/types/generateDocumentsApiTypes";
import { addCustomDataGridFilters } from "../../../../components/common/customDataGridFilters/customDataGridFilters";

function getFullName(params: any) {
    return `${params.row.firstName || ""} ${params.row.lastName || ""}`;
}
const COLUMNS: GridColDef[] = [
    {
        field: "firstName",
        headerName: "Participant",
        valueGetter: getFullName,
        flex: 1,
    },
];

interface TemplateParticipantAssignmentPanelProps {
    sessionId: string;
    allParticipants: Participant[];
    isLoading: boolean;
    handleSelectionClicked: (participantKeys: number[]) => void;
}

const TemplateParticipantAssignmentPanel: React.FC<TemplateParticipantAssignmentPanelProps> = (
    props
) => {
    const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>();

    const handleSelection = (newSelectionModel: any) => {
        setSelectionModel(newSelectionModel);
        props.handleSelectionClicked(newSelectionModel);
    };

    return (
        <FlexGrid>
            <DataGridPro
                rows={props.allParticipants}
                getRowId={(row) => row.participantKey}
                loading={props.isLoading}
                columns={addCustomDataGridFilters(COLUMNS as GridColumns<any>)}
                checkboxSelection
                onSelectionModelChange={(newSelectionModel) => {
                    handleSelection(newSelectionModel);
                }}
                selectionModel={selectionModel}
            />
        </FlexGrid>
    );
};

export default TemplateParticipantAssignmentPanel;
