import * as React from "react";
import { Box, Grid, TextField, Typography } from "@mui/material";
import CclButton from "../../../components/common/cclButtons/cclButton";

interface FastAccessSegmentProps {
    label: string;
    title: string;
    callbackFunction: any;
    isLoading: boolean;
    GridItemWidth: number;
    GridTitleWidth: number;
    isError: boolean;
    buttons: FastAccessSegmentButtonProps[];
}

export type FastAccessSegmentButtonProps = { label: string; tabIndex: number, mode: "primary" | "secondary" | "tertiary"};

const FastAccessSegment: React.FC<FastAccessSegmentProps> = (props) => {
    const [value, setValue] = React.useState<string>("");
    const [focus, setFocus] = React.useState<boolean>(false);

    const titleWidth = `${props.GridTitleWidth}%`;
    const itemWidth = `${props.GridItemWidth}%`;
    const buttonBoxWidth = `${100 - props.GridTitleWidth - props.GridItemWidth}%`;

    const onCallToAction = (tabIndex: number) => {
        props.callbackFunction(value, tabIndex);
    };

    document.onkeydown = function (evt) {
        evt = evt || window.event;
        if (evt.keyCode === 13) {
            if (focus && value) {
                if (value === "Select Work Pool") {
                    return;
                }
                props.callbackFunction(value);
            }
        }
    };

    return (
        <Grid
            container
            sx={{
                width: 1,
                display: "flex",
                flexDirection: "row",
                alignContent: "center",
                margin: "2px",
                marginLeft: "20px",
                alignItems: "center",
            }}
        >
            <Grid item sx={{ width: titleWidth }}>
                <Typography fontSize={16} sx={{ marginTop: "auto", marginBottom: "auto" }}>
                    {props.title}
                </Typography>
            </Grid>
            <Grid item sx={{ width: itemWidth }}>
                <TextField
                    fullWidth
                    error={props.isError}
                    size={"small"}
                    label={props.isError ? `${props.title} not found` : props.label}
                    value={value}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setValue(event.target.value);
                    }}
                    onBlur={() => {
                        setFocus(false);
                    }}
                    onFocus={() => {
                        setFocus(true);
                    }}
                    variant={"filled"}
                />
            </Grid>
            <Grid
                item
                sx={{
                    w: buttonBoxWidth,
                    display: "flex",
                    // flexWrap: "inherit",
                    alignContent: "center",
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        alignContent: "center",
                        justifyContent: "space-between",
                    }}
                >
                    {props.buttons.map((button, index) => {
                        return (
                            <CclButton
                                restricted={false}
                                mode={button.mode}
                                onClick={() => onCallToAction(button.tabIndex)}
                                sx={{ width: "116px", marginLeft: "8px", padding: "6px" }}
                                disabled={value === ""}
                                key={button.label}
                            >
                                {button.label}
                            </CclButton>
                        );
                    })}
                </Box>
            </Grid>
        </Grid>
    );
};

export default FastAccessSegment;
