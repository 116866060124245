import * as React from "react";
import { AlertColor, Box, FormControl, InputLabel, MenuItem, Select, Stack } from "@mui/material";
import { useAddRoleToUserMutation } from "../../../../services/cclTokenedApplicationRolesApi ";
import { AddRoleToUser, ApplicationRole } from "../../../../services/types/applicationRolesTypes";
import useLogAccessEvent from "../../../../hooks/useLogAccessEvent";
import { AccessEventUserApplicationRole } from "../../../../services/types/accessEventTypes";
import { UserClaimsService } from "../../../../services/currentUserService/currentUserService";
import { GrandCentralUserByGroups } from "../../../../services/types/cclGrandCentralApiTypes";
import CclButton from "../../../../components/common/cclButtons/cclButton";
import CclLoadingButton from "../../../../components/common/cclButtons/cclLoadingButton";
import CclDrawer from "../../../../components/common/cclDrawer/cclDrawer";
import CclDrawerActionBar from "../../../../components/common/cclDrawer/cclDrawerActionBar";

export interface AddRoleToUserPanelProps {
    open: boolean;
    users: GrandCentralUserByGroups[];
    roles: ApplicationRole[];
    onClose: () => void;
    showSnackBar: (message: string, severity: AlertColor) => void;
}

export const AddRoleToUserPanel: React.FC<AddRoleToUserPanelProps> = (props) => {
    const [roleKey, setRoleKey] = React.useState<number>(0);
    const [imKey, setImKey] = React.useState<number>(0);

    const [AddRoleToUser, { isLoading }] = useAddRoleToUserMutation();
    const { logEvent } = useLogAccessEvent();

    const claimservice = new UserClaimsService();

    const getEmailfromImKey = (imKey: number) => {
        if (!props.users) {
            return "";
        }
        for (let i = 0; i < props.users.length; i++) {
            if (imKey === +props.users[i].imKey) {
                return props.users[i].accountEmails[0].email;
            }
        }
        return "";
    };

    const addNewRoleToUser = () => {
        const payload: AddRoleToUser = {
            roleKey: roleKey,
            imKey: imKey,
            email: getEmailfromImKey(imKey),
            createdUser: claimservice.GetCurrentUserId(),
        };

        if (!roleKey || !imKey || !payload.email || payload.email === "" || !payload.createdUser) {
            props.showSnackBar("Role Addition Failed", "error");
            return;
        }

        AddRoleToUser(payload)
            .unwrap()
            .then((e) => {
                if (e === null) {
                    props.showSnackBar("Role Addition Failed", "error");
                } else {
                    const evtData: AccessEventUserApplicationRole = {
                        imKey: imKey.toString(),
                        email: getEmailfromImKey(imKey),
                    };
                    logEvent("UserApplicationRoleAdded", evtData);
                    props.showSnackBar("Role Added", "success");
                    props.onClose();
                }
            });
    };

    return (
        <>
            <CclDrawer title={"Add Role To User"} open={props.open} onClose={props.onClose}>
                <Box>
                    <Stack spacing={3} sx={{ marginTop: "10px" }}>
                        {props.users && (
                            <FormControl>
                                <InputLabel id="user-select-dropdown">User</InputLabel>
                                <Select
                                    id="user-select-dropdown"
                                    label="User"
                                    onChange={(e) =>
                                        setImKey(
                                            typeof e.target.value === "string" ? +e.target.value : 0
                                        )
                                    }
                                >
                                    {props.users.map((user) => (
                                        <MenuItem value={user.imKey}>
                                            {user.firstName + " " + user.lastName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                        {props.roles && (
                            <FormControl>
                                <InputLabel id="role-select-dropdown">Role</InputLabel>
                                <Select
                                    id="role-select-dropdown"
                                    label="Role"
                                    onChange={(e) =>
                                        setRoleKey(
                                            typeof e.target.value === "number" ? e.target.value : 0
                                        )
                                    }
                                >
                                    {props.roles.map((role) => (
                                        <MenuItem value={role.applicationRoleKey}>
                                            {role.roleName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    </Stack>
                </Box>
                <CclDrawerActionBar>
                    <CclButton
                        onClick={props.onClose}
                        disabled={isLoading}
                        restricted={false}
                        mode={"secondary"}
                    >
                        Cancel
                    </CclButton>
                    <CclLoadingButton
                        onClick={addNewRoleToUser}
                        loading={isLoading}
                        disabled={imKey && roleKey ? false : true}
                        restricted={false}
                        mode={"primary"}
                    >
                        Save
                    </CclLoadingButton>
                </CclDrawerActionBar>
            </CclDrawer>
        </>
    );
};

export default AddRoleToUserPanel;
