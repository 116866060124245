import React from "react";
import { Grid, TextField } from "@mui/material";
import CclButton from "../cclButtons/cclButton";

interface CclDoubleTextSearchBarProps {
    initialFieldOneSearchTerm: string;
    fieldOneSearchLabel: string;
    initialFieldTwoSearchTerm: string;
    fieldTwoSearchLabel: string;
    executeSearch: (fieldOneSearchTerm: string, fieldTwoSearchTerm: string) => void;
    errorStateChange?: (error: boolean) => void;
    requireBothFields: boolean;
    suppressErrors: boolean;
}

const CclDoubleTextSearchBar: React.FC<CclDoubleTextSearchBarProps> = (props) => {
    const [fieldOneSearchString, setFieldOneSearchString] = React.useState<string>(
        props.initialFieldOneSearchTerm ?? ""
    );
    const [fieldOneSearchError, setFieldOneSearchError] = React.useState<boolean>(false);
    const [fieldTwoSearchString, setFieldTwoSearchString] = React.useState<string>(
        props.initialFieldTwoSearchTerm ?? ""
    );
    const [fieldTwoSearchError, setFieldTwoSearchError] = React.useState<boolean>(false);

    React.useEffect(() => {
        setFieldOneSearchString(props.initialFieldOneSearchTerm ?? "");
    }, [props.initialFieldOneSearchTerm]);

    React.useEffect(() => {
        setFieldTwoSearchString(props.initialFieldTwoSearchTerm ?? "");
    }, [props.initialFieldTwoSearchTerm]);

    const searchInputSx = {
        "& .MuiOutlinedInput-input": {
            paddingTop: "8px!important",
            paddingBottom: "8px!important",
        },
        "& .MuiInputLabel-root": {
            marginTop: "-8px!important",
        },
        "& .MuiInputLabel-shrink": {
            marginTop: "auto!important",
        },
    };

    const setFieldOneError = (error: boolean) => {
        if (props.suppressErrors) return;
        if (
            error !== fieldOneSearchError &&
            error &&
            (fieldTwoSearchString === "" || fieldTwoSearchError)
        ) {
            setFieldOneSearchError(error);
            if (props.errorStateChange) props.errorStateChange(error);
        }
    };

    const setFieldTwoError = (error: boolean) => {
        if (props.suppressErrors) return;
        if (
            error !== fieldTwoSearchError &&
            error &&
            (fieldOneSearchString === "" || fieldOneSearchError)
        ) {
            setFieldTwoSearchError(error);
            if (props.errorStateChange) props.errorStateChange(error);
        }
    };

    const handleKeywordSearchKeyPress = async (evt: React.KeyboardEvent<HTMLDivElement>) => {
        if (evt.key === "Enter") {
            if (
                !fieldOneSearchError &&
                !fieldTwoSearchError &&
                (fieldOneSearchString !== "" || fieldTwoSearchString !== "")
            ) {
                evt.preventDefault();
                props.executeSearch(fieldOneSearchString, fieldTwoSearchString);
            }
        }
    };

    return (
        <Grid container spacing={1} padding={2}>
            <Grid item xs={12} sm={5} sx={{ p: 1 }}>
                <TextField
                    value={fieldOneSearchString === "*" ? "" : fieldOneSearchString}
                    sx={searchInputSx}
                    id="outlined-adornment-keyword-search-field-one"
                    label={props.fieldOneSearchLabel ?? "Field One Search Term"}
                    variant="outlined"
                    error={fieldOneSearchError}
                    fullWidth={true}
                    helperText={fieldOneSearchError ? "Must specify an ID or Keyword" : ""}
                    onChange={(e) => {
                        e.preventDefault();
                        setFieldOneSearchString(e.target.value);
                        setFieldOneError(e.target.value === "");
                    }}
                    onKeyDown={(e) => handleKeywordSearchKeyPress(e)}
                />
            </Grid>
            <Grid item xs={12} sm={5} sx={{ p: 1 }}>
                <TextField
                    value={fieldTwoSearchString === "*" ? "" : fieldTwoSearchString}
                    sx={searchInputSx}
                    id="outlined-adornment-keyword-search-field-two"
                    label={props.fieldTwoSearchLabel ?? "Field Two Search Term"}
                    variant="outlined"
                    error={fieldTwoSearchError}
                    fullWidth={true}
                    helperText={fieldTwoSearchError ? "Must specify an ID or Keyword" : ""}
                    onChange={(e) => {
                        e.preventDefault();
                        setFieldTwoSearchString(e.target.value);
                        setFieldTwoError(e.target.value === "");
                    }}
                    onKeyDown={(e) => handleKeywordSearchKeyPress(e)}
                />
            </Grid>
            <Grid item xs={12} sm={2} sx={{ p: 1 }}>
                <CclButton
                    onClick={() =>
                        props.executeSearch(
                            fieldOneSearchString?.trim() ?? "",
                            fieldTwoSearchString?.trim() ?? ""
                        )
                    }
                    aria-label="Search By ID or Keyword"
                    sx={{ width: 1, height: 1 }}
                    disabled={
                        fieldOneSearchError ||
                        fieldTwoSearchError ||
                        (fieldOneSearchString === "" && fieldTwoSearchString === "")
                    }
                    restricted={false}
                    mode={"primary"}
                >
                    Search
                </CclButton>
            </Grid>
        </Grid>
    );
};

export default CclDoubleTextSearchBar;
