import * as React from "react";
import {
    DataGridPro,
    DataGridProProps,
    GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    GridColumnVisibilityModel,
    GridColumns,
    GridRenderCellParams,
    useGridApiRef,
} from "@mui/x-data-grid-pro";
import { useDispatch, useSelector } from "react-redux";
import { AccessEvent } from "../../services/types/accessEventTypes";
import AccessLogDetailToggle from "./accessLogDetailToggle";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
    Box,
    Dialog,
    DialogContent,
    DialogContentText,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from "@mui/material";
import CclDataGridToolbar from "../../components/common/cclDataGridToolbar/cclDataGridToolbar";
import InfoIcon from "@mui/icons-material/Info";
import {
    useSetUserPreferenceMutation,
    useRemoveUserPreferenceMutation,
} from "../../services/cclTokenedUserPreferenceApi";
import { updateAccessEventListGridState } from "../../app/slices/accessEventListTableStateSlice";
import { addCustomDataGridFilters } from "../../components/common/customDataGridFilters/customDataGridFilters";
import CclCommonLink from "../../components/common/cclCommonLink";

const testIfEmpty = (obj: Object) => {
    for (const prop in obj) {
        if (Object.hasOwn(obj, prop)) {
            return true;
        }
    }
    return false;
};

const COLUMNS = [
    {
        ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
        renderCell: (params: GridRenderCellParams) => (
            <AccessLogDetailToggle
                id={params.id}
                value={params.value}
                showEventDetail={testIfEmpty(JSON.parse(params.row.data))}
            />
        ),
        type: "string",
        width: 25,
    },
    {
        field: "createdUtc",
        headerName: "Timestamp",
        valueGetter: (params: any) => new Date(params.row.createdUtc),
        type: "date",
        renderCell: (params: GridRenderCellParams) => <span>{params?.value.toLocaleString()}</span>,
        flex: 0.4,
    },
    {
        field: "accessEventTypeName",
        headerName: "Event",
        type: "string",
        flex: 1,
    },
    {
        field: "userEmail",
        headerName: "User Email",
        type: "string",
        renderCell: (params: GridRenderCellParams) => (
            <CclCommonLink
                text={params.value}
                keyVal={params.value}
                to={`mailto:${params.value}`}
                type={"email"}
            />
        ),
        flex: 1,
    },
    {
        field: "userGroups",
        headerName: "User Group",
        type: "string",
        flex: 1,
    },
    {
        field: "userId",
        headerName: "User ID",
        type: "string",
        flex: 0.4,
    },
];

interface IStringIndex {
    [key: string]: any;
}

export interface AccessLogsDataGridProProps {
    accessEvents: AccessEvent[];
    loading: boolean;
    showResultBar?: boolean;
    resultBarText?: string;
    showSaveState: boolean;
}

export const AccessLogsDataGridPro: React.FC<AccessLogsDataGridProProps> = (props) => {
    const emailClaimKey = "email";
    const imKeyClaimKey = "http://schemas.ccl.org/accounts/claims/ccl-identity/id";

    const stateSelector = useSelector((state: any) => state.accessEventListTableState);
    const stateObj = JSON.parse(stateSelector.TableState);
    const initialColumnVisibilityModel =
        stateObj?.columns?.columnVisibilityModel == null
            ? {
                  id: false,
                  brokerId: false,
                  status: false,
              }
            : stateObj.columns.columnVisibilityModel;
    const [saveUserPref, { isLoading: saveIsLoading }] = useSetUserPreferenceMutation();
    const [deleteUserPref, { isLoading: clearIsLoading }] = useRemoveUserPreferenceMutation();
    const apiRef = useGridApiRef();
    const dispatch = useDispatch();
    const claimsSelector = useSelector((state: any) => state.userClaims);
    const [showTableStateChanged, setShowTableStateChanged] = React.useState<boolean>(false);
    const [showTableChangeMessage, setShowTableChangeMessage] = React.useState<string>("");
    const [columnVisibilityModel, setColumnVisibilityModel] =
        React.useState<GridColumnVisibilityModel>(initialColumnVisibilityModel);

    function getDataGridState(): any {
        var st: any = apiRef.current.exportState();
        var fullstate = apiRef.current.state;
        if (st.columns == null) st.columns = { columnVisibilityModel: {} };
        st.columns.columnVisibilityModel = columnVisibilityModel;
        st.density = fullstate.density.value;
        st.preferencePanel = undefined;
        return st;
    }

    const handleTableStateChange = (isClearEvt: boolean) => {
        const msg = isClearEvt ? "Clearing table settings..." : "Saving table settings...";

        setShowTableChangeMessage(msg);
        setShowTableStateChanged(true);
    };

    const saveStateUserPref = () => {
        saveUserPref({
            email: claimsSelector.claims[emailClaimKey],
            userId: claimsSelector.claims[imKeyClaimKey],
            data: JSON.stringify(getDataGridState()),
            preferenceId: 10,
        });
        handleTableStateChange(false);
    };

    const deleteStateUserPref = () => {
        deleteUserPref({
            email: claimsSelector.claims[emailClaimKey],
            userId: claimsSelector.claims[imKeyClaimKey],
            data: "", // unused in remove operation
            preferenceId: 10,
        });

        // reset view model
        let visibilitymodel: IStringIndex = {};
        COLUMNS.map((c) => (visibilitymodel[c.field] = true));
        setColumnVisibilityModel(visibilitymodel);
        // clear filters
        apiRef.current.setFilterModel({ items: [] });
        // remove column ordering
        COLUMNS.map((c, index, arr) => apiRef.current.setColumnIndex(c.field, index));
        // reset density (hard code to 'compact' for now as that's default for access)
        apiRef.current.setDensity("compact");
        // apply init state sort model
        const sm = JSON.parse(
            JSON.stringify({
                sorting: { sortModel: [{ field: "createdUtc", sort: "desc" }] },
            })
        );
        apiRef.current.setSortModel(sm.sorting.sortModel);
        let st = getDataGridState();
        st.columns.columnVisibilityModel = visibilitymodel;
        dispatch(updateAccessEventListGridState(JSON.stringify(st)));
        handleTableStateChange(true);
    };

    const saveStateToStore = () => {
        dispatch(updateAccessEventListGridState(JSON.stringify(getDataGridState())));
    };

    const onColumnViewModelChangeHandler = (newModel: GridColumnVisibilityModel) => {
        setColumnVisibilityModel(newModel);
        let st = getDataGridState();
        st.columns.columnVisibilityModel = newModel;
        dispatch(updateAccessEventListGridState(JSON.stringify(st)));
    };

    const getDetailPanelContent: DataGridProProps["getDetailPanelContent"] = React.useCallback(
        ({ row }: any) => {
            if (row.accessEventTypeId <= 2) return null;

            const getRows = (jsonObject: object): React.ReactNode[] => {
                let nodes: React.ReactNode[] = [];
                let idx = 0;
                for (var key in jsonObj) {
                    const val = Array.isArray(jsonObj[key]) ? jsonObj[key].join(",") : jsonObj[key];

                    const node: React.ReactNode = (
                        <TableRow
                            key={`${row.accessEventId}-${idx}`}
                            sx={{ p: 0, m: 0, border: 0 }}
                        >
                            <TableCell
                                sx={{ p: 0, m: 0, border: 0, width: "1px", whiteSpace: "nowrap" }}
                            >
                                <Typography variant="button" sx={{ fontWeight: "bold" }}>
                                    {key}:
                                </Typography>
                            </TableCell>
                            <TableCell sx={{ p: 0, m: 0, border: 0 }}>
                                <Typography variant="subtitle1" sx={{ pl: 3 }}>
                                    {val}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    );
                    nodes.push(node);
                    idx++;
                }
                return nodes;
            };

            let jsonObj: any;
            try {
                jsonObj = JSON.parse(row.data);
            } catch {
                jsonObj = { error: "Invalid data logged" };
            }
            return (
                <Box
                    sx={{
                        ml: 15,
                        p: 1,
                        borderWidth: "1px",
                        borderColor: "gray",
                        borderStyle: "solid",
                    }}
                >
                    <Table size="small" aria-label="a dense table" sx={{ border: 0 }}>
                        <TableBody>{getRows(jsonObj)}</TableBody>
                    </Table>
                </Box>
            );
        },
        []
    );

    return (
        <React.Fragment>
            <Dialog
                open={showTableStateChanged}
                onClose={() => setShowTableStateChanged(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <Stack direction="row" alignItems={"center"}>
                        <InfoIcon color="primary" fontSize="large" sx={{ pr: 1 }} />
                        <DialogContentText id="alert-dialog-description">
                            <span>{showTableChangeMessage}</span>
                        </DialogContentText>
                    </Stack>
                </DialogContent>
            </Dialog>
            <DataGridPro
                getRowId={(row: AccessEvent) => row.accessEventId}
                rows={props.accessEvents}
                columns={addCustomDataGridFilters(COLUMNS as GridColumns<any>)}
                loading={props.loading}
                components={{
                    Toolbar: CclDataGridToolbar,
                    DetailPanelExpandIcon: KeyboardArrowRightIcon,
                    DetailPanelCollapseIcon: KeyboardArrowDownIcon,
                }}
                componentsProps={{
                    toolbar: {
                        showSaveState: false,
                        hideSettingsButtons: !props.showSaveState,
                        savestate: saveStateUserPref,
                        clearstate: deleteStateUserPref,
                        resultBar: false,
                        saveInProgress: saveIsLoading,
                        clearInProgress: clearIsLoading,
                    },
                }}
                disableSelectionOnClick={true}
                initialState={stateObj}
                columnVisibilityModel={columnVisibilityModel}
                apiRef={apiRef}
                onColumnOrderChange={saveStateToStore}
                onColumnVisibilityModelChange={(newModel) =>
                    onColumnViewModelChangeHandler(newModel)
                }
                onFilterModelChange={saveStateToStore}
                onPinnedColumnsChange={saveStateToStore}
                onSortModelChange={saveStateToStore}
                hideFooter={false}
                getDetailPanelContent={getDetailPanelContent}
                getDetailPanelHeight={({ row }) => {
                    let jsonObj: any;
                    try {
                        jsonObj = JSON.parse(row.data);
                    } catch {
                        jsonObj = { error: "Invalid data logged" };
                    }
                    return Object.keys(jsonObj).length * 26 + 10;
                }}
            />
        </React.Fragment>
    );
};

export default AccessLogsDataGridPro;
