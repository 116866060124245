import * as React from "react";
import { Button, ButtonProps } from "@mui/material";

type CclButtonProps = ButtonProps & {
    restricted: boolean;
    mode: "primary" | "secondary" | "tertiary";
};

export const CclButton: React.FC<CclButtonProps> = (props) => {
    const { mode, sx, children, ...passthroughprops } = props;

    const v =
        props.mode === "primary" ? "contained" : props.mode === "secondary" ? "outlined" : "text";
    const brdr = mode !== "tertiary" ? "2px solid" : "0px";
    const disabledStyle = {
        backgroundColor: "white",
        color: "#A6A6A6",
        borderColor: "#A6A6A6",
        border: brdr,
        padding: "2px 10px",
    };

    return (
        <Button
            color={"primary"}
            variant={v}
            sx={{
                "&.Mui-disabled": disabledStyle,
                "&:hover": {
                    borderWidth: "2px",
                },
                borderWidth: "2px",
                padding: "4px 10px",
                boxShadow: "none",
                ...sx,
            }}
            {...passthroughprops}
        >
            {children}
        </Button>
    );
};

export default CclButton;
