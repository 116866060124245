import * as React from "react";
import { Stack, TextField, Box, Typography, Alert, AlertColor, AlertTitle } from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { Contract } from "../../../../../../services/types/enterpriseCoachingApiTypes";
import { AssetDownloadRequest } from "../../../../../../services/types/assetApiTypes";
import { FileDownloadService } from "../../../../../../services/fileDownloadService/fileDownloadService";
import CclUnrestrictedLoadingButton from "../../../../../../components/common/cclButtons/cclUnrestrictedLoadingButton";
import CclRestrictedLoadingButton from "../../../../../../components/common/cclButtons/cclRestrictedLoadingButton";
import CclUnrestrictedOutlinedButton from "../../../../../../components/common/cclButtons/cclUnrestrictedOutlinedButton";

export interface SignStepPanelProps {
    pkey: number;
    onSign: (signature: string, businessName: string) => void;
    onPreviousPanel: () => void;
    okToSign: boolean;
    signingContract: boolean;
    contract: Contract | null;
}

export const SignStepPanel: React.FC<SignStepPanelProps> = (props) => {
    const downloadService = new FileDownloadService();

    const [downloading, setDownloading] = React.useState<boolean>(false);
    const [signature, setSignature] = React.useState<string>("");
    const [businessName, setBusinessName] = React.useState<string>("");
    const [alertOpen, setAlertOpen] = React.useState<boolean>(false);
    const [alertMsg, setAlertMsg] = React.useState<string>("");
    const [alertTitle, setAlertTitle] = React.useState<string>("");
    const [alertSeverity, setAlertSeverity] = React.useState<AlertColor>("success");

    const downloadGenericContract = () => {
        if (props?.contract == null) return;

        setDownloading(true);
        setAlertOpen(true);
        setAlertMsg("Downloading unsigned contract...");
        setAlertTitle("Download Unsigned Contract");
        setAlertSeverity("info");

        const request: AssetDownloadRequest = {
            fname: props.contract.title,
            aikeys: [props.contract.aiKey],
            flatzip: false,
        };
        downloadService
            .DownloadAssets(request)
            .then(() => {
                setAlertMsg("Successfully downloaded unsigned contract.");
                setAlertSeverity("success");
                setDownloading(false);
            })
            .catch((error) => {
                const msg = error.message ?? "Unknown error";
                setAlertMsg(`Failed to downloaded unsigned contract (error: ${msg})`);
                setAlertSeverity("error");
                setDownloading(false);
            });
    };

    const handleSign = () => {
        if (businessName !== "" && signature !== "") props.onSign(signature, businessName);
    };

    return (
        <Stack direction={"column"}>
            <Box
                component="form"
                sx={{
                    width: "100%",
                    borderStyle: "solid",
                    borderRadius: 3,
                    borderWidth: 1,
                    borderColor: "lightgray",
                    p: 4,
                    mt: 5,
                    mb: 3,
                }}
            >
                <Stack direction={"column"} width={1} spacing={3}>
                    <Typography variant="h5">Please review and sign your Contract.</Typography>
                    <Typography variant="body2" sx={{ pb: 3, color: "text.secondary" }}>
                        Closing the screen will stop the Sign Contract process but you will be able
                        to restart it again.
                    </Typography>
                    <CclUnrestrictedLoadingButton
                        sx={{ mb: 3, width: 0.5 }}
                        onClick={downloadGenericContract}
                        loading={downloading}
                    >
                        Download And Review Contract
                    </CclUnrestrictedLoadingButton>
                    {alertOpen ? (
                        <Alert
                            severity={alertSeverity}
                            sx={{ mb: 3, width: 0.5 }}
                            onClose={() => setAlertOpen(false)}
                        >
                            <AlertTitle>{alertTitle}</AlertTitle>
                            {alertMsg}
                        </Alert>
                    ) : null}
                    <Typography sx={{ pb: 3 }}>
                        By typing your legal name and complete business name below and pressing the
                        “Submit” button, you are agreeing to the terms and conditions as spelled out
                        in the Center for Creative Leadership Independent Contractor Agreement
                        2023/24. If you do not wish to do that please close the screen using the
                        cross in the top right and click the "Decline Contract" button.
                    </Typography>
                    <Box>
                        <Typography sx={{ pb: 2, display: "inline" }}>
                            Signature (legal name)
                        </Typography>
                        <Typography
                            variant="h5"
                            color={"error"}
                            sx={{
                                pl: 1,
                                display: "inline",
                                textDecoration: "bold",
                            }}
                        >
                            *
                        </Typography>
                    </Box>
                    <TextField
                        label={"Signature"}
                        fullWidth
                        id="signature"
                        value={signature}
                        size="small"
                        sx={{ fontSize: "0.8125rem" }}
                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                            setSignature(evt.target.value);
                        }}
                    />
                    <Box>
                        <Typography sx={{ pb: 2, display: "inline" }}>
                            Business Name (as it appears on invoice)
                        </Typography>
                        <Typography
                            variant="h5"
                            color={"error"}
                            sx={{
                                pl: 1,
                                display: "inline",
                                textDecoration: "bold",
                            }}
                        >
                            *
                        </Typography>
                    </Box>
                    <TextField
                        label={"Business Name"}
                        fullWidth
                        id="business-name"
                        value={businessName}
                        size="small"
                        sx={{ fontSize: "0.8125rem" }}
                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                            setBusinessName(evt.target.value);
                        }}
                    ></TextField>
                </Stack>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <CclUnrestrictedOutlinedButton
                    onClick={() => props.onPreviousPanel()}
                    startIcon={<NavigateBeforeIcon />}
                    sx={{ mr: 1 }}
                >
                    Back
                </CclUnrestrictedOutlinedButton>
                <Box sx={{ flex: "1 1 auto" }} />
                {!props.okToSign ? (
                    <Alert severity="error" sx={{ mr: 5, pt: 1, pb: 1 }}>
                        There are required fields that you have not addressed on previous tabs. You
                        must complete all required fields in order to sign your contract.
                    </Alert>
                ) : null}
                <CclRestrictedLoadingButton
                    disabled={!props.okToSign || signature === "" || businessName === ""}
                    onClick={handleSign}
                    loading={props.signingContract}
                >
                    Sign Contract
                </CclRestrictedLoadingButton>
            </Box>
        </Stack>
    );
};

export default SignStepPanel;
