import React from "react";
import { RaterDetails } from "../../../../services/types/assessmentAPITypes";
import {
    DataGridPro,
    GridColumns,
    GridRenderCellParams,
    GridSelectionModel,
} from "@mui/x-data-grid-pro";
import { addCustomDataGridFilters } from "../../../../components/common/customDataGridFilters/customDataGridFilters";
import { Link } from "@mui/material";
import CclDataGridToolbar from "../../../../components/common/cclDataGridToolbar/cclDataGridToolbar";

export interface RaterManagementDataGridProProps {
    programCode: string;
    ratersDetailsList: RaterDetails[];
    handleButtonClick: (buttonId: string, ids: number[]) => void;
    isLoading: boolean;
    loadingButtonId: string;
    showToolbar: boolean;
    isUserRaterManager: boolean;
    isSessionRater: boolean;
    readOnly?: boolean;
}

export const RaterManagementDataGridPro: React.FC<RaterManagementDataGridProProps> = (props) => {
    const [ids, setIds] = React.useState<number[]>([]);

    const handleButtonClick = (buttonId: string) => {
        props.handleButtonClick(buttonId, ids);
    };

    const COLUMNS = [
        {
            field: "participantName",
            headerName: "Participant",
            type: "string",
            renderCell: (params: GridRenderCellParams) => (
                <Link href={`/registration/${encodeURI(params.row.participant_ESI_Key)}`}>
                    {params.value}
                </Link>
            ),
            flex: 0.4,
        },
        {
            field: "raterName",
            headerName: "Rater",
            type: "string",
            flex: 0.4,
        },
        {
            field: "raterEmail",
            headerName: "Email",
            type: "string",
            flex: 0.4,
        },
        {
            field: "formMasterName",
            headerName: "Assessment",
            type: "string",
            flex: 0.4,
        },
        {
            field: "raterTypeName",
            headerName: "Type",
            type: "string",
            flex: 0.4,
        },
        {
            field: "bpiasT_Name",
            headerName: "Status",
            type: "string",
            flex: 0.3,
        },
    ];

    return (
        <>
            <DataGridPro
                columns={
                    props.isUserRaterManager
                        ? props.isSessionRater
                            ? addCustomDataGridFilters(COLUMNS as GridColumns<any>)
                            : addCustomDataGridFilters(
                                  COLUMNS.filter(
                                      (c) => c.field !== "participantName"
                                  ) as GridColumns<any>
                              )
                        : props.isSessionRater
                        ? addCustomDataGridFilters(
                              COLUMNS.filter(
                                  (c) => c.field !== "bpiasT_Name" && c.field !== "actions"
                              ) as GridColumns<any>
                          )
                        : props.isSessionRater
                        ? addCustomDataGridFilters(
                              COLUMNS.filter(
                                  (c) => c.field !== "bpiasT_Name" && c.field !== "actions"
                              ) as GridColumns<any>
                          )
                        : addCustomDataGridFilters(
                              COLUMNS.filter(
                                  (c) =>
                                      c.field !== "bpiasT_Name" &&
                                      c.field !== "actions" &&
                                      c.field !== "participantName"
                              ) as GridColumns<any>
                          )
                }
                rows={props.ratersDetailsList}
                loading={props.isLoading}
                getRowId={(row: RaterDetails) => row.rM_Key}
                checkboxSelection
                components={{ Toolbar: CclDataGridToolbar }}
                componentsProps={
                    props.isUserRaterManager && !props.readOnly
                        ? {
                              toolbar: {
                                  showSaveState: true,
                                  hideSettingsButtons: true,
                                  miscButtons: [
                                      {
                                          id: "add",
                                          caption: "Add Rater",
                                          disable: false,
                                          returnSelected: false,
                                          restricted: false,
                                          onClick: () => props.handleButtonClick("add", ids),
                                      },
                                      {
                                          id: "edit",
                                          caption: "Edit Rater",
                                          disable: ids.length !== 1,
                                          returnSelected: true,
                                          restricted: false,
                                          onClick: () => props.handleButtonClick("edit", ids),
                                      },
                                      {
                                          id: "delete",
                                          caption: "Delete Rater",
                                          disable: ids.length !== 1,
                                          returnSelected: true,
                                          restricted: false,
                                          onClick: () => props.handleButtonClick("delete", ids),
                                          loadingBtn: true,
                                          isLoading: props.loadingButtonId === "delete",
                                      },
                                      {
                                          id: "invitation",
                                          caption: "Resend Invitation(s)",
                                          disable: ids.length <= 0,
                                          returnSelected: true,
                                          restricted: false,
                                          onClick: () => props.handleButtonClick("invitation", ids),
                                          loadingBtn: true,
                                          isLoading: props.loadingButtonId === "invitation",
                                      },
                                      {
                                          id: "reminder",
                                          caption: "Resend Reminder(s)",
                                          disable: ids.length <= 0,
                                          returnSelected: true,
                                          restricted: false,
                                          onClick: () => props.handleButtonClick("reminder", ids),
                                          loadingBtn: true,
                                          isLoading: props.loadingButtonId === "reminder",
                                      },
                                  ],
                                  anySelected: ids?.length > 0,
                                  btnClick: handleButtonClick,
                                  isRaterMangementRole: props.isUserRaterManager,
                              },
                          }
                        : undefined
                }
                onSelectionModelChange={(ids: GridSelectionModel) => {
                    setIds(ids.map((i) => parseInt(i.toString())));
                }}
                autoHeight
            />
        </>
    );
};

export default RaterManagementDataGridPro;
