import * as React from "react";
import {
    Alert,
    AlertColor,
    Autocomplete,
    Box,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import { Editor } from "@tinymce/tinymce-react";
import ComponentLoader from "../../../../components/common/componentLoader";
import EmailViewer from "../../../../components/common/emailViewer";
import {
    useGetCclEventByEventKeyQuery,
    useGetNotificationByEventKeyQuery,
    useGetNotificationMetadataMutation,
    usePreviewNotificationMutation,
    useResetNotificationMetadataMutation,
    useSaveNotificationMetadataMutation,
} from "../../../../services/cclTokenedSessionApi";
import { AccessEventSessionDetails } from "../../../../services/types/accessEventTypes";
import useLogAccessEvent from "../../../../hooks/useLogAccessEvent";
import CclButton from "../../../../components/common/cclButtons/cclButton";
import CclLoadingButton from "../../../../components/common/cclButtons/cclLoadingButton";
import CclAlertSnackbar from "../../../../components/common/cclAlertSnackbar";

interface CustomNotificationPanelProps {
    sessionId: string;
}

const CustomNotificationPanel: React.FC<CustomNotificationPanelProps> = (props) => {
    const { data, isLoading, isSuccess } = useGetNotificationByEventKeyQuery(props.sessionId, {
        skip: !props.sessionId,
    });
    const { data: sessionDetail } = useGetCclEventByEventKeyQuery(props.sessionId, {
        skip: !props.sessionId,
    });
    const lableColor = blue[500];
    const [previewNotification, { data: pax, isLoading: paxIsLoading, isSuccess: paxIsSuccess }] =
        usePreviewNotificationMutation();
    const [GetNotificationMetdata, { data: newdata }] = useGetNotificationMetadataMutation();
    const [
        ResetNotificationMetadata,
        {
            data: resetdata,
            isSuccess: resetIsSuccess,
            isLoading: resetIsLoading,
            isError: resetError,
        },
    ] = useResetNotificationMetadataMutation();
    const [
        SaveNotificationMetadata,
        { isLoading: saveIsLoading, isSuccess: saveIsSuccess, isError: saveIsError },
    ] = useSaveNotificationMetadataMutation();
    const [clavinId, setClavinId] = React.useState<string | null>(
        data?.customProgramTemplateHeaders[0].emailBaseTypeName
            ? data?.customProgramTemplateHeaders[0].emailBaseTypeName
            : null
    );
    const [languageKey, setLanguageKey] = React.useState<string | null>(
        data?.language[0].name ? data?.language[0].name : null
    );
    const [subjectValue, setsubjectValue] = React.useState(data?.subject);
    const [contentValue, setcontentValue] = React.useState(data?.content);
    const [status, setstatus] = React.useState(data?.status);
    const [statusText, setstatusText] = React.useState(
        "This is the default version of this email."
    );
    const [open, setOpen] = React.useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = React.useState<AlertColor>("success");
    const [snackbarMessage, setSnackbarMessage] = React.useState("");
    const [sessionCode, setSessionCode] = React.useState<string>("");
    const { logEvent } = useLogAccessEvent();

    React.useEffect(() => {
        if (sessionDetail == null) return;
        setSessionCode(sessionDetail.sessionCode);
    }, [sessionDetail]);

    React.useEffect(() => {
        setClavinId(
            data?.customProgramTemplateHeaders[0].emailBaseTypeName
                ? data?.customProgramTemplateHeaders[0].emailBaseTypeName
                : null
        );
        setLanguageKey(data?.language[0].name ? data?.language[0].name : null);
        setsubjectValue(data?.subject);
        setcontentValue(data?.content);
        setstatus(data?.status);
        statusTextDisplay(data?.status);
    }, [data]);

    React.useEffect(() => {
        if (newdata) {
            setsubjectValue(newdata?.subject);
            setcontentValue(newdata?.content);
            setstatus(newdata?.status);
            statusTextDisplay(newdata?.status);
        }
    }, [newdata]);

    React.useEffect(() => {
        if (resetdata) {
            setsubjectValue(resetdata?.subject);
            setcontentValue(resetdata?.content);
            setstatus(resetdata?.status);
            statusTextDisplay(resetdata?.status);
        }
    }, [resetdata]);

    const previewNotificationClick = () => {
        previewNotification({
            eskey: data?.esKey!,
            languageId: data?.language.filter((e) => e.name === languageKey)[0].languageId!,
            clavinId: data?.customProgramTemplateHeaders.filter(
                (e) => e.emailBaseTypeName === clavinId
            )[0].emailTypeId!,
            subject: subjectValue!,
            content: contentValue!,
            status: data?.status!,
        });
        console.log("hello");
    };

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    const statusTextDisplay = (currentSTatus: number | undefined) => {
        if (currentSTatus === 1)
            setstatusText("This email is customized and currently in draft awaiting publishing.");
        if (currentSTatus === 2) setstatusText("This email is customized and published.");
        if (currentSTatus === 0) setstatusText("This is the default version of this email.");
    };
    const getMetDataClavin = (param: string | null) => {
        setClavinId(param);
        setLanguageKey("English");
        GetNotificationMetdata({
            eskey: data?.esKey!,
            languageId: data?.language.filter((e) => e.name === languageKey)[0].languageId!,
            clavinId: data?.customProgramTemplateHeaders.filter(
                (e) => e.emailBaseTypeName === param
            )[0].emailTypeId!,
        });
    };

    const getMetDataLanguage = (param: string | null) => {
        setLanguageKey(param);
        GetNotificationMetdata({
            eskey: data?.esKey!,
            languageId: data?.language.filter((e) => e.name === param)[0].languageId!,
            clavinId: data?.customProgramTemplateHeaders.filter(
                (e) => e.emailBaseTypeName === clavinId
            )[0].emailTypeId!,
        });
    };

    const resetNotificationOnClick = () => {
        ResetNotificationMetadata({
            eskey: data?.esKey!,
            languageId: data?.language.filter((e) => e.name === languageKey)[0].languageId!,
            clavinId: data?.customProgramTemplateHeaders.filter(
                (e) => e.emailBaseTypeName === clavinId
            )[0].emailTypeId!,
        })
            .unwrap()
            .then(() => {
                setOpen(true);
            });
    };

    React.useEffect(() => {
        if (resetIsSuccess) {
            setSnackbarSeverity("success");
            setSnackbarMessage("Email Reset");
        } else if (resetError) {
            setSnackbarSeverity("error");
            setSnackbarMessage("Email Reset Failed");
        }
    }, [resetIsSuccess, resetError]);

    React.useEffect(() => {
        if (saveIsSuccess && status === 1) {
            setSnackbarSeverity("success");
            setSnackbarMessage("Custom Email Saved");
        }
        if (saveIsSuccess && status === 2) {
            setSnackbarSeverity("success");
            setSnackbarMessage("Custom Email Published");
        }
        if (saveIsError && status === 1) {
            setSnackbarSeverity("error");
            setSnackbarMessage("Custom Email Save Failed");
        }
        if (saveIsError && status === 2) {
            setSnackbarSeverity("error");
            setSnackbarMessage("Custom Email Publishing Failed");
        }
        //eslint-disable-next-line
    }, [saveIsSuccess, saveIsError, status]);

    const saveNotificationOnClick = (NotificationStatus: number) => {
        setstatus(NotificationStatus);
        SaveNotificationMetadata({
            eskey: data?.esKey!,
            languageId: data?.language.filter((e) => e.name === languageKey)[0].languageId!,
            clavinId: data?.customProgramTemplateHeaders.filter(
                (e) => e.emailBaseTypeName === clavinId
            )[0].emailTypeId!,
            subject: subjectValue!,
            content: contentValue!,
            status: NotificationStatus!,
        })
            .unwrap()
            .then(() => {
                setOpen(true);
                const evtData: AccessEventSessionDetails = {
                    projectCode: sessionCode,
                };

                const tag: string =
                    NotificationStatus === 1
                        ? "CustomNotificationSaved"
                        : "CustomNotificationPublished";
                logEvent(tag, evtData);
            });
        statusTextDisplay(NotificationStatus);
    };

    if (isLoading) {
        return <ComponentLoader msg={"Loading Emails"} />;
    }
    if (
        isSuccess &&
        ((data.programs.derivedEventTypeId !== 3 && data.programs.derivedEventTypeId !== 5) ||
            data.programs.surveyPlatformId !== 1)
    ) {
        return (
            <Grid container>
                <Grid item xs={12} md={8}>
                    <Card sx={{ height: 1, width: 1, m: 2, p: 2, md: 6 }}>
                        <Alert severity="info">
                            Email customization is not available for this session. Email
                            customization is only available for Custom Programs and Coaching
                            Engagements that include the Assessment Platform.
                        </Alert>
                    </Card>
                </Grid>
            </Grid>
        );
    }
    if (
        isSuccess &&
        (data.programs.derivedEventTypeId === 3 || data.programs.derivedEventTypeId === 5) &&
        data.programs.surveyPlatformId === 1
    ) {
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <Card>
                        <CardHeader title="Customize Emails" />
                        <CardContent>
                            <Stack spacing={3}>
                                <Alert severity="info">{statusText}</Alert>
                                <Box pt={5}>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-template"
                                        onChange={(event: any, newValue: string | null) =>
                                            getMetDataClavin(newValue)
                                        }
                                        value={clavinId}
                                        options={data.customProgramTemplateHeaders.map(
                                            (email) => email.emailBaseTypeName
                                        )}
                                        sx={{ width: 1 }}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Available Templates" />
                                        )}
                                    />
                                </Box>
                                <Stack spacing={2} pb={2}>
                                    <Typography
                                        variant="caption"
                                        sx={{ color: lableColor }}
                                        gutterBottom
                                    >
                                        This email is available for customization in the following
                                        languages:
                                    </Typography>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-language"
                                        onChange={(event: any, newValue: string | null) =>
                                            getMetDataLanguage(newValue)
                                        }
                                        value={languageKey}
                                        options={data.language.map((option) => option.name)}
                                        sx={{ width: 1 }}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Languages" />
                                        )}
                                    />
                                </Stack>
                                <Divider light />
                                <Stack spacing={2} pt={5} pb={2}>
                                    <Typography variant="subtitle1">Subject</Typography>
                                    <Editor
                                        apiKey="y7y53muqd751xs3r3tgo3wey3uwqehd18hiyp1s2pjdprezv"
                                        onInit={(evt: any, editor) =>
                                            setsubjectValue(editor.getContent({ format: "text" })!)
                                        }
                                        onEditorChange={(newValue: any, editor) => {
                                            setsubjectValue(editor.getContent({ format: "text" }));
                                        }}
                                        value={subjectValue}
                                        init={{
                                            height: 200,
                                            menubar: false,
                                            inline: false,
                                            branding: false,
                                            suffix: ".min",
                                            plugins: [
                                                "print preview",
                                                "searchreplace visualblocks code",
                                                "paste code help properties",
                                            ],
                                            toolbar: "undo redo | properties | help",
                                            external_plugins: {
                                                properties: "/plugins/properties-plugin.js",
                                            },
                                            contextmenu: "assessment participant program rater",
                                            content_style:
                                                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                        }}
                                    />
                                </Stack>
                                <Divider light />

                                <Stack spacing={2} pt={5}>
                                    <Typography variant="subtitle1">Message Content</Typography>
                                    <Editor
                                        apiKey="y7y53muqd751xs3r3tgo3wey3uwqehd18hiyp1s2pjdprezv"
                                        onInit={(
                                            evt: any,
                                            editor: { getContent: () => string | undefined }
                                        ) => setcontentValue(editor.getContent()!)}
                                        onEditorChange={(newValue: string | undefined) =>
                                            setcontentValue(newValue)
                                        }
                                        value={contentValue}
                                        init={{
                                            height: 500,
                                            menubar: "file edit view insert format table help",
                                            branding: false,
                                            external_plugins: {
                                                properties: "/plugins/properties-plugin.js",
                                            },
                                            contextmenu: "assessment participant program rater",
                                            menu: {
                                                insert: {
                                                    title: "Insert",
                                                    items: "image link inserttable | charmap | insertdatetime | assessment participant program rater",
                                                },
                                                format: {
                                                    title: "Format",
                                                    items: "bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontsizes align | forecolor backcolor | removeformat",
                                                },
                                            },
                                            plugins: [
                                                "advlist autolink lists link image charmap print preview",
                                                "searchreplace visualblocks code fullscreen",
                                                "insertdatetime table paste code help properties",
                                            ],
                                            toolbar:
                                                "undo redo | formatselect | properties | bold italic fontcolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help",
                                            content_style:
                                                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                        }}
                                    />
                                </Stack>
                                <Stack direction={"row"} spacing={3} justifyContent="left">
                                    {status !== 2 && (
                                        <CclLoadingButton
                                            onClick={() => {
                                                saveNotificationOnClick(1);
                                            }}
                                            disabled={saveIsLoading}
                                            restricted={false}
                                            loading={saveIsLoading && status === 1}
                                            mode={"primary"}
                                        >
                                            Save
                                        </CclLoadingButton>
                                    )}
                                    <CclLoadingButton
                                        onClick={() => {
                                            saveNotificationOnClick(2);
                                        }}
                                        disabled={saveIsLoading}
                                        loading={saveIsLoading && status === 2}
                                        mode={"primary"}
                                        restricted={false}
                                    >
                                        Publish
                                    </CclLoadingButton>
                                    <CclButton
                                        onClick={previewNotificationClick}
                                        restricted={false}
                                        mode={"primary"}
                                    >
                                        Preview
                                    </CclButton>
                                    <CclLoadingButton
                                        onClick={() => {
                                            resetNotificationOnClick();
                                        }}
                                        mode={"primary"}
                                        restricted={false}
                                        loading={resetIsLoading}
                                    >
                                        Reset
                                    </CclLoadingButton>
                                </Stack>
                                <CclAlertSnackbar
                                    open={open}
                                    onClose={handleClose}
                                    severity={snackbarSeverity}
                                    message={snackbarMessage}
                                />
                            </Stack>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Card>
                        <CardHeader title="Preview" />
                        <CardContent>
                            {paxIsLoading && <ComponentLoader msg={"Loading Email Preview"} />}
                            {paxIsSuccess && (
                                <Stack spacing={2}>
                                    <Typography variant="subtitle1">
                                        {" "}
                                        Subject : {pax?.subject}{" "}
                                    </Typography>
                                    <EmailViewer
                                        content={
                                            pax !== undefined && pax !== null
                                                ? pax?.content
                                                : "No Data"
                                        }
                                        disableLinks={true}
                                    />
                                </Stack>
                            )}
                            {pax === undefined && !paxIsLoading && (
                                <Box minHeight={300}>
                                    <Alert severity="info">
                                        Click Preview to review this template.
                                    </Alert>
                                </Box>
                            )}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        );
    }
    return <span>No Email Data Loaded</span>;
};

export default CustomNotificationPanel;
