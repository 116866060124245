import * as React from "react";
import { GridToolbarContainer } from "@mui/x-data-grid-pro";
import { CclPersistantDataGridMiscButton } from "../cclPersistentDataGrid/cclPersistentDataGrid";
import CclButton from "../cclButtons/cclButton";

//TODO - Resolve disparity between btn.onClick and btnClick, currently there seems to be no need for the btn.onClick prop inside of miscButtons

export interface CclDataGridCustomButtonToolbarProps {
    resultBar?: boolean;
    resultBarText?: string;
    miscButtons: CclPersistantDataGridMiscButton[];
    btnClick: (btnId: string) => any;
    anySelected: boolean;
}

const CclToolbarBtnSx = {
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "4px",
    paddingBottom: "4px",
    margin: "5px",
    fontSize: "12px",
    lineHeight: "21px",
};

const CclDataGridCustomButtonToolbar: React.FC<CclDataGridCustomButtonToolbarProps> = (props) => {
    const handleMiscClick = (btnid: string) => {
        if (props.btnClick != null) props.btnClick(btnid);
    };

    return (
        <GridToolbarContainer>
            {props.resultBar && <span>{props.resultBarText}</span>}
            {props.miscButtons &&
                props.miscButtons.map((btn) => (
                    <CclButton
                        key={btn.id}
                        id={btn.id}
                        disabled={btn.enableOnSelect ? !props.anySelected : btn.disable}
                        sx={CclToolbarBtnSx}
                        onClick={() => handleMiscClick(btn.id)}
                        restricted={false}
                        mode={"primary"}
                    >
                        {btn.caption}
                    </CclButton>
                ))}
        </GridToolbarContainer>
    );
};

export default CclDataGridCustomButtonToolbar;
