import * as React from "react";
import { Box, Card, List, Stack, Typography } from "@mui/material";
import UploadFileListItem from "./uploadFileListItem";
import StyledDropzone from "../../../../sessions/sessionDetail/files/fileUploadDialog/styledDropzone";
import useLogAccessEvent from "../../../../../hooks/useLogAccessEvent";
import { AccessEventIndividualDetails } from "../../../../../services/types/accessEventTypes";
import CclDrawer from "../../../../../components/common/cclDrawer/cclDrawer";
import CclUnrestrictedButton from "../../../../../components/common/cclButtons/cclUnrestrictedButton";

type FileUploadItem = {
    file: File;
    uploadAttempted: boolean;
    uploadSuccess: boolean;
};

interface FileUploadDrawerProps {
    email: string;
    imkey: number;
    open: boolean;
    onClose: () => void;
}

const FileUploadDrawer: React.FC<FileUploadDrawerProps> = (props) => {
    const maxFilesToUpload = 3;
    const [fileUploadItemsList, setFileUploadItemsList] = React.useState<FileUploadItem[]>([]);
    const { logEvent } = useLogAccessEvent();

    const enableUpload = fileUploadItemsList.filter((f) => !f.uploadAttempted).length <= 0;

    React.useEffect(() => {
        if (!props.open) setFileUploadItemsList([]);
    }, [props.open]);

    const addFile = (acceptedFiles: File[]) => {
        const newuploaditems = acceptedFiles.map((f) => {
            return {
                file: f,
                id: "none", // will be a guid after first chunk uploaded
                uploadAttempted: false,
                uploadSuccess: false,
            };
        });
        setFileUploadItemsList((prev) => [...prev, ...newuploaditems]);
    };

    const removeFile = (file: File) => {
        const newFileItemsList = fileUploadItemsList.filter((item) => item.file.name !== file.name);
        setFileUploadItemsList(newFileItemsList);
    };

    const fileUploadResult = (file: File, status: boolean) => {
        const updatedUploadItems = fileUploadItemsList.map((ui) => {
            if (ui.file.name === file.name) {
                return { ...ui, uploadSuccess: status };
            }
            return ui;
        });
        setFileUploadItemsList(updatedUploadItems);
        startNextUpload(false);
    };

    const startNextUpload = (logUploadEvent: boolean) => {
        // get the next file to upload (alpabetical by those with attempted == false)
        const itemsToUpload = fileUploadItemsList
            .filter((ui) => !ui.uploadAttempted)
            .sort((a, b) => {
                return a.file.name.localeCompare(b.file.name);
            });

        if (itemsToUpload == null || itemsToUpload.length <= 0) return;

        const updatedUploadItems = fileUploadItemsList.map((ui) => {
            if (ui.file.name === itemsToUpload[0].file.name) {
                return { ...ui, uploadAttempted: true };
            }
            return ui;
        });

        setFileUploadItemsList(updatedUploadItems);
        // only log the button press, not individual uploads
        if (logUploadEvent) {
            const evtData: AccessEventIndividualDetails = {
                imKey: props.imkey.toString(),
                email: props.email,
            };

            logEvent("IndividualFilesUploaded", evtData);
        }
    };

    return (
        <CclDrawer open={props.open} onClose={props.onClose} title="Upload Files">
            <Box height={0.99} width={1}>
                <Stack direction="row" spacing={3} width={1} height={0.95}>
                    <Card sx={{ width: 1, height: 0.98 }}>
                        <Stack
                            spacing={3}
                            sx={{ pl: 3, pr: 3, pt: 1, maxHeight: "calc(80%-10px)" }}
                        >
                            <Typography
                                variant={"h6"}
                                sx={{
                                    marginTop: 2,
                                    marginBottom: 2,
                                    fontWeight: "nomral",
                                }}
                            >
                                Select Files For Upload
                            </Typography>
                            <StyledDropzone
                                disabled={fileUploadItemsList.length >= maxFilesToUpload}
                                onAddFile={addFile}
                                maxFilesToUpload={maxFilesToUpload}
                            />
                            <Stack
                                width={1}
                                direction="row"
                                display="flex"
                                justifyContent="space-between"
                                sx={{ pl: 2, pr: 2 }}
                            >
                                <CclUnrestrictedButton
                                    disabled={fileUploadItemsList.length === 0}
                                    size="small"
                                    onClick={() => setFileUploadItemsList([])}
                                >
                                    Clear Files
                                </CclUnrestrictedButton>
                                <CclUnrestrictedButton
                                    size="small"
                                    onClick={() => startNextUpload(true)}
                                    disabled={enableUpload}
                                >
                                    Upload Files
                                </CclUnrestrictedButton>
                            </Stack>
                            {fileUploadItemsList && fileUploadItemsList.length > 0 ? (
                                <Typography variant="subtitle1" sx={{ pl: 2, pr: 2 }}>
                                    Files To Upload
                                </Typography>
                            ) : null}
                            <List sx={{ mt: 0, pb: 0, borderBottom: "1px solid lightgray" }}>
                                {fileUploadItemsList &&
                                    fileUploadItemsList.length > 0 &&
                                    fileUploadItemsList.map((item) => (
                                        <UploadFileListItem
                                            key={item.file.name}
                                            file={item.file}
                                            properties={{}}
                                            makeAvailable={true}
                                            startUpload={item.uploadAttempted}
                                            deleteFile={removeFile}
                                            onUploadResult={fileUploadResult}
                                            imkeys={[props.imkey]}
                                        />
                                    ))}
                            </List>
                        </Stack>
                    </Card>
                </Stack>
                <Box sx={{ mt: 2 }}>
                    <CclUnrestrictedButton onClick={props.onClose} sx={{ float: "right" }}>
                        Close
                    </CclUnrestrictedButton>
                </Box>
            </Box>
        </CclDrawer>
    );
};

export default FileUploadDrawer;
