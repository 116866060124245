import React, { useRef, Component } from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";

import { useAuth0 } from "@auth0/auth0-react";
import { UserClaimsService } from "../../services/currentUserService/currentUserService";
import { withIdleTimer, IIdleTimer } from "react-idle-timer";
import CclUnrestrictedButton from "./cclButtons/cclUnrestrictedButton";

// this is to accommodate the new version (5.5) of react-idle-timer working as IdleTimer Component
class IdleTimerComponent extends Component<IIdleTimer> {
    render() {
        return <></>;
    }
}

export const IdleTimer = withIdleTimer(IdleTimerComponent);
// SessionTime Out Component(Used - React Idle Timer)
const oneMinute = 60000;

const SessionTimeOut = () => {
    const idleTime = useRef(null);
    const [isOpen, setIsOpen] = React.useState(false);
    const idleTimeOut = useRef<any>(null);
    const { logout } = useAuth0();

    const claimsService = new UserClaimsService();
    // OnIdle
    const onIdle = () => {
        setIsOpen(true);
        idleTimeOut.current = setTimeout(() => {
            logOut();
        }, 2 * oneMinute);
    };

    //Stay Active
    const stayActive = () => {
        setIsOpen(false);
        clearTimeout(idleTimeOut.current);
    };

    //LogOut
    const logOut = () => {
        claimsService.ClearToken();
        logout({ logoutParams: { returnTo: window.location.origin } });
    };

    let timeoutVal = 60 * oneMinute;

    return (
        <div>
            <Dialog open={isOpen}>
                <DialogTitle>Session TimeOut</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        You are about to be automatically logged out due to inactivity.{" "}
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ float: "left", marginLeft: "5px" }}>
                    <CclUnrestrictedButton
                        onClick={stayActive}
                        size="small"
                        style={{
                            marginRight: "10px",
                        }}
                    >
                        Stay Active
                    </CclUnrestrictedButton>
                    <CclUnrestrictedButton
                        onClick={logOut}
                        size="small"
                        style={{
                            marginRight: "10px",
                        }}
                    >
                        Logout
                    </CclUnrestrictedButton>
                </DialogActions>
            </Dialog>
            <IdleTimer ref={idleTime as any} timeout={timeoutVal} onIdle={onIdle} />
        </div>
    );
};

export default SessionTimeOut;
