import * as React from "react";
import useLogAccessEvent from "../../../../hooks/useLogAccessEvent";
import AddEmailTemplateTabOne from "./createTemplateDialogTabOne";
import { useCreateEmailTemplateMutation } from "../../../../services/cclTokenedEmailTemplateApi";
import { EmailMessageTemplate } from "../../../../services/types/emailTemplateTypes";
import { UserClaimsService } from "../../../../services/currentUserService/currentUserService";
import { AccessEventEmailTemplate } from "../../../../services/types/accessEventTypes";
import CclDrawer from "../../../../components/common/cclDrawer/cclDrawer";
import { Box } from "@mui/material";
import CclButton from "../../../../components/common/cclButtons/cclButton";
import CclLoadingButton from "../../../../components/common/cclButtons/cclLoadingButton";

export interface addEmailtemplate {
    open: boolean;
    onClose: (showSnackBar: boolean, error?: boolean) => void;
}

export const AddEmailtemplate: React.FC<addEmailtemplate> = (props) => {
    let claimservice = new UserClaimsService();

    const [trackingId, setTrackingId] = React.useState<string>("");
    const [templateName, setTemplateName] = React.useState<string>("");
    const [subject, setSubject] = React.useState<string>("");
    const [content, setContent] = React.useState<string>("");
    const [ccRecipients, setCcRecipients] = React.useState<string>("");
    const [bccRecipients, setBccRecipients] = React.useState<string>("");
    const [notificationType, setNotificationType] = React.useState<number>(1);

    const [createTemplate, { isLoading, isError }] = useCreateEmailTemplateMutation();

    const { logEvent } = useLogAccessEvent();

    const addEmailtemplate = () => {
        let newTemplate: EmailMessageTemplate = {
            id: "",
            templateId: "",
            isParent: false,
            parentId: "",
            trackingId: trackingId,
            templateName: templateName,
            languageKey: 0,
            subject: subject,
            ccRecipients: ccRecipients,
            bccRecipients: bccRecipients,
            content: content,
            createdUtc: new Date().toISOString(),
            createdBy: claimservice.GetCurrentUserEmail(),
            modifiedUtc: new Date().toISOString(),
            modifiedBy: claimservice.GetCurrentUserEmail(),
            notificationType: notificationType,
        };

        createTemplate(newTemplate)
            .unwrap()
            .then(() => {
                props.onClose(true, isError);
                const evtData: AccessEventEmailTemplate = {
                    templateName: templateName,
                };
                logEvent("EmailTemplateCreated", evtData);
            });
    };

    const onOk = () => {
        addEmailtemplate();
    };

    const checkRequirments = () => {
        if (trackingId === "" || templateName === "" || subject === "" || isLoading) {
            return false;
        }
        return true;
    };

    const handleValueChange = (key: string, value: string) => {
        switch (key) {
            case "trackingId":
                setTrackingId(value);
                break;
            case "templateName":
                setTemplateName(value);
                break;
            case "subject":
                setSubject(value);
                break;
            case "ccRecipients":
                setCcRecipients(value);
                break;
            case "bccRecipients":
                setBccRecipients(value);
                break;
            case "content":
                setContent(value);
                break;
            case "notificationType":
                setNotificationType(parseInt(value));
                break;
            default:
                break;
        }
    };

    return (
        <CclDrawer open={props.open} onClose={() => props.onClose(false)} title={"Create Template"}>
            <>
                <Box>
                    <AddEmailTemplateTabOne
                        setValue={handleValueChange}
                        trackingId={trackingId}
                        templateName={templateName}
                        subject={subject}
                        ccRecipients={ccRecipients}
                        bccRecipients={bccRecipients}
                        notificationType={notificationType}
                    />
                    <Box sx={{ width: "100%", display: "flex", justifyItems: "flex-end" }}>
                        <CclButton
                            onClick={() => props.onClose(false)}
                            sx={{ ml: "auto", mr: "10px" }}
                            restricted={false}
                            mode={"secondary"}
                        >
                            Cancel
                        </CclButton>
                        <CclLoadingButton
                            disabled={!checkRequirments()}
                            onClick={() => {
                                onOk();
                            }}
                            restricted={false}
                            loading={isLoading}
                            mode={"primary"}
                        >
                            Save
                        </CclLoadingButton>
                    </Box>
                </Box>
            </>
        </CclDrawer>
    );
};

export default AddEmailtemplate;
