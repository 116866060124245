import * as React from "react";
import {
    Alert,
    AlertColor,
    AlertTitle,
    Box,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
} from "@mui/material";
import ComponentLoader from "../../../../../components/common/componentLoader";
import { DetailsTabPanel } from "../../../../../components/common/detailTabPanel/detailTabPanel";
import ReadOnlyGate from "../../../../../components/userAccess/ReadOnlyGate";
import { PersonInfo } from "../../../../../models/personInfo";
import {
    useGetAssignedCoachesBySessionKeyQuery,
    useGetAvailableCoachesBySessionKeyQuery,
    useStoreCclParticipantCoachAssignmentsMutation,
} from "../../../../../services/cclTokenedEnterpriseCoachingApi";
import { useGetCclParticipantsByEventKeyQuery } from "../../../../../services/cclTokenedEnterpriseParticipantApi";
import { UserClaimsService } from "../../../../../services/currentUserService/currentUserService";
import { AccessEventSessionDetails } from "../../../../../services/types/accessEventTypes";
import {
    CoachAssignmentEndpointPayload,
    SessionDetailAssignedCoach,
} from "../../../../../services/types/sessionDetailTypes";
import { getErrorMsg } from "../../../../../utilities/rtkQueryErrorHelpers";
import OtherResourcesAssignmentSubPanel from "./otherResourcesAssignmentSubPanel";
import PrimaryCoachAssignmentSubPanel from "./primaryCoachAssignmentSubPanel";
import ViewAssignmentsPanel from "./viewAssignmentsPanel";
import { useGetCclEventByEventKeyQuery } from "../../../../../services/cclTokenedSessionApi";
import useLogAccessEvent from "../../../../../hooks/useLogAccessEvent";
import CclLoadingButton from "../../../../../components/common/cclButtons/cclLoadingButton";
import CclAlertSnackbar from "../../../../../components/common/cclAlertSnackbar";

interface CclSessionResourcesPanelProps {
    sessionId: string;
    setSaveNeeded: (needSave: boolean) => void;
    readOnly?: boolean;
}

const CclSessionResourcesPanel: React.FC<CclSessionResourcesPanelProps> = (props) => {
    const [showSnackbar, setShowSnackbar] = React.useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = React.useState<AlertColor | undefined>("info");
    const [saved, setSaved] = React.useState<boolean>(true);
    const [showLoader, setShowLoader] = React.useState<boolean>(false);
    const [activeTab, setActiveTab] = React.useState<number>(0);
    const [workingAssignedCoaches, setWorkingAssignedCoaches] = React.useState<
        SessionDetailAssignedCoach[]
    >([]);
    const [storedAssignedCoaches, setStoredAssignedCoaches] = React.useState<
        SessionDetailAssignedCoach[]
    >([]);
    const [assignCoaches] = useStoreCclParticipantCoachAssignmentsMutation();
    const { logEvent } = useLogAccessEvent();
    const { data, isLoading, isSuccess, isError, error } = useGetAssignedCoachesBySessionKeyQuery(
        props.sessionId
    );
    const { data: availableCoaches, isSuccess: availableCoachesSuccess } =
        useGetAvailableCoachesBySessionKeyQuery(props.sessionId);
    const { data: allParticipants, isSuccess: participantsSuccess } =
        useGetCclParticipantsByEventKeyQuery(props.sessionId);
    const claimsService = new UserClaimsService();
    const [sessionCode, setSessionCode] = React.useState<string>("");
    const { data: sessionDetail } = useGetCclEventByEventKeyQuery(props.sessionId, {
        skip: !props.sessionId,
    });

    React.useEffect(() => {
        if (sessionDetail == null) return;
        setSessionCode(sessionDetail.sessionCode);
    }, [sessionDetail]);

    React.useEffect(() => {
        if (data == null) return;
        setStoredAssignedCoaches(data.coaches);
        setWorkingAssignedCoaches(data.coaches);
        if (availableCoachesSuccess) setShowLoader(false);
        // eslint-disable-next-line
    }, [data]);

    React.useEffect(() => {
        if (isSuccess && availableCoachesSuccess) setShowLoader(false);
        // eslint-disable-next-line
    }, [availableCoachesSuccess]);

    const ShowSnackbarElement = (message: string, severity: AlertColor) => {
        setShowSnackbar(true);
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
    };

    // Handlers
    const handleCoachAssignment = (
        paxImKey: number,
        coachImKey: number,
        isPrimary: boolean,
        assigned: boolean
    ) => {
        // see if we need to add or remove first, ignore primary because cant assign as both primary and other
        const currentAssignment = workingAssignedCoaches.find(
            (ac) => ac.imKey === coachImKey && ac.paxImKey === paxImKey
        );
        const alreadyAssigned = currentAssignment != null;

        // handle cases where nothing needs to happen
        if (assigned === alreadyAssigned) return;

        // if remove we just pull the assignment from the existing assignment
        if (!assigned) {
            const newassignedcoaches = workingAssignedCoaches.filter(
                (ac) => !(ac.imKey === coachImKey && ac.paxImKey === paxImKey)
            );
            setWorkingAssignedCoaches(newassignedcoaches);
            return;
        }

        // add the new assignment
        const newcoach = availableCoaches?.coaches?.find((c) => c.imKey === coachImKey) ?? null;

        // safety - shouldn't be possible
        if (newcoach == null) return;

        // remove any assignments to other coaches from the assignment list
        let newassignedlist = isPrimary
            ? workingAssignedCoaches.filter((ac) => !(ac.paxImKey === paxImKey && ac.isPrimary))
            : [...workingAssignedCoaches];

        // don't care about the eskey here
        newassignedlist.push({
            imKey: coachImKey,
            pKey: newcoach.pKey,
            email: newcoach.email,
            firstName: newcoach.firstName,
            lastName: newcoach.lastName,
            isPrimary: isPrimary,
            isProposed: false,
            paxImKey: paxImKey,
            esKey: +props.sessionId,
        });
        setWorkingAssignedCoaches(newassignedlist);
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: number | null) => {
        if (newValue != null) setActiveTab(newValue);
    };

    React.useEffect(() => {
        const anyChanges = (): boolean => {
            const removedCoaches = storedAssignedCoaches.filter(
                (ac) =>
                    workingAssignedCoaches.find(
                        (ec) =>
                            ec.imKey === ac.imKey &&
                            ec.paxImKey === ac.paxImKey &&
                            ec.isPrimary === ac.isPrimary
                    ) == null
            );
            if (removedCoaches.length > 0) return true;

            const addedCoaches = workingAssignedCoaches.filter(
                (ac) =>
                    storedAssignedCoaches.find(
                        (ec) =>
                            ec.imKey === ac.imKey &&
                            ec.paxImKey === ac.paxImKey &&
                            ec.isPrimary === ac.isPrimary
                    ) == null
            );
            return addedCoaches.length > 0;
        };

        const changes = anyChanges();
        setSaved(!changes);
        props.setSaveNeeded(changes);
        // eslint-disable-next-line
    }, [workingAssignedCoaches]);

    const handleSave = () => {
        const removedCoaches = storedAssignedCoaches.filter(
            (ac) =>
                workingAssignedCoaches.find(
                    (ec) =>
                        ec.imKey === ac.imKey &&
                        ec.paxImKey === ac.paxImKey &&
                        ec.isPrimary === ac.isPrimary
                ) == null
        );
        const addedCoaches = workingAssignedCoaches.filter(
            (ac) =>
                storedAssignedCoaches.find(
                    (ec) =>
                        ec.imKey === ac.imKey &&
                        ec.paxImKey === ac.paxImKey &&
                        ec.isPrimary === ac.isPrimary
                ) == null
        );

        let endpointPayload: CoachAssignmentEndpointPayload = {
            eskey: +props.sessionId,
            payload: { coachAssignments: [] },
        };

        if (removedCoaches != null && removedCoaches.length > 0)
            removedCoaches.map((c) =>
                endpointPayload.payload.coachAssignments.push({
                    isRemove: true,
                    coachAssignment: {
                        coachImKey: c.imKey,
                        isPrimary: c.isPrimary,
                        isProposed: false,
                        paxImKey: c.paxImKey,
                    },
                })
            );

        if (addedCoaches != null && addedCoaches.length > 0)
            addedCoaches.map((c) =>
                endpointPayload.payload.coachAssignments.push({
                    isRemove: false,
                    coachAssignment: {
                        coachImKey: c.imKey,
                        isPrimary: c.isPrimary,
                        isProposed: false,
                        paxImKey: c.paxImKey,
                    },
                })
            );

        if (endpointPayload.payload.coachAssignments.length === 0) return;

        setShowLoader(true);
        assignCoaches(endpointPayload)
            .unwrap()
            .then(() => {
                setShowLoader(false);
                const evtData: AccessEventSessionDetails = {
                    projectCode: sessionCode,
                };

                logEvent("ResourceAssignmentsSaved", evtData);
                const msg =
                    activeTab === 0 ? "Coach Assignments Updated" : "Resource Assignments Updated";
                ShowSnackbarElement(msg, "success");
            })
            .catch((error) => {
                console.log(error);
                setShowLoader(false);
                const msg =
                    activeTab === 0
                        ? "Coach Assignment Update Failed"
                        : "Resource Assignment Update Failed";
                ShowSnackbarElement(msg, "error");
            });
    };

    const removeCoachsParticipants = (coach: PersonInfo, isPrimary: boolean) => {
        const newassignments = workingAssignedCoaches.filter(
            (c) => !(c.isPrimary === isPrimary && c.imKey === coach.imKey)
        );
        setWorkingAssignedCoaches(newassignments);
    };

    const assignAllToCoach = (coach: PersonInfo) => {
        if (participantsSuccess && allParticipants != null && allParticipants.length > 0) {
            // first remove all existing assignments/removals
            // NOTE this is only done for non-primary coaches, operation not allowed for primary coaches
            let newassignments = workingAssignedCoaches.filter(
                (c) => c.isPrimary || c.imKey !== coach.imKey
            );
            const coachinfo = availableCoaches?.coaches?.find((c) => c.imKey === coach.imKey);
            if (coachinfo != null) {
                allParticipants
                    .filter((p) => p.registrationStatus === "Confirmed")
                    .filter(
                        (p) =>
                            workingAssignedCoaches.find(
                                (c) =>
                                    c.isPrimary && c.imKey === coach.imKey && p.imKey === c.paxImKey
                            ) == null
                    )
                    .map((p) =>
                        newassignments.push({
                            imKey: coachinfo.imKey,
                            pKey: coachinfo.pKey,
                            email: coachinfo.email,
                            firstName: coachinfo.firstName,
                            lastName: coachinfo.lastName,
                            isPrimary: false,
                            isProposed: false,
                            paxImKey: p.imKey,
                            esKey: +props.sessionId,
                        })
                    );
                setWorkingAssignedCoaches(newassignments);
            }
        }
    };

    // Tablist
    const tablist: [string, React.ReactNode][] = [
        [
            "Primary Coaches",
            <PrimaryCoachAssignmentSubPanel
                sessionId={props.sessionId}
                assignedCoaches={workingAssignedCoaches.filter((c) => c.isPrimary) ?? []}
                updateCoachAssignment={handleCoachAssignment}
                removeCoachesAssignments={removeCoachsParticipants}
            />,
        ],
        [
            "Other Resources",
            <OtherResourcesAssignmentSubPanel
                sessionId={props.sessionId}
                assignedCoaches={workingAssignedCoaches.filter((c) => !c.isPrimary) ?? []}
                updateCoachAssignment={handleCoachAssignment}
                removeCoachesAssignments={removeCoachsParticipants}
                assignAllToCoach={assignAllToCoach}
            />,
        ],
        [
            "View All Resources",
            <ViewAssignmentsPanel
                sessionId={props.sessionId}
                editedAssignedCoaches={workingAssignedCoaches}
            />,
        ],
    ];

    const readonlyTablist = tablist.filter(
        (x) => x[0] !== "Primary Coaches" && x[0] !== "Other Resources"
    );
    const useTablist = claimsService.IsReadOnly() || props.readOnly ? readonlyTablist : tablist;

    function showErrorAlert(msg: string | undefined) {
        return (
            <Alert severity="error" sx={{ fontSize: 14 }}>
                <AlertTitle sx={{ fontSize: 14 }}>Error</AlertTitle>
                {msg}
            </Alert>
        );
    }

    if (isLoading) {
        return <ComponentLoader msg="Loading Resources" />;
    }

    if (isSuccess) {
        return (
            <Stack height={1} width={1}>
                {showSnackbar ? (
                    <CclAlertSnackbar
                        open={true}
                        onClose={() => setShowSnackbar(false)}
                        message={snackbarMessage}
                        severity={snackbarSeverity}
                    />
                ) : null}
                <Box
                    sx={{
                        width: 1,
                        m: 2,
                        height: "100%",
                        borderWidth: 0,
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Box display={"flex"} justifyContent={"center"} sx={{ borderWidth: 0 }}>
                        <ToggleButtonGroup
                            color="primary"
                            value={activeTab}
                            exclusive
                            onChange={handleTabChange}
                            sx={{ mb: 2 }}
                        >
                            {useTablist.map((tab, i) => (
                                <ToggleButton
                                    key={i}
                                    id={`session-detail-resource-panel-tab-${i}`}
                                    value={i}
                                >
                                    {tab[0]}
                                </ToggleButton>
                            ))}
                        </ToggleButtonGroup>
                    </Box>
                    {useTablist.map((tab, i) => (
                        <DetailsTabPanel
                            detailType={"session-detail-resources"}
                            key={tab[0]}
                            value={activeTab}
                            index={i}
                        >
                            {tab[1]}
                        </DetailsTabPanel>
                    ))}
                    <ReadOnlyGate
                        readOnly={props.readOnly || (useTablist.length > 1 && activeTab === 2)}
                        reactElement={
                            <Box sx={{ height: "60px", m: 2, p: 2 }}>
                                <CclLoadingButton
                                    sx={{ mr: 5 }}
                                    onClick={handleSave}
                                    disabled={saved}
                                    restricted={false}
                                    mode={"primary"}
                                    loading={showLoader}
                                >
                                    Save
                                </CclLoadingButton>
                            </Box>
                        }
                    />
                </Box>
            </Stack>
        );
    }

    if (isError) {
        return showErrorAlert(
            `Error retrieving participant assignment data for session ${
                props.sessionId
            } (${getErrorMsg(error)})`
        );
    }

    return showErrorAlert(
        `Unknown error retrieving participant assignment data for session ${props.sessionId}`
    );
};

export default CclSessionResourcesPanel;
