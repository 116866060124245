import React from "react";
import { Alert, Box } from "@mui/material";
import { SessionCalendarEvent } from "../../services/types/sessionDetailTypes";
import CclDrawer from "../../components/common/cclDrawer/cclDrawer";
import { useGetAppUrlsQuery } from "../../services/cclTokenedSettingsApi";
import { useNavigate } from "react-router-dom";
import CclDrawerActionBar from "../../components/common/cclDrawer/cclDrawerActionBar";
import CclButton from "../../components/common/cclButtons/cclButton";
import CclInfoTable from "../../components/common/cclInfoTable/cclInfoTable";
import { CclInfoTblCalendarSessionDetailTemplate } from "./cclInfoTbCalendarSessionDetailTemplate";

export type CalendarSessionDetailDrawerProps = {
    open: boolean;
    evt: SessionCalendarEvent | null;
    onClose: () => void;
};

const CalendarSessionDetailDrawer: React.FC<CalendarSessionDetailDrawerProps> = (props) => {
    const { data: urls } = useGetAppUrlsQuery();
    const navigate = useNavigate();
    const template = CclInfoTblCalendarSessionDetailTemplate;

    const handleClose = () => {
        props.onClose();
    };

    const handleScore = () => {
        window.open(`${urls["CCLScoring"]}/program-status/${props.evt?.programId}`, "_blank");
    };

    const handleViewSession = () => {
        navigate(`/session/${props.evt?.programId}`);
    };

    return (
        <CclDrawer open={props.open} onClose={handleClose} title={"Session Detail"}>
            <Box>
                {props.evt == null ? (
                    <Alert severity="error">Error retrieving session data</Alert>
                ) : (
                    <CclInfoTable template={template} data={props.evt} disableSave={true} />
                )}
            </Box>
            <CclDrawerActionBar>
                <CclButton
                    onClick={handleClose}
                    sx={{ float: "right" }}
                    restricted={false}
                    mode={"secondary"}
                >
                    Close
                </CclButton>
                <CclButton
                    onClick={handleViewSession}
                    sx={{ ml: 2 }}
                    restricted={false}
                    mode={"primary"}
                >
                    View Session
                </CclButton>
                <CclButton onClick={handleScore} sx={{ mr: 2 }} restricted={false} mode={"primary"}>
                    Score Assessments
                </CclButton>
            </CclDrawerActionBar>
        </CclDrawer>
    );
};

export default CalendarSessionDetailDrawer;
