import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import * as React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface cclGenericAccordianProps {
    headingText: string;
    children: React.ReactNode;
    sx?: object;
    defaultExpanded?: boolean;
    expandIconText?: [string, string];
    expandIconOverride?: React.ReactNode;
    changeCallback?: () => (event: React.SyntheticEvent, isExpanded: boolean) => void;
    summaryRightSideElement?: JSX.Element;
}

const CclGenericAccordian: React.FC<cclGenericAccordianProps> = (props) => {
    const [expandIconText, setExpandIconText] = React.useState<string>(
        props.expandIconText ? props.expandIconText[0] : ""
    );

    const handleChange = () => (event: React.SyntheticEvent, isExpanded: boolean) => {
        if (!props.expandIconText) return;
        setExpandIconText(isExpanded ? props.expandIconText[0] : props.expandIconText[1]);
    };

    const expandText = () => {
        if (!props.expandIconText) return <></>;
        let sx = {};
        if (expandIconText === props.expandIconText[0]) {
            sx = {
                color: "primary.main",
                paddingRight: "7px",
            };
        } else {
            sx = {
                color: "primary.main",
                paddingRight: "10px",
            };
        }
        return <Typography sx={sx}>{expandIconText}</Typography>;
    };

    const expandIcon = () => {
        if (props.expandIconText) {
            return <></>;
        } else if (props.expandIconOverride) {
            return props.expandIconOverride;
        } else {
            return <ExpandMoreIcon />;
        }
    };

    const returnSX = () => {
        if (props.sx) {
            return props.sx;
        } else {
            return {};
        }
    };

    return (
        <Accordion
            sx={returnSX()}
            onChange={props.changeCallback ? props.changeCallback() : handleChange()}
            defaultExpanded={props.defaultExpanded}
        >
            <AccordionSummary expandIcon={expandIcon()}>
                <Typography sx={{ marginLeft: "15px", fontWeight: "bolder", whiteSpace: "nowrap" }}>
                    {props.headingText}
                </Typography>
                {props.summaryRightSideElement ?? null}
                {props.expandIconText ? (
                    <Typography sx={{ marginLeft: "auto" }}>{expandText()}</Typography>
                ) : (
                    <></>
                )}
            </AccordionSummary>
            <AccordionDetails>{props.children}</AccordionDetails>
        </Accordion>
    );
};

CclGenericAccordian.defaultProps = {
    defaultExpanded: true,
};

export default CclGenericAccordian;
