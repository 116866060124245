import * as React from "react";
import { Alert, AlertTitle, Card, CardHeader } from "@mui/material";
import { getErrorMsg } from "../../../../utilities/rtkQueryErrorHelpers";
import ComponentLoader from "../../../common/componentLoader";
import { useSearchJournalEntriesMutation } from "../../../../services/cclTokenedJournalEntryApi";
import { JournalEntry } from "../../../../services/types/journalEntryTypes";
import HistoryTimeLine from "../../../common/historyTimeline/historyTimeline";
import CclButton from "../../../common/cclButtons/cclButton";
import {
    UserClaimsService,
    eGroupIds,
} from "../../../../services/currentUserService/currentUserService";

interface IndividualJournalEntryTimelineCardProps {
    emailAddress: string;
    imKey: string;
    onCreateJournalEntry: () => void;
}

const IndividualJournalEntryTimelineCard: React.FC<IndividualJournalEntryTimelineCardProps> = (
    props
) => {
    const [allJournalEntries, setAllJournalEntries] = React.useState<JournalEntry[]>([]);
    const [
        runSearchJournalEntries,
        {
            isLoading: journalEntriesLoading,
            isError: journalEntriesIsError,
            error: journalEntriesError,
        },
    ] = useSearchJournalEntriesMutation();
    const claimsService = new UserClaimsService();
    const allowJournalEntries = claimsService.IsUserInGroups([
        eGroupIds.eClientAdmin,
        eGroupIds.eNWAAdmin,
        eGroupIds.eCCLAdmin,
        eGroupIds.eCCLSuperAdmin,
    ]);

    React.useEffect(() => {
        let entries: JournalEntry[] = [];

        runSearchJournalEntries({
            journalCategoryIds: [3, 4, 5], // resource journal entry
            categoryDataTerm: props.imKey,
        })
            .unwrap()
            .then((journalentries: JournalEntry[]) => {
                entries = [...journalentries];
                setAllJournalEntries(entries);
            })
            .catch(() => {
                setAllJournalEntries(entries);
            });
    }, [props.emailAddress, props.imKey, runSearchJournalEntries]);

    function showErrorAlert(msg: string | undefined) {
        return (
            <div>
                <Alert severity="error" sx={{ fontSize: 14 }}>
                    <AlertTitle sx={{ fontSize: 14 }}>Error</AlertTitle>
                    {msg}
                </Alert>
            </div>
        );
    }

    if (journalEntriesIsError) {
        return showErrorAlert(getErrorMsg(journalEntriesError));
    }

    return (
        <React.Fragment>
            <Card sx={{ height: 1, width: { xs: 1, lg: 0.8, xl: 0.6 }, m: 2, p: 2 }}>
                {allowJournalEntries ? (
                    <CardHeader
                        action={
                            <CclButton
                                restricted={false}
                                mode={"primary"}
                                onClick={props.onCreateJournalEntry}
                            >
                                Create Journal Entry
                            </CclButton>
                        }
                    />
                ) : null}
                {journalEntriesLoading ? (
                    <ComponentLoader msg="Loading" />
                ) : (
                    <HistoryTimeLine events={[]} journalEntries={allJournalEntries} />
                )}
            </Card>
        </React.Fragment>
    );
};

export default IndividualJournalEntryTimelineCard;
