import * as React from "react";
import { Alert, Grid, Stack, TextField } from "@mui/material";
import { useSearchResourcesMutation } from "../../../../../services/cclTokenedResourceApi";
import { useAddCadreMemberMutation } from "../../../../../services/cclTokenedEnterpirseCoachingCadreApi";
import {
    UpdateWorkPoolMember,
    WorkPool,
} from "../../../../../services/types/resources/workPoolTypes";
import { AccessEventWorkPoolDetails } from "../../../../../services/types/accessEventTypes";
import useLogAccessEvent from "../../../../../hooks/useLogAccessEvent";
import CclUnrestrictedLoadingButton from "../../../../../components/common/cclButtons/cclUnrestrictedLoadingButton";
import CclDrawer from "../../../../../components/common/cclDrawer/cclDrawer";
import CclLoadingButton from "../../../../../components/common/cclButtons/cclLoadingButton";
import CclButton from "../../../../../components/common/cclButtons/cclButton";
import CclInfoTable, { CclInfoTableRowTemplate } from "../../../../../components/common/cclInfoTable/cclInfoTable";
import { flattenObject } from "../../../../../utilities/flattenObject";
import { ResourceDocument } from "../../../../../services/types/search.service.types";
import { CclInfoTableUserTemplate } from "./cclInfoTableUserTemplate";

export interface AddUserDialogProps {
    data: WorkPool | undefined;
    cKey: number;
    open: boolean;
    status: (success: boolean) => void;
    onClose: (trigger: boolean) => void;
}

export const AddUserDialog: React.FC<AddUserDialogProps> = (props) => {
    const [searchEmail, setSearchEmail] = React.useState<string>("");
    const [emailSearchError, setEmailSearchError] = React.useState<boolean>(false);
    const [searchError, setSearchError] = React.useState<boolean>(false);
    const [user, setUser] = React.useState<ResourceDocument>({} as ResourceDocument);

    const [template, setTemplate] = React.useState<CclInfoTableRowTemplate[]>([]);

    const [runSearch, { data, isLoading, isError }] = useSearchResourcesMutation();
    const [runAddMember, { isLoading: loading, isError: error }] = useAddCadreMemberMutation();

    const { logEvent } = useLogAccessEvent();

    const handleSearch = () => {
        setSearchError(false);
        setEmailSearchError(false);
        runSearch({ email: searchEmail });   
    }

    const handleEmailSearchKeyPress = async (evt: React.KeyboardEvent<HTMLDivElement>) => {
        if (evt.key === "Enter") {
            evt.preventDefault();
            if (emailSearchError || searchEmail === "") {
                setSearchEmail("");
                setSearchError(false);
                setEmailSearchError(false);
                runSearch({ email: "" });
            } else {
                setSearchError(false);
                setEmailSearchError(false);
                runSearch({ email: searchEmail });
            }
        }
    };

    const clearTemplate = () => {
        var newTemplate = CclInfoTableUserTemplate;
        setTemplate(newTemplate);
        var rowItem = newTemplate.find((item) => item.id === "name");
        if (rowItem && rowItem.valueField) {
            rowItem.valueField.value = "";
        }
        rowItem = newTemplate.find((item) => item.id === "reportinglocation");
        if (rowItem && rowItem.valueField) {
            rowItem.valueField.value = "";
        }
        rowItem = newTemplate.find((item) => item.id === "origincountry");
        if (rowItem && rowItem.valueField) {
            rowItem.valueField.value = "";
        }
    }

    const handleClose = (trigger: boolean) => {
        setSearchEmail("");
        setEmailSearchError(false);
        setSearchError(false);
        setUser({} as ResourceDocument);
        clearTemplate();
        props.onClose(trigger);
    };

    const addMember = (member: UpdateWorkPoolMember) => {
        runAddMember(member)
            .unwrap()
            .then(() => {
                props.status(error)
                const evtData: AccessEventWorkPoolDetails = {
                    workPoolId: `${props.data?.cKey}`,
                    workPoolName: props.data?.name ?? "",
                };
                logEvent("WorkPoolMemberAdded", evtData);
                handleClose(true);
            });
    };

    React.useEffect(() => {
        var newTemplate = CclInfoTableUserTemplate;
        setTemplate([...newTemplate]);
        if (data && data.length > 0) {
            var rowItem = newTemplate.find((item) => item.id === "name");
            if (rowItem && rowItem.valueField) {
                rowItem.valueField.value = `${data[0].firstname} ${data[0].lastname}`;
            }   
            setUser(data[0]);
        } else if (data && data.length === 0 && !searchError) {
            setSearchError(true);
        }
        //eslint-disable-next-line
    }, [data]);

    return (
        <CclDrawer 
            title={"Add Member"} 
            open={props.open} 
            onClose={()=> handleClose(false)}
        >
            <>
                <Grid container component="form" sx={{m: 5}}>
                    <Grid item xs={12} sm={6} md={8} sx={{ p: 1 }}>
                        <TextField
                            value={searchEmail}
                            sx={{ width: 1, maxHeight: "57px" }}
                            id="outlined-adornment-email-search"
                            label="Enter Email"
                            variant="outlined"
                            error={emailSearchError}
                            helperText={emailSearchError ? "Email can't be blank" : ""}
                            onChange={(e) => {
                                e.preventDefault();
                                setSearchEmail(e.target.value);
                                setEmailSearchError(e.target.value === "");
                            }}
                            onKeyDown={(e) => handleEmailSearchKeyPress(e)}
                        />
                    </Grid>
                    <Grid item xs={6} sm={3} md={2} sx={{ p: 1 }}>
                        <CclUnrestrictedLoadingButton
                            onClick={() => handleSearch()}
                            aria-label="Search by Email"
                            sx={{ width: 1, height: 1, maxHeight: "57px" }}
                            disabled={
                                emailSearchError || searchEmail === "" || isLoading || loading
                            }
                            loading={isLoading}
                        >
                            Search
                        </CclUnrestrictedLoadingButton>
                    </Grid>
                </Grid>
                <CclInfoTable 
                    template={template} 
                    data={flattenObject(user)}
                    readonly={true}
                />
                <Stack direction={"row"} sx={{width: "100%", mt: 2, mb: 2}}>
                    <CclButton 
                        restricted={false}
                        sx={{ marginLeft: "auto", marginRight: "10px" }}
                        onClick={()=>handleClose(false)}
                        mode={"secondary"}
                    >
                            Cancel
                    </CclButton>
                    <CclLoadingButton 
                        restricted={false} 
                        mode={"primary"}
                        onClick={()=> addMember({ cKey: props.cKey, imKey: user.imkey })}
                        loading={loading}

                    >
                            Save
                    </CclLoadingButton>
                </Stack>

                {searchError && <Alert severity="info">No user found with that email</Alert>}
                {isError && <Alert severity="error">Error searching for user</Alert>}
                {error && <Alert severity="error">Could not add user to workpool</Alert>}
            </>
        </CclDrawer>

    );
};

export default AddUserDialog;
