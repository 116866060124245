import * as React from "react";
import DialogContentText from "@mui/material/DialogContentText";
import { Stack, TextField } from "@mui/material";
import CclDrawer from "../../components/common/cclDrawer/cclDrawer";
import CclDrawerActionBar from "../../components/common/cclDrawer/cclDrawerActionBar";
import CclButton from "../../components/common/cclButtons/cclButton";
import CclLoadingButton from "../../components/common/cclButtons/cclLoadingButton";

export type CclEnterNewFacilitatorDrawerProps = {
    open: boolean;
    onOk: (email: string) => void;
    onCancel: () => void;
    saving: boolean;
};

const CclEnterNewFacilitatorDrawer: React.FC<CclEnterNewFacilitatorDrawerProps> = (props) => {
    const [newFacEmail, setNewFacEmail] = React.useState<string>("");
    const [inputError, setInputError] = React.useState<string>("");

    React.useEffect(() => {
        if (props.open) return;
        setNewFacEmail("");
    }, [props.open]);

    const validateEmail = (): boolean => {
        let facEmail: string = newFacEmail.trim();
        let regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i;
        return regex.test(facEmail.replace(/\s/g, ""));
    };

    const handleOk = () => {
        if (validateEmail()) {
            setInputError("");
            props.onOk(newFacEmail.trim());
        } else {
            setInputError("Not a valid email address");
        }
    };

    const handleKeyPress = async (evt: React.KeyboardEvent<HTMLDivElement>) => {
        if (evt.key === "Enter") {
            handleOk();
        }
    };

    return (
        <CclDrawer
            open={props.open}
            onClose={() => setNewFacEmail("")}
            title={"Change Facilitator"}
        >
            <Stack direction="row" alignItems={"center"}>
                <DialogContentText id="alert-dialog-description" sx={{ width: 1 }}>
                    <TextField
                        size="small"
                        name="facilitatorEmail"
                        placeholder="Enter New Facilitator's Email"
                        value={newFacEmail}
                        id="outlined-adornment-email-search"
                        label="Enter New Facilitator's Email"
                        variant="outlined"
                        fullWidth={true}
                        error={inputError !== ""}
                        helperText={inputError}
                        onChange={(e) => {
                            e.preventDefault();
                            setNewFacEmail(e.target.value);
                        }}
                        onKeyDown={(e) => handleKeyPress(e)}
                        sx={{ mt: "10px" }}
                    />
                </DialogContentText>
            </Stack>
            <CclDrawerActionBar>
                <CclButton
                    onClick={props.onCancel}
                    sx={{ float: "right" }}
                    restricted={false}
                    mode={"secondary"}
                >
                    Cancel
                </CclButton>
                <CclLoadingButton
                    onClick={handleOk}
                    disabled={newFacEmail.length <= 3}
                    loading={props.saving}
                    restricted={false}
                    mode={"primary"}
                >
                    Save
                </CclLoadingButton>
            </CclDrawerActionBar>
        </CclDrawer>
    );
};

export default CclEnterNewFacilitatorDrawer;
