import { LoadingButtonProps } from "@mui/lab";
import CclLoadingButton from "./cclLoadingButton";

export const CclUnrestrictedLoadingButton: React.FC<LoadingButtonProps> = (props) => {
    return (
        <CclLoadingButton
            restricted={false}
            mode="primary"
            {...props}
        >
            {props.children}
        </CclLoadingButton>
    );
};

export default CclUnrestrictedLoadingButton;
