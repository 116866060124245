import * as React from "react";
import { UserClaimsService } from "../../../../services/currentUserService/currentUserService";
import MyAttributesTabPanel from "../../../my-access/accessDetial/myAttributesTab";

interface AttributeProps {
    pKey: string;
    imKey: string;
    email: string;
}

const AttributePanel: React.FC<AttributeProps> = (props) => {
    const claimservice = new UserClaimsService();

    const readonly = !claimservice.IsUserCclAdminOrSuperAdmin();

    return (
        <MyAttributesTabPanel 
            readonly={readonly}
            fromResource={true}
            pkey={props.pKey}
            imKey={props.imKey}
            email={props.email}      
        />
    );
};

export default AttributePanel;
