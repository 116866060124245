import * as React from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from "@mui/material";
import CclRestrictedButton from "../../../../components/common/cclButtons/cclRestrictedButton";
import CclUnrestrictedOutlinedButton from "../../../../components/common/cclButtons/cclUnrestrictedOutlinedButton";

export interface UpdateLoginApplicationCallbackDialogProps {
    open: boolean;
    onClose: () => void;
    url: string;
    loginApplicationCallbackUrlId: string;
    onSave: (url: string, callbackUrlId: string) => void;
}

export const UpdateLoginApplicationCallbackDialog: React.FC<
    UpdateLoginApplicationCallbackDialogProps
> = (props) => {
    const inputRef = React.useRef<HTMLInputElement>(null);

    const onUpdateCallback = () => {
        if (inputRef.current === null || inputRef.current.value === "") return;

        props.onSave(inputRef.current.value, props.loginApplicationCallbackUrlId);
        props.onClose();
    };

    var verbage = props.loginApplicationCallbackUrlId === "" ? "Add" : "Update";
    return (
        <Dialog open={props.open} onClose={props.onClose} maxWidth={"sm"} fullWidth={true}>
            <DialogTitle>{verbage} Callback Url</DialogTitle>
            <DialogContent sx={{ marginTop: "10px" }}>
                <Stack spacing={3}>
                    <TextField
                        required
                        id="edit-callback-url"
                        label="{verbage} Callback Url"
                        variant="standard"
                        defaultValue={props.url}
                        inputRef={inputRef}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <CclUnrestrictedOutlinedButton onClick={props.onClose}>
                    Cancel
                </CclUnrestrictedOutlinedButton>
                <CclRestrictedButton onClick={() => onUpdateCallback()}>
                    {verbage} Callback
                </CclRestrictedButton>
            </DialogActions>
        </Dialog>
    );
};

export default UpdateLoginApplicationCallbackDialog;
