import * as React from "react";
import {
    Stack,
    FormControl,
    TextField,
    FormControlLabel,
    Checkbox,
    Box,
    Typography,
    Card,
    InputLabel,
    Select,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Link,
    MenuItem,
    SelectChangeEvent,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import CircleIcon from "@mui/icons-material/Circle";
import { ContractCardContent, ItContractData } from "./signContractStepper";
import ContractCardHeader from "./sharedComponents/cardHeader";
import {
    SelectOption,
    getOptionFromVal,
} from "../../../../../../components/common/selectOptions/selectOption";
import CclRestrictedButton from "../../../../../../components/common/cclButtons/cclRestrictedButton";
import CclUnrestrictedOutlinedButton from "../../../../../../components/common/cclButtons/cclUnrestrictedOutlinedButton";

const antiVirusOptions: SelectOption[] = [
    { val: "1", option: "Avast" },
    { val: "2", option: "Bit Defender" },
    { val: "3", option: "CrowdStrike" },
    { val: "4", option: "F-Secure" },
    { val: "5", option: "McAfee" },
    { val: "6", option: "Norton" },
    { val: "7", option: "Panda" },
    { val: "8", option: "Sophos" },
    { val: "9", option: "Symantec" },
    { val: "10", option: "Trend" },
    { val: "11", option: "Vipre" },
    { val: "12", option: "Webroot" },
    { val: "13", option: "Windows Defender" },
    { val: "99", option: "Other" },
];

const encryptionOptions: SelectOption[] = [
    { val: "1", option: "FileVault (Mac)" },
    { val: "2", option: "BitLocker (Windows)" },
    { val: "3", option: "TrendMicro" },
    { val: "4", option: "Symantic" },
    { val: "5", option: "McAfee" },
    { val: "99", option: "Other" },
];

export interface ItStepPanelProps {
    pkey: number;
    itData: ItContractData;
    onNextPanel: (nextStep: number, newdata: ItContractData) => void;
    onPreviousPanel: () => void;
    activeStep: number;
    nextStep: number;
}

export const ItStepPanel: React.FC<ItStepPanelProps> = (props) => {
    const [policyChecked, setPolicyChecked] = React.useState<boolean | undefined>(
        props.itData?.agreeToITPolicy
    );
    const [selectedEncryption, setSelectedEncryption] = React.useState<SelectOption | null>({
        val: "-1",
        option: "Nothing Selected",
    } as SelectOption);
    const [selectedAntiVirus, setSelectedAntiVirus] = React.useState<SelectOption | null>({
        val: "-1",
        option: "Nothing Selected",
    } as SelectOption);
    const [otherEncryption, setOtherEncryption] = React.useState<string | undefined>("");
    const [otherAntiVirus, setOtherAntiVirus] = React.useState<string | undefined>("");

    React.useEffect(() => {
        if (props.nextStep !== -1) handleNext(props.nextStep);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.nextStep]);

    React.useEffect(() => {
        if (props.itData === undefined) return;
        if (props.itData?.antiVirusId) {
            const selopt = antiVirusOptions.find((avo) => +avo.val === props.itData?.antiVirusId);
            if (selopt) setSelectedAntiVirus(selopt);
        }
        if (props.itData?.encryptionId) {
            const selopt = encryptionOptions.find((eo) => +eo.val === props.itData?.encryptionId);
            if (selopt) setSelectedEncryption(selopt);
        }
        if (props.itData?.otherAntiVirus) setOtherAntiVirus(props.itData.otherAntiVirus);
        if (props.itData?.otherEncryption) setOtherEncryption(props.itData.otherEncryption);
        setPolicyChecked(props.itData?.agreeToITPolicy);
    }, [props.itData]);

    const handleEncryptionChange = (event: SelectChangeEvent) => {
        let newopt: SelectOption = {
            val: event.target.value,
            option: getOptionFromVal(event.target.value, encryptionOptions) ?? "Unknown Option",
        };
        setSelectedEncryption(newopt);
    };

    const handleAntiVirusChange = (event: SelectChangeEvent) => {
        let newopt: SelectOption = {
            val: event.target.value,
            option: getOptionFromVal(event.target.value, antiVirusOptions) ?? "Unknown Option",
        };
        setSelectedAntiVirus(newopt);
    };

    const handleNext = (nxtStep?: number) => {
        let ns = nxtStep !== undefined ? nxtStep : props.activeStep + 1;
        let newdata: ItContractData = {
            encryptionId:
                selectedEncryption?.val !== undefined ? +selectedEncryption.val : undefined,
            otherEncryption: otherEncryption,
            antiVirusId: selectedAntiVirus?.val !== undefined ? +selectedAntiVirus.val : undefined,
            otherAntiVirus: otherAntiVirus,
            agreeToITPolicy: policyChecked,
        };

        props.onNextPanel(ns, newdata);
    };

    return (
        <Stack direction={"column"}>
            <Box
                component="form"
                sx={{
                    width: "100%",
                    borderStyle: "solid",
                    borderRadius: 3,
                    borderWidth: 1,
                    borderColor: "lightgray",
                    p: 4,
                    mt: 5,
                    mb: 3,
                }}
            >
                <Stack direction={"column"} width={1} spacing={3}>
                    <Typography variant="h5">
                        Please provide details regarding your encryption and anti-virus software and
                        indicate you agree to abide by IT policies.
                    </Typography>
                    <Typography variant="body2" sx={{ pb: 3, color: "text.secondary" }}>
                        Closing the screen will stop the Sign Contract process but you will be able
                        to restart it again.
                    </Typography>
                    <Card sx={{ width: 1 }}>
                        <ContractCardContent>
                            <ContractCardHeader
                                text={"Encryption"}
                                showRequired={true}
                                showInfoIcon={true}
                                infoText="Please indicate software that you use to encrypt your email server, hard drive and other electronic devices that you use to perform services for CCL."
                            />
                            <Typography variant="body2" sx={{ pb: 4 }}>
                                It is understood and agreed that any device accessing, storing, or
                                processing CCL data, or participant data will be encrypted prior to
                                CCL data being present on the device.
                            </Typography>
                            <Stack direction={"row"} width={1} spacing={3}>
                                <FormControl sx={{ width: 0.5 }}>
                                    <InputLabel id="select-encryption-label">
                                        Select Encryption Software
                                    </InputLabel>
                                    <Select
                                        labelId="select-encryption-label"
                                        label="Select Encryption Software"
                                        id="select-encryption"
                                        value={
                                            selectedEncryption?.val == null ||
                                            selectedEncryption.val === "-1"
                                                ? ""
                                                : selectedEncryption.val
                                        }
                                        onChange={handleEncryptionChange}
                                        size="small"
                                    >
                                        {encryptionOptions &&
                                            encryptionOptions.length > 0 &&
                                            encryptionOptions.map((opt, i) => {
                                                return (
                                                    <MenuItem
                                                        key={opt.val}
                                                        value={opt.val}
                                                        selected={
                                                            opt.val === selectedEncryption?.val
                                                        }
                                                    >
                                                        {opt.option}
                                                    </MenuItem>
                                                );
                                            })}
                                    </Select>
                                </FormControl>
                                <TextField
                                    label={"If 'Other', please specify"}
                                    id="other-encryption"
                                    value={otherEncryption}
                                    size="small"
                                    sx={{ width: 0.5, fontSize: "0.8125rem" }}
                                    disabled={selectedEncryption?.val !== "99"}
                                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                        setOtherEncryption(evt.target.value);
                                    }}
                                />
                            </Stack>
                        </ContractCardContent>
                    </Card>
                    <Card sx={{ width: 1 }}>
                        <ContractCardContent>
                            <ContractCardHeader
                                text={"Anti-Virus"}
                                showRequired={true}
                                showInfoIcon={true}
                                infoText="Please indicate software that you use for anti-virus protection on any electronic devices that you use to perform services for CCL."
                            />
                            <Typography variant="body2" sx={{ pb: 4 }}>
                                At all times, an industry recognized antivirus and anti-malware
                                application will be running scanning all files. The application will
                                be updated no less than weekly with current antivirus and
                                anti-malware signatures. Most applications run updates
                                automatically, you will not disable the auto updates.{" "}
                                <b>
                                    Note: Use of Kaspersky products is prohibited
                                    https://usa.kaspersky.com
                                </b>
                            </Typography>
                            <Stack direction={"row"} width={1} spacing={3}>
                                <FormControl fullWidth>
                                    <InputLabel id="select-antivirus-label">
                                        Select Anti-Virus Software
                                    </InputLabel>
                                    <Select
                                        labelId="select-antivirus-label"
                                        id="select-antivirus"
                                        value={
                                            selectedAntiVirus?.val == null ||
                                            selectedAntiVirus.val === "-1"
                                                ? ""
                                                : selectedAntiVirus.val
                                        }
                                        label="Select Anti-Virus Software"
                                        onChange={handleAntiVirusChange}
                                        size="small"
                                    >
                                        {antiVirusOptions &&
                                            antiVirusOptions.length > 0 &&
                                            antiVirusOptions.map((opt, i) => {
                                                return (
                                                    <MenuItem
                                                        key={opt.val}
                                                        value={opt.val}
                                                        selected={
                                                            opt.val === selectedAntiVirus?.val
                                                        }
                                                    >
                                                        {opt.option}
                                                    </MenuItem>
                                                );
                                            })}
                                    </Select>
                                </FormControl>
                                <TextField
                                    label={"If 'Other', please specify"}
                                    fullWidth
                                    id="other-antivirus"
                                    value={otherAntiVirus}
                                    size="small"
                                    sx={{ fontSize: "0.8125rem" }}
                                    disabled={selectedAntiVirus?.val !== "99"}
                                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                        setOtherAntiVirus(evt.target.value);
                                    }}
                                />
                            </Stack>
                        </ContractCardContent>
                    </Card>
                    <Card sx={{ width: 1 }}>
                        <ContractCardContent>
                            {" "}
                            <Typography
                                variant="h6"
                                component="div"
                                sx={{ textDecoration: "underline" }}
                            >
                                IT Policy
                            </Typography>
                            <List dense={true} sx={{ fontSize: "0.8125rem" }}>
                                <ListItem>
                                    <ListItemIcon>
                                        <CircleIcon
                                            sx={{
                                                fontSize: "small",
                                                color: "text.disabled",
                                            }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="I will use my CCL email or CCL sanctioned communication
                                        methods for all CCL related work and all client
                                        communications. For CCL sanctioned Communications please
                                        refer to the Contractor Handbook in section 5."
                                        sx={{ mt: 0, mb: 0 }}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <CircleIcon
                                            sx={{
                                                fontSize: "small",
                                                color: "text.disabled",
                                            }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="I understand that CCL has provided me with an Office 365
                                        License for the web version only and that any additional
                                        Office 365 Products are my personal responsibility."
                                        sx={{ mt: 0, mb: 0 }}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <CircleIcon
                                            sx={{
                                                fontSize: "small",
                                                color: "text.disabled",
                                            }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="I understand that CCL IT does not support issues with my
                                        personal computer, and I need to ensure that everything is
                                        working properly."
                                        sx={{ mt: 0, mb: 0 }}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <CircleIcon
                                            sx={{
                                                fontSize: "small",
                                                color: "text.disabled",
                                            }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="I understand that CCL requires using a multifactor
                                        authentication system to protect client data and I am
                                        required to comply with CCL policy when conducting CCL work."
                                        sx={{ mt: 0, mb: 0 }}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <CircleIcon
                                            sx={{
                                                fontSize: "small",
                                                color: "text.disabled",
                                            }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="I understand that it is my responsibility to keep my
                                        technology (Computer, Phone, applications, etc.) updated in
                                        order to efficiently perform CCL work."
                                        sx={{ mt: 0, mb: 0 }}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <CircleIcon
                                            sx={{
                                                fontSize: "small",
                                                color: "text.disabled",
                                            }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="I understand that Google Chrome is the recommended default
                                        web browser for best compatibility with all CCL
                                        applications."
                                        sx={{ mt: 0, mb: 0 }}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <CircleIcon
                                            sx={{
                                                fontSize: "small",
                                                color: "text.disabled",
                                            }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <React.Fragment>
                                                <Typography
                                                    variant="body2"
                                                    sx={{ display: "inline" }}
                                                >
                                                    I will adhere to all privacy regulations
                                                    regarding CCL data including GDPR (General Data
                                                    Protection Regulation), CCPA (California
                                                    Consumer Privacy Act), and all other applicable
                                                    regulations.
                                                </Typography>
                                                <Link
                                                    target="_blank"
                                                    href="https://gdpr.eu"
                                                    sx={{ pl: 3 }}
                                                >
                                                    https://gdpr.eu
                                                </Link>
                                                <Link
                                                    target="_blank"
                                                    href="https://oag.ca.gov/privacy/ccpa"
                                                    sx={{ pl: 3 }}
                                                >
                                                    https://oag.ca.gov/privacy/ccpa
                                                </Link>
                                            </React.Fragment>
                                        }
                                        sx={{ mt: 0, mb: 0 }}
                                    />
                                </ListItem>
                            </List>
                            <FormControlLabel
                                label={
                                    <Box
                                        sx={{
                                            pt: 0,
                                            pb: 0,
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography variant="body2" sx={{ display: "inline" }}>
                                            I agree
                                        </Typography>
                                        <Typography
                                            variant="h5"
                                            color={"error"}
                                            sx={{
                                                pl: 1,
                                                display: "inline",
                                                textDecoration: "bold",
                                            }}
                                        >
                                            *
                                        </Typography>
                                    </Box>
                                }
                                control={
                                    <Checkbox
                                        name="it-policy-agreement-checkbox"
                                        checked={policyChecked}
                                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                                            setPolicyChecked(evt.target.checked)
                                        }
                                        sx={{ pl: 3, fontSize: "0.8125rem" }}
                                    />
                                }
                            />
                        </ContractCardContent>
                    </Card>
                </Stack>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <CclUnrestrictedOutlinedButton
                    onClick={() => props.onPreviousPanel()}
                    startIcon={<NavigateBeforeIcon />}
                    sx={{ mr: 1 }}
                >
                    Back
                </CclUnrestrictedOutlinedButton>
                <Box sx={{ flex: "1 1 auto" }} />
                <CclRestrictedButton onClick={() => handleNext()} endIcon={<NavigateNextIcon />}>
                    Next
                </CclRestrictedButton>
            </Box>
        </Stack>
    );
};

export default ItStepPanel;
