import { Box, Dialog } from "@mui/material";
import * as React from "react";
import CclUnrestrictedLoadingButton from "./cclButtons/cclUnrestrictedLoadingButton";

interface CommonPopupProps {
    open: boolean;
    onClose: any;
    children: React.ReactNode;
    previewLoaded: boolean;
    buttonText?: string;
}

const CommonPopup: React.FC<CommonPopupProps> = (props) => {
    return (
        <Dialog
            onClose={props.onClose}
            open={props.open}
            sx={{
                textAlign: "center",
                overflowY: "hidden",
            }}
            maxWidth={props.previewLoaded ? "md" : false}
            fullWidth={props.previewLoaded}
        >
            <Box
                sx={{
                    height: 1,
                    borderWidth: 0,
                    display: "flex",
                    flexDirection: "column",
                    maxWidth: "100%",
                }}
            >
                <Box
                    sx={{
                        m: "auto",
                        maxWidth: "100%",
                    }}
                >
                    {props.children}
                </Box>
                {props.previewLoaded && (
                    <CclUnrestrictedLoadingButton
                        onClick={props.onClose}
                        loadingPosition="end"
                        style={{
                            marginLeft: "auto",
                            width: "10%",
                            marginTop: "20px",
                            marginBottom: "10px",
                            marginRight: "10px",
                        }}
                    >
                        {props.buttonText ? props.buttonText : "Ok"}
                    </CclUnrestrictedLoadingButton>
                )}
            </Box>
        </Dialog>
    );
};

export default CommonPopup;
