import * as React from "react";
import { Card, Stack, Typography } from "@mui/material";
import { SimpleBreadcrumbs } from "../../components/navigation/simpleBreadcrumbs";
import StandardLayout from "../../layouts/standard";
import { UserClaimsService } from "../../services/currentUserService/currentUserService";
import { useParams } from "react-router-dom";
import ResourcesTabs from "./resourcesTabs";

export type ResourceProps = {
    target: string;
};

export const Resources: React.FC = (props) => {
    const claimsService = new UserClaimsService();
    const params = useParams<ResourceProps>();
    let tabValue = "1";

    if (claimsService.IsUserNwaAdmin()) {
        tabValue = "3";
    } else  if (params.target) {
        tabValue = params.target;
    }

    const breadcrumbs = (
        <SimpleBreadcrumbs>
            <Typography variant="h6" color="text.primary">
                Resources
            </Typography>
        </SimpleBreadcrumbs>
    );

    return (
        <StandardLayout breadcrumbs={breadcrumbs}>
            <Stack height={1} width={1}>
                <Card sx={{ height: 1, width: 1, display: "flex", flexDirection: "column" }}>
                    <ResourcesTabs
                        defaultTab={tabValue}
                        isNwaAdmin={claimsService.IsUserNwaAdmin()}
                        partnerId={claimsService.NwaAdminGroupId()}
                        partnerName={claimsService.NwaAdminGroup()}
                        isCCPUser={claimsService.IsCCPUser()}
                        isCCLAdmin={claimsService.IsUserCclAdminOrSuperAdmin()}
                    />
                </Card>
            </Stack>
        </StandardLayout>
    );
};
export default Resources;
