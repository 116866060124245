import { Alert, Box } from "@mui/material";
import * as React from "react";
import CclInfoTable from "../../../../../components/common/cclInfoTable/cclInfoTable";
import { useGetCclEventByEventKeyQuery } from "../../../../../services/cclTokenedSessionApi";
import { SessionDetailType } from "../../../../../services/types/sessionDetailTypes";
import { CclInfoTblSessionPopupTemplate } from "./cclInfoTblSessionPopupInfoTemplate";
import CclDrawerActionBar from "../../../../../components/common/cclDrawer/cclDrawerActionBar";
import CclDrawer from "../../../../../components/common/cclDrawer/cclDrawer";
import ComponentLoader from "../../../../../components/common/componentLoader";
import CclButton from "../../../../../components/common/cclButtons/cclButton";

interface SessionPopupPanelProps {
    sessionId: string;
    open: boolean;
    onClose: () => void;
}

const SessionPopupPanel: React.FC<SessionPopupPanelProps> = (props) => {
    const { data, isLoading, isSuccess } = useGetCclEventByEventKeyQuery(props.sessionId, {
        skip: !props.sessionId,
    });
    const [sessionDetail, setSessionDetail] = React.useState<SessionDetailType | undefined>(
        undefined
    );

    React.useEffect(() => {
        if (data === undefined) {
            setSessionDetail(undefined);
        } else {
            let sd: SessionDetailType = JSON.parse(JSON.stringify(data));
            sd.start = new Date(sd.start.toString().slice(0, -1));
            sd.end = new Date(sd.end.toString().slice(0, -1));
            setSessionDetail(sd);
        }
        // eslint-disable-next-line
    }, [data]);

    if (isSuccess || isLoading) {
        return (
            <CclDrawer title={"Session Details"} open={props.open} onClose={props.onClose}>
                {/* <Grid container>                
                    <Grid item>
                        <Card sx={{ m: 2, border: 1, borderColor: "#EEEEEE", borderRadius: 3 }}> */}
                <Box>
                    {isLoading ? (
                        <ComponentLoader msg="" />
                    ) : sessionDetail != null ? (
                        <CclInfoTable
                            template={CclInfoTblSessionPopupTemplate}
                            data={sessionDetail}
                        />
                    ) : (
                        <Alert severity="error">No data found for session</Alert>
                    )}
                </Box>
                {/* </Card>
                    </Grid>
                </Grid> */}
                <CclDrawerActionBar>
                    <CclButton
                        onClick={props.onClose}
                        sx={{ float: "right" }}
                        restricted={false}
                        mode={"secondary"}
                    >
                        Close
                    </CclButton>
                </CclDrawerActionBar>
            </CclDrawer>
        );
    }
    return <span>No Session Data Loaded</span>;
};

export default SessionPopupPanel;
