import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import {
    Card,
    CardContent,
    CardHeader,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    TextField,
} from "@mui/material";
import { DataGridPro, GridColDef, GridColumns, GridRenderCellParams } from "@mui/x-data-grid-pro";
import FlexGrid from "../../../../layouts/flexGrid";
import {
    useCustomPropertiesQuery,
    useSaveCustomPropertyMutation,
} from "../../../../services/cclTokenedGenerateDocumentsApi";
import { CustomPropertyModel } from "../../../../services/types/generateDocumentsApiTypes";
import { addCustomDataGridFilters } from "../../../../components/common/customDataGridFilters/customDataGridFilters";
import CclUnrestrictedOutlinedButton from "../../../../components/common/cclButtons/cclUnrestrictedOutlinedButton";
import CclUnrestrictedButton from "../../../../components/common/cclButtons/cclUnrestrictedButton";
import CclUnrestrictedLoadingButton from "../../../../components/common/cclButtons/cclUnrestrictedLoadingButton";
import { AccessEventSessionDetails } from "../../../../services/types/accessEventTypes";
import useLogAccessEvent from "../../../../hooks/useLogAccessEvent";

interface CustomPropertiesPanelProps {
    esKey: number;
    programCode: string;
}

const CustomPropertiesPanel: React.FC<CustomPropertiesPanelProps> = (props) => {
    const { data, isLoading } = useCustomPropertiesQuery(props.esKey);
    const [saveCustomPropertyMutate, { isLoading: saveCustomPropertyMutateIsLoading }] =
        useSaveCustomPropertyMutation();

    const [selectFieldPropertyType, setSelectFieldPropertyType] = React.useState<string>("");
    const [selectedPropertyName, setSelectedPropertyName] = React.useState<string>("");
    const [textFieldPropertyName, setTextFieldPropertyName] = React.useState<string>("");
    const [textFieldChoices, setTextFieldChoices] = React.useState<string>("");
    const { logEvent } = useLogAccessEvent();

    const handlePropertyTypeChange = (event: SelectChangeEvent) => {
        setSelectFieldPropertyType(event.target.value);
    };

    const deleteCustomProperty = () => {
        saveCustomProperty(true);
        cancelEdits();
    };

    const saveCustomProperty = (remove: boolean) => {
        let model: CustomPropertyModel = {
            programId: props.esKey,
            propertyName: textFieldPropertyName,
            originalPropertyName: selectedPropertyName,
            propertyTarget: "0",
            propertyType: selectFieldPropertyType,
            propertyValues: textFieldChoices,
            remove: remove,
        };

        saveCustomPropertyMutate(model).unwrap().then(() => {
            const evtData:AccessEventSessionDetails = {
                projectCode: props.programCode,
            };
            logEvent("DocumentCustomPropertyChanged", evtData);
        });

        if (selectedPropertyName.length === 0) {
            cancelEdits();
        }
    };

    const setEdit = (propertyName: string) => {
        setSelectedPropertyName(propertyName);
        let item = data?.filter(
            (x: { propertyName: string }) => x.propertyName === propertyName
        )[0];
        if (item) {
            setTextFieldPropertyName(item?.propertyName);
            setSelectFieldPropertyType(item?.propertyValueType);
            setTextFieldChoices(item.validValues);
        }
    };

    const cancelEdits = () => {
        setSelectedPropertyName("");
        setTextFieldPropertyName("");
        setSelectFieldPropertyType("");
        setTextFieldChoices("");
    };

    const COLUMNS: GridColDef[] = [
        {
            field: "propertyName",
            headerName: "Name",
            width: 250,
        },
        {
            field: "propertyValueType",
            headerName: "Type",
            width: 150,
            type: "string",
        },
        {
            field: "validValues",
            headerName: "Valid Values",
            flex: 1,
            type: "string",
        },
        {
            field: "actions",
            type: "actions",
            headerName: "",
            width: 120,
            renderCell: (params: GridRenderCellParams) => (
                <>
                    <IconButton
                        onClick={() => {
                            setEdit(params.row["propertyName"]);
                        }}
                        aria-label="delete"
                    >
                        {" "}
                        <EditIcon />
                    </IconButton>
                </>
            ),
        },
    ];

    return (
        <Grid container width={1} spacing={3}>
            <Grid item xs={12} md={8} lg={8}>
                <Card sx={{ height: 500 }}>
                    <CardHeader title="Current Custom Properies"></CardHeader>
                    <CardContent sx={{ height: 400 }}>
                        <FlexGrid>
                            <DataGridPro
                                getRowId={(row) => row.propertyName}
                                rows={data ? data : []}
                                columns={addCustomDataGridFilters(COLUMNS as GridColumns<any>)}
                                loading={isLoading}
                            />
                        </FlexGrid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
                <Card sx={{ height: 500 }}>
                    <CardHeader title="Manage Custom Properties" />
                    <CardContent>
                        <Stack spacing={4}>
                            <TextField
                                id="property-name-tf"
                                label="Property Name"
                                onChange={(e) => setTextFieldPropertyName(e.target.value)}
                                value={textFieldPropertyName}
                                InputLabelProps={{ shrink: textFieldPropertyName.length > 0 }}
                            />
                            <FormControl sx={{ m: 1, minWidth: 120 }}>
                                <InputLabel id="property-type-select-helper-label">
                                    Property Type
                                </InputLabel>
                                <Select
                                    labelId="property-type-select-helper-label"
                                    id="property-type-select"
                                    value={selectFieldPropertyType}
                                    label="Property Type"
                                    onChange={handlePropertyTypeChange}
                                >
                                    <MenuItem value={"Text"}>Text</MenuItem>
                                    <MenuItem value={"Choice"}>Choice</MenuItem>
                                </Select>
                            </FormControl>
                            {selectFieldPropertyType === "Choice" ? (
                                <TextField
                                    id="outlined-multiline-static"
                                    label="Valid Values (1 Per Line)"
                                    value={textFieldChoices}
                                    multiline
                                    minRows={4}
                                    maxRows={8}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => setTextFieldChoices(e.target.value)}
                                />
                            ) : null}

                            <Stack direction={"row"} spacing={3} justifyContent="end">
                                {selectedPropertyName.length > 0 ? (
                                    <>
                                        <CclUnrestrictedOutlinedButton onClick={cancelEdits}>
                                            Cancel Edits
                                        </CclUnrestrictedOutlinedButton>{" "}
                                        <CclUnrestrictedButton onClick={deleteCustomProperty}>
                                            Delete Property
                                        </CclUnrestrictedButton>
                                    </>
                                ) : undefined}
                                <CclUnrestrictedLoadingButton
                                    loading={saveCustomPropertyMutateIsLoading}
                                    onClick={() => saveCustomProperty(false)}
                                    disabled={
                                        textFieldPropertyName.length === 0 ||
                                        selectFieldPropertyType.length === 0 ||
                                        (data
                                            ? data?.filter(
                                                  (x: { propertyName: string }) =>
                                                      x.propertyName === textFieldPropertyName
                                              ).length > 0 &&
                                              textFieldPropertyName !== selectedPropertyName
                                            : false)
                                    }
                                >
                                    {selectedPropertyName.length > 0
                                        ? "Save Changes"
                                        : "Save New Property"}
                                </CclUnrestrictedLoadingButton>
                            </Stack>
                        </Stack>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default CustomPropertiesPanel;
