import * as React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { Stack, useTheme } from "@mui/material";
import Topbar from "./standardDetails/topbar";
import Leftnav from "./standardDetails/leftnav";


export interface StandardLayoutProps {
    breadcrumbs?: React.ReactNode;
    children?: React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined;
}

export const StandardLayout: React.FC<StandardLayoutProps> = ({ breadcrumbs, children }) => {
    const [smallScreen, setSmallScreen] = React.useState<boolean>(
        window.innerWidth < 600 ? true : false
    );
    const theme = useTheme();

    React.useEffect(() => {
        function handleResize() {
            setSmallScreen(window.innerWidth < 600 ? true : false);
        }
        window.addEventListener("resize", handleResize);
    });

    const fetchDrawerCookie = () => {
        let cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
            let c = cookies[i];
            while (c.charAt(0) === " ") {
                c = c.substring(1);
            }
            if (c.indexOf("drawercookie") === 0) {
                let value = c.substring("drawercookie".length, c.length);
                if (value === "=false") {
                    return false;
                } else if (value === "=true") {
                    return true;
                }
            }
        }
        return true;
    };

    const [mobileOpen, setMobileOpen] = React.useState(fetchDrawerCookie());
    const [desktopOpen, setDesktopOpen] = React.useState<boolean>(fetchDrawerCookie());

    const setDrawerCookie = (value: boolean) => {
        document.cookie = `drawercookie=${value}`;
    };

    const handleMobileDrawerToggle = () => {
        setDrawerCookie(!mobileOpen);
        setMobileOpen(!mobileOpen);
    };

    const handleDesktopDrawerToggle = () => {
        setDrawerCookie(!desktopOpen);
        setDesktopOpen(!desktopOpen);
    };

    const returnSX = () => {
        if (desktopOpen) {
            return {
                flexGrow: 1,
                zIndex: 100,
                minWidth: "0px",
                backgroundColor: "#E1E1E1",
                transition: theme.transitions.create("margin", {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                }),
            };
        } else {
            return {
                flexGrow: 1,
                zIndex: 100,
                minWidth: "0px",
                marginLeft: `-145px`,
                backgroundColor: "#E1E1E1",
                transition: theme.transitions.create("margin", {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
            };
        }
    };

    return (
        <Stack height={"100vh"}>
            <Box sx={{ display: "flex", flexGrow: 1}}>
                <CssBaseline />
                <Topbar 
                    desktopOpen={desktopOpen} 
                    smallScreen={smallScreen} 
                    breadcrumbs={breadcrumbs} 
                    handleDesktopDrawerToggle={handleDesktopDrawerToggle} 
                    handleMobileDrawerToggle={handleMobileDrawerToggle}               
                />
                <Leftnav 
                    smallScreen={smallScreen} 
                    desktopOpen={desktopOpen} 
                    mobileOpen={mobileOpen} 
                    handleMobileDrawerToggle={handleMobileDrawerToggle}                
                />
                <Box component="main" padding={0} margin={0} sx={returnSX()}>
                    <Box sx={{height: "calc(100% - 56px)", mt: "58px", p: 1.5, pb: 3}}>{children}</Box>                  
                </Box>
            </Box>
        </Stack>
    );
};

export default StandardLayout;