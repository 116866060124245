import * as React from "react";
import { Card, CardHeader, CardContent, Stack, Alert } from "@mui/material";
import { GcSystem } from "../../../../services/types/cclGrandCentralApiTypes";
import SystemTenantDetail from "./systemTenantDetail";
import AddTenantDialog from "./addTenantDialog";
import CclRestrictedButton from "../../../../components/common/cclButtons/cclRestrictedButton";

export type SystemTenantsProps = {
    system: GcSystem;
    readonly: boolean;
};

export const SystemTenants: React.FC<SystemTenantsProps> = (props) => {
    const [showAddTenant, setShowAddTenant] = React.useState<boolean>(false);

    const addTenant = () => {
        setShowAddTenant(true);
    };

    return (
        <>
            <AddTenantDialog
                open={showAddTenant}
                onClose={() => setShowAddTenant(false)}
                systemId={props.system.systemId}
            />

            <Card variant="outlined" sx={{ width: 1, height: 1 }}>
                <CardHeader
                    title={"Tenants"}
                    action={
                        props.readonly ? null : (
                            <CclRestrictedButton onClick={() => addTenant()}>
                                Add Tenant
                            </CclRestrictedButton>
                        )
                    }
                />
                <CardContent>
                    <Stack spacing={1}>
                        {props.system.tenants && props.system.tenants.length > 0 ? (
                            props.system.tenants.map((tnt, idx) => (
                                <SystemTenantDetail
                                    systemId={props.system.systemId}
                                    systemName={props.system.systemName}
                                    key={tnt.tenantId}
                                    tenant={tnt}
                                    readonly={props.readonly}
                                    expanded={idx === 0}
                                />
                            ))
                        ) : (
                            <Alert severity="warning">No Tenants Found</Alert>
                        )}
                    </Stack>
                </CardContent>
            </Card>
        </>
    );
};

export default SystemTenants;
