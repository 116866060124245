import * as React from "react";
import CclInfoTable from "../../../../../components/common/cclInfoTable/cclInfoTable";
import { SessionDetailType } from "../../../../../services/types/sessionDetailTypes";
import { CclInfoTblSessionInfoTemplate } from "./cclInfoTblSessionInfoTemplate";
import CclGenericAccordian from "../../../../../components/common/cclGenericAccordian";
import { Box } from "@mui/material";
import CclButton from "../../../../../components/common/cclButtons/cclButton";
import useLogAccessEvent from "../../../../../hooks/useLogAccessEvent";
import { useGetAppUrlsQuery } from "../../../../../services/cclTokenedSettingsApi";
import { AccessEventSessionDetails } from "../../../../../services/types/accessEventTypes";

interface SessionDetailsAccordionProps {
    sessionDetail: SessionDetailType;
    readOnly?: boolean;
}

const SessionDetailsAccordion: React.FC<SessionDetailsAccordionProps> = (props) => {
    const { data: urls } = useGetAppUrlsQuery();
    const { logEvent } = useLogAccessEvent();

    const openCclScoring = (evt: React.MouseEvent<HTMLButtonElement>) => {
        evt.stopPropagation();
        const evtData: AccessEventSessionDetails = {
            projectCode: props.sessionDetail?.sessionKey.toString() ?? "unknown",
        };
        logEvent("SessionViewedInCCLScoring", evtData);
        window.open(
            `${urls["CCLScoring"]}/program-status/${props.sessionDetail?.sessionKey}`,
            "_blank"
        );
    };

    function getStatusElement(): JSX.Element {
        return (
            <Box width={1} sx={{ marginRight: "10px" }}>
                {!props.readOnly && props.sessionDetail.status !== "Draft" ? (
                    <CclButton
                        onClick={openCclScoring}
                        sx={{ float: "right", marginRight: "5px" }}
                        restricted={false}
                        mode={"primary"}
                    >
                        Score Assessments
                    </CclButton>
                ) : null}
            </Box>
        );
    }

    return (
        <CclGenericAccordian
            headingText="Session"
            defaultExpanded={true}
            summaryRightSideElement={getStatusElement()}
        >
            {props.sessionDetail != null && (
                <CclInfoTable template={CclInfoTblSessionInfoTemplate} data={props.sessionDetail} />
            )}
        </CclGenericAccordian>
    );
};

export default SessionDetailsAccordion;
