import * as React from "react";
import { DataGridPro, GridColumns, GridRenderCellParams } from "@mui/x-data-grid-pro";
import CclDataGridToolbar from "../../components/common/cclDataGridToolbar/cclDataGridToolbar";
import { addCustomDataGridFilters } from "../../components/common/customDataGridFilters/customDataGridFilters";
import { ScoringSnapshotItem } from "../../services/types/scoringStatusTypes";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const COLUMNS = [
    {
        field: "scoreReportStatusTypeName",
        headerName: "Status",
        type: "string",
        renderCell: (params: GridRenderCellParams) => {
            if (params.row?.scoringStatus === 1) {
                return (
                    <React.Fragment>
                        <HourglassTopIcon color="warning" fontSize="small" sx={{ mr: 2 }} />
                        <span>{params?.value}</span>
                    </React.Fragment>
                );
            } else if (params.row?.scoringStatus === 2) {
                return (
                    <React.Fragment>
                        <CheckCircleIcon color="success" fontSize="small" sx={{ mr: 2 }} />
                        <span>{params?.value}</span>
                    </React.Fragment>
                );
            } else if (params.row?.scoringStatus === 3) {
                return (
                    <React.Fragment>
                        <ErrorIcon color="error" fontSize="small" sx={{ mr: 2 }} />
                        <span>{params?.value}</span>
                    </React.Fragment>
                );
            }
        },
        flex: 0.5,
    },
    {
        field: "programId",
        headerName: "ID",
        type: "string",
        flex: 0.2,
    },
    {
        field: "participantName",
        headerName: "Participant",
        type: "string",
        flex: 0.75,
    },
    {
        field: "requestDateTime",
        headerName: "Requested",
        valueGetter: (params: any) => new Date(params.row.requestDateTime),
        type: "date",
        renderCell: (params: GridRenderCellParams) => <span>{params?.value.toLocaleString()}</span>,
        flex: 0.4,
    },
    {
        field: "instrumentShortName",
        headerName: "Instrument",
        type: "string",
        flex: 0.3,
    },
    {
        field: "instrumentName",
        headerName: "Report Type",
        type: "string",
        flex: 0.75,
    },
    {
        field: "scoreReportExternalTrackingId",
        headerName: "Tracking ID",
        type: "string",
        flex: 0.2,
    },
    {
        field: "vendorName",
        headerName: "Vendor",
        type: "string",
        flex: 0.4,
    },
    {
        field: "languageName",
        headerName: "Language",
        type: "string",
        flex: 0.3,
    },
    {
        field: "scorer",
        headerName: "Scorer",
        type: "string",
        flex: 0.75,
    },
];

export interface ScoringSnapshotDataGridProProps {
    snapshotItems: ScoringSnapshotItem[];
    loading: boolean;
    refresh: () => void;
}

export const ScoringSnapshotDataGridPro: React.FC<ScoringSnapshotDataGridProProps> = (props) => {
    const refresh = (id: string) => {
        if (id === "refresh") props.refresh();
    };

    return (
        <DataGridPro
            getRowId={(row: ScoringSnapshotItem) => row.scoreReportExternalTrackingId ?? 0}
            rows={props.snapshotItems}
            columns={addCustomDataGridFilters(COLUMNS as GridColumns<any>)}
            loading={props.loading}
            components={{
                Toolbar: CclDataGridToolbar,
            }}
            componentsProps={{
                toolbar: {
                    showSaveState: true,
                    hideSettingsButtons: true,
                    savestate: null,
                    clearstate: null,
                    resultBar: false,
                    resultBarText: "",
                    btnClick: refresh,
                    miscButtons: [
                        {
                            id: "refresh",
                            caption: "Refresh",
                            label: "Refresh",
                            onClick: () => {},
                        },
                    ],
                },
            }}
            disableSelectionOnClick={true}
            initialState={{ sorting: { sortModel: [{ field: "requestDateTime", sort: "desc" }] } }}
            hideFooter={true}
        />
    );
};

export default ScoringSnapshotDataGridPro;
