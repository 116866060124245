import * as React from "react";
import { Stack, Box, Grid } from "@mui/material";
import { Application } from "../../../../services/types/cclGrandCentralApiTypes";
import ApplicationInvitationDataGrid from "./applicationInvitationDataGrid";
import { useSearchInvitationsforApplicationMutation } from "../../../../services/cclTokenedGrandCentralApi";
import { ApplicationSearchInvitationBody } from "../../../../services/types/applicationTypes";
import CclTextSearchBar from "../../../../components/common/cclLandingPageSearchBars/cclTextSearchBar";
import CclDateSearchBar from "../../../../components/common/cclLandingPageSearchBars/cclDateSearchBar";
import CclButton from "../../../../components/common/cclButtons/cclButton";
import ComponentLoader from "../../../../components/common/componentLoader";
import CclSearchDefault from "../../../../components/common/cclSearchDefault";

interface ApplicationInvitationPanelProps {
    application: Application | undefined;
}

const ApplicationInvitationPanel: React.FC<ApplicationInvitationPanelProps> = (props) => {
    const [runSearch, { data: searchData, isSuccess, isLoading }] =
        useSearchInvitationsforApplicationMutation();

    const emailOrImKey = (value: string) => {
        if (isNaN(+value)) {
            return false;
        } else {
            return true;
        }
    };

    const searchInvitations = (
        startDate: Date | null,
        endDate: Date | null,
        searchEmail: string
    ) => {
        if (!props.application) {
            return;
        }

        const payload: ApplicationSearchInvitationBody = {
            applicationId: props.application.applicationId,
            payload: {
                imKey: searchEmail ? (emailOrImKey(searchEmail) ? +searchEmail : null) : null,
                emailAddress: searchEmail ? (emailOrImKey(searchEmail) ? "" : searchEmail) : "",
                startDate: startDate,
                endDate: endDate ? endDate : startDate ? new Date() : null,
            },
        };

        runSearch(payload);
    };

    return (
        <Stack width={1} height={1} spacing={2}>
            <Box width={1} sx={{ display: "flex", flexDirection: "row" }}>
                <CclTextSearchBar
                    initialSearchTerm={""}
                    searchLabel="Enter User Email"
                    executeSearch={(searchTerm: string) =>
                        searchInvitations(null, null, searchTerm)
                    }
                />
                <CclDateSearchBar
                    initialStartDate={null}
                    initialEndDate={null}
                    startDateLabel="From Date"
                    endDateLabel="To Date"
                    executeSearch={(startDate: Date | null, endDate: Date | null) =>
                        searchInvitations(startDate, endDate, "")
                    }
                    allowNullDates={false}
                />
                <Grid
                    container
                    spacing={1}
                    padding={2}
                    sx={{ maxWidth: "fit-content", height: "fit-content" }}
                >
                    <Grid item>
                        <CclButton
                            onClick={() => {
                                searchInvitations(null, null, "");
                            }}
                            aria-label="Get All Invitations"
                            disabled={props.application === undefined}
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignContent: "center",
                                height: 1,
                                padding: "7px",
                            }}
                            restricted={false}
                            mode={"primary"}
                        >
                            Get All
                        </CclButton>
                    </Grid>
                </Grid>
            </Box>
            {isLoading ? (
                <ComponentLoader msg={"Loading"} />
            ) : isSuccess && searchData ? (
                <ApplicationInvitationDataGrid
                    invitations={searchData ? searchData : []}
                    isLoading={isLoading}
                    showResultBar={false}
                    resultBarText={""}
                />
            ) : (
                <CclSearchDefault
                    line1="Start searching for Invitations"
                    line2="Enter User Email or enter a Date Range and click Search or click Get All"
                    hasButtons={false}
                />
            )}
        </Stack>
    );
};

export default ApplicationInvitationPanel;
