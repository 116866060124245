import { ButtonProps } from "@mui/material";
import CclButton from "./cclButton";

export const CclRestrictedButton: React.FC<ButtonProps> = (props) => {
    return (
        <CclButton
            restricted={true}
            mode="primary"
            {...props}
        >
            {props.children}
        </CclButton>
    );
};

export default CclRestrictedButton;
