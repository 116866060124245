import { LoadingButtonProps } from "@mui/lab";
import CclLoadingButton from "./cclLoadingButton";


export const CclRestrictedLoadingButton: React.FC<LoadingButtonProps> = (props) => {
    return (
        <CclLoadingButton
            restricted={true}
            mode="primary"
            {...props}
        >
            {props.children}
        </CclLoadingButton>
    );
};

export default CclRestrictedLoadingButton;
