import * as React from "react";
import { LoadingButtonProps, LoadingButton } from "@mui/lab";

type CclLoadingButtonProps = LoadingButtonProps & {
    restricted: boolean;
    mode: "primary" | "secondary" | "tertiary";
};

export const CclLoadingButton: React.FC<CclLoadingButtonProps> = (props) => {
    const { restricted, mode, sx, children, ...passthroughprops } = props;

    const v =
        props.mode === "primary" ? "contained" : props.mode === "secondary" ? "outlined" : "text";
    const disabledStyle = {
        backgroundColor: "white",
        color: props.loading ? "white" : "#A6A6A6",
        border: mode === "tertiary" ? "0px" : "2px solid #A6A6A6",
        padding: "2px 10px",
    };

    return (
        <LoadingButton
            color={restricted ? "secondary" : "primary"}
            variant={v}
            sx={{
                "&.Mui-disabled": disabledStyle,
                borderWidth: "2px",
                padding: "4px 10px",
                boxShadow: "none",
                ...sx,
            }}
            {...passthroughprops}
        >
            {children}
        </LoadingButton>
    );
};

export default CclLoadingButton;
