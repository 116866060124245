import * as React from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from "@mui/material";
import { useAddCclApplicationMutation } from "../../../../services/cclTokenedGrandCentralApi";
import { generateGUID } from "../../../../utilities/generalUtilities";
import CclErrorDialog from "../../../../components/common/cclErrorDialog";
import CclRestrictedLoadingButton from "../../../../components/common/cclButtons/cclRestrictedLoadingButton";
import CclUnrestrictedOutlinedButton from "../../../../components/common/cclButtons/cclUnrestrictedOutlinedButton";

export interface AddApplicationDialogProps {
    open: boolean;
    onClose: () => void;
    systemId: string;
}

export const AddApplicationDialog: React.FC<AddApplicationDialogProps> = (props) => {
    const [showErrorDialog, setShowErrorDialog] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = React.useState<string>("");
    const [newAppName, setNewAppName] = React.useState<string>("");
    const [addApplication] = useAddCclApplicationMutation();

    const showError = (msg: string) => {
        setErrorMessage(msg);
        setShowErrorDialog(true);
    };

    const onAddApplication = () => {
        addApplication({
            name: newAppName,
            applicationId: generateGUID(),
            systemId: props.systemId,
        })
            .unwrap()
            .then((res) => {
                console.log("Add Application result: ", res ? "Success" : "Failed");
                if (!res) {
                    showError("Failed to add application");
                }
                props.onClose();
            })
            .catch((err) => {
                showError(`Failed to add application: ${err.data}`);
                props.onClose();
            });
    };

    return (
        <>
            <CclErrorDialog
                open={showErrorDialog}
                onOk={() => setShowErrorDialog(false)}
                msg={errorMessage}
                title="Error Adding Application"
            />
            <Dialog open={props.open} onClose={props.onClose} maxWidth={"sm"} fullWidth={true}>
                <DialogTitle>Add Application</DialogTitle>
                <DialogContent sx={{ marginTop: "10px" }}>
                    <Stack spacing={3}>
                        <TextField
                            required
                            id="new-application-name"
                            label="New Application Name"
                            variant="standard"
                            value={newAppName}
                            onChange={(e) => setNewAppName(e.target.value)}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <CclUnrestrictedOutlinedButton onClick={props.onClose}>
                        Cancel
                    </CclUnrestrictedOutlinedButton>
                    <CclRestrictedLoadingButton
                        onClick={() => onAddApplication()}
                        disabled={newAppName === ""}
                    >
                        Add Application
                    </CclRestrictedLoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AddApplicationDialog;
