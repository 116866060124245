import * as React from "react";
import { Autocomplete, Grid, Stack, TextField } from "@mui/material";
import { AllAttributeOptions } from "../../../services/types/myAccessTypes";
import { AttributeSearch } from "./advancedSearchForm";
import { SearchAttribute } from "../../../services/types/enterpriseCoachingApiTypes";
import CclButton from "../../../components/common/cclButtons/cclButton";

interface AdvancedSearchAttributeListItemProps {
    allAttributeOptions: AllAttributeOptions | undefined;
    attributeSearch: AttributeSearch;
    handleDelete: (id: string) => void;
    handleAttributeSelected: (attribute: SearchAttribute) => void;
}

export interface AutocompleteItem {
    id: number;
    label: string;
}

const AdvancedSearchAttributeListItem: React.FC<AdvancedSearchAttributeListItemProps> = (props) => {
    const attributes = props.allAttributeOptions
        ? props.allAttributeOptions.profileItemTypes
              .map((x) => {
                  return { id: x.key, label: x.name };
              })
              .sort((a, b) => a.label.localeCompare(b.label))
        : [];
    const [parentAttribute, setParentAttribute] = React.useState<number>(0);
    const [selectedAttributeOptions, setSelectedAttributeOptions] = React.useState<
        AutocompleteItem[]
    >([]);
    const [selectedLevelOptions, setSelectedLevelOptions] = React.useState<AutocompleteItem[]>([]);
    const [selectedAttribute, setSelectedAttribute] = React.useState<AutocompleteItem | undefined>(
        undefined
    );
    const [selectedLevel, setSelectedLevel] = React.useState<AutocompleteItem | undefined>(
        undefined
    );
    const [hasChanged, setHasChanged] = React.useState<boolean>(false);

    const onSelectChange = (newValue: { label: string; id: number } | null) => {
        if (newValue && newValue.id > 0) {
            setParentAttribute(newValue.id);
            setHasChanged(true);
            if (newValue.id === 2) {
                let attributeOptions = props.allAttributeOptions?.languages
                    .map((x) => {
                        return { id: x.key, label: x.name } as AutocompleteItem;
                    })
                    .sort((a, b) => a.label.localeCompare(b.label));
                setSelectedAttributeOptions(attributeOptions ? attributeOptions : []);
            } else {
                let attributeOptions = props.allAttributeOptions?.profileItemTypes
                    .find((x) => x.key === newValue.id)
                    ?.items.map((x) => {
                        return { id: x.key, label: x.name } as AutocompleteItem;
                    })
                    .sort((a, b) => a.label.localeCompare(b.label));
                setSelectedAttributeOptions(attributeOptions ? attributeOptions : []);
            }
            setSelectedAttribute(undefined);
            setSelectedLevel(undefined);
            let searchAttribute: SearchAttribute = {
                pitKey: newValue.id,
                id: props.attributeSearch.id,
            };
            props.handleAttributeSelected(searchAttribute);
        }
    };

    const onAttributeOptionChanged = (newValue: { label: string; id: number } | null) => {
        if (newValue && newValue.id > 0) {
            setSelectedAttribute(newValue);
            let levels = props.allAttributeOptions?.profileItemTypes
                .filter((x) => x.key === parentAttribute)[0]
                .itemLevels.map((x) => {
                    return { id: x.key, label: x.name } as AutocompleteItem;
                })
                .sort((a, b) => a.label.localeCompare(b.label));
            if (parentAttribute === 2) {
                levels = [];
            }
            setSelectedLevelOptions(levels ? levels : []);
            let searchAttribute: SearchAttribute = {
                pitKey: parentAttribute,
                piKey: newValue.id,
                pitlKey: selectedLevel?.id,
                id: props.attributeSearch.id,
            };
            props.handleAttributeSelected(searchAttribute);
        }
    };

    const onAttributeLevelChanged = (newValue: { label: string; id: number } | null) => {
        if (newValue && newValue.id > 0) {
            setSelectedLevel(newValue);
            let searchAttribute: SearchAttribute = {
                pitKey: parentAttribute,
                piKey: selectedAttribute?.id,
                pitlKey: newValue.id,
                id: props.attributeSearch.id,
            };
            props.handleAttributeSelected(searchAttribute);
        } else {
            setSelectedLevel(undefined);
            let searchAttribute: SearchAttribute = {
                pitKey: parentAttribute,
                piKey: selectedAttribute?.id,
                id: props.attributeSearch.id,
            };
            props.handleAttributeSelected(searchAttribute);
        }
    };

    const handleDeleteClick = (event: React.MouseEvent<HTMLElement>) => {
        props.handleDelete(props.attributeSearch.id);
    };

    return (
        <Grid container spacing={4} sx={{ width: "-webkit-fill-available" }}>
            <Grid item xs={12} md={6} lg={4}>
                <Autocomplete
                    fullWidth
                    size="small"
                    disabled={hasChanged}
                    disablePortal
                    id="combo-box-demo-1"
                    options={attributes}
                    onChange={(
                        event: React.SyntheticEvent<Element, Event>,
                        value: { label: string; id: number } | null
                    ) => onSelectChange(value)}
                    renderInput={(params) => <TextField {...params} label="Select item type" />}
                />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                {selectedAttributeOptions.length > 0 ? (
                    <Autocomplete
                        fullWidth={true}
                        size="small"
                        disablePortal
                        value={selectedAttribute}
                        id="combo-box-demo-2"
                        options={selectedAttributeOptions}
                        onChange={(
                            e: React.SyntheticEvent<Element, Event>,
                            value: { label: string; id: number } | null
                        ) => onAttributeOptionChanged(value)}
                        renderInput={(params) => <TextField {...params} label="Select item type" />}
                    />
                ) : (
                    <CclButton
                        aria-label="delete"
                        onClick={handleDeleteClick}
                        restricted={false}
                        mode={"primary"}
                        sx={{ height: "100%" }}
                    >
                        Delete
                    </CclButton>
                )}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                {selectedLevelOptions && selectedLevelOptions.length > 0 ? (
                    <Stack direction={"row"} spacing={2} padding={0}>
                        <Autocomplete
                            fullWidth
                            size="small"
                            disablePortal
                            value={selectedLevel}
                            onChange={(
                                e: React.SyntheticEvent<Element, Event>,
                                value: { label: string; id: number } | null
                            ) => onAttributeLevelChanged(value)}
                            id="combo-box-demo-3"
                            options={selectedLevelOptions}
                            renderInput={(params) => (
                                <TextField {...params} label="Select item type" />
                            )}
                        />
                        <CclButton
                            aria-label="delete"
                            onClick={handleDeleteClick}
                            restricted={false}
                            mode={"primary"}
                        >
                            Delete
                        </CclButton>
                    </Stack>
                ) : selectedAttributeOptions.length !== 0 ? (
                    <CclButton
                        aria-label="delete"
                        onClick={handleDeleteClick}
                        restricted={false}
                        mode={"primary"}
                        sx={{ height: "100%" }}
                    >
                        Delete
                    </CclButton>
                ) : null}
            </Grid>
        </Grid>
    );
};

export default AdvancedSearchAttributeListItem;
