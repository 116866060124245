import * as React from "react";
import { Alert, Card, CardContent, CardHeader, Stack } from "@mui/material";
import { GcSystem } from "../../../../services/types/cclGrandCentralApiTypes";
import SystemApplicationDetail from "./systemApplicationDetail";
import AddApplicationDialog from "./addApplicationDialog";
import CclRestrictedButton from "../../../../components/common/cclButtons/cclRestrictedButton";

export type SystemApplicationsProps = {
    system: GcSystem;
    readonly: boolean;
};

export const SystemApplications: React.FC<SystemApplicationsProps> = (props) => {
    const [showAddApplication, setShowAddApplication] = React.useState<boolean>(false);

    return (
        <>
            <AddApplicationDialog
                open={showAddApplication}
                onClose={() => setShowAddApplication(false)}
                systemId={props.system.systemId}
            />
            <Card variant="outlined" sx={{ width: 1, height: 1 }}>
                <CardHeader
                    title={"Applications"}
                    action={
                        props.readonly ? null : (
                            <CclRestrictedButton onClick={() => setShowAddApplication(true)}>
                                Add Application
                            </CclRestrictedButton>
                        )
                    }
                />
                <CardContent>
                    <Stack spacing={1}>
                        {props.system.applications && props.system.applications.length > 0 ? (
                            props.system.applications.map((app, idx) => (
                                <SystemApplicationDetail
                                    key={app.applicationId}
                                    applicationId={app.applicationId}
                                    systemId={props.system.systemId}
                                    systemName={props.system.systemName}
                                    readonly={props.readonly}
                                    expanded={idx === 0}
                                />
                            ))
                        ) : (
                            <Alert severity="warning">No Applications Found</Alert>
                        )}
                    </Stack>
                </CardContent>
            </Card>
        </>
    );
};

export default SystemApplications;
