import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'
import { getDateStringAdjusted } from '../../utilities/dateHelpers';

// Define a type for the slice state
export interface RegistrationListConfigState {
  StartDate: string,
  EndDate: string,
  EmailSearch: string,
  IsDateSearchActive: boolean
};

// Define the initial state using that type
const initialState: RegistrationListConfigState = {
  StartDate: getDateStringAdjusted(-5),
  EndDate: getDateStringAdjusted(30),
  EmailSearch: '',
  IsDateSearchActive: true
};

export const registrationListConfigSlice = createSlice({
  name: 'registrationListConfig',
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    updateRegistrationSearchParams: (state, action: PayloadAction<RegistrationListConfigState>) => {
      state.StartDate = action.payload.StartDate;
      state.EndDate = action.payload.EndDate;
      state.EmailSearch = action.payload.EmailSearch;
      state.IsDateSearchActive = action.payload.IsDateSearchActive;
    }
  }
})

export const { updateRegistrationSearchParams } = registrationListConfigSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectRegistrationRegistrationListConfig = (state: RootState) => state;
export default registrationListConfigSlice.reducer;
