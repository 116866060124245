import * as React from "react";
import { Box, Stack, Typography } from "@mui/material";
import FastAccessSegment from "./fastAccessSegment";
import { useNavigate } from "react-router-dom";
import { useLazyGetResourceByEmailQuery } from "../../../services/cclTokenedResourceApi";
import { useSearchEnterpriseUsersMutation } from "../../../services/cclTokenedEnterpriseUserApi";
import { useGetCclEventsMutation } from "../../../services/cclTokenedSessionApi";
import useLogAccessEvent from "../../../hooks/useLogAccessEvent";

interface FastAccessProps {}

const FastAccess: React.FC<FastAccessProps> = () => {
    const navigate = useNavigate();
    const gridItemWidth: number = 20;
    const gridTitleWidth: number = 15;

    const [tabIndex, setTabIndex] = React.useState<number>(0);

    const [runSearch, { data: resources, isLoading: resourceLoading, isError: resourceError }] =
        useLazyGetResourceByEmailQuery();

    const [runUserSearch, { data: userData, isLoading: userLoading, isError: userError }] =
        useSearchEnterpriseUsersMutation();

    const [
        runSessionSearch,
        { data: sessionData, isLoading: sessionLoading, isError: sessionError },
    ] = useGetCclEventsMutation();

    const { logEvent } = useLogAccessEvent();

    const [resourceNotFound, setResouceNotFound] = React.useState<boolean>(false);
    const [userNotFound, setUserNotFound] = React.useState<boolean>(false);
    const [sessionNotFound, setSessionNotFound] = React.useState<boolean>(false);

    const [isBio, setIsBio] = React.useState<boolean>(false);
    const [searchTerm, setSearchTerm] = React.useState<string>("");

    React.useEffect(() => {
        if (resources && resources[0]) {
            setResouceNotFound(false);
            if (resources.length > 1) {
                navigate(`/resources/${isBio ? 2 : 1}?searchparam=${encodeURI(searchTerm)}`);
                setSearchTerm("");
            } else {
                const resourceUrl: string =
                    tabIndex > -1
                        ? `/resource/${resources[0].pkey}/${tabIndex}`
                        : `/resource/${resources[0].pkey}/0`;
                navigate(resourceUrl);
            }
        } else if (resources && !resources[0]) {
            setResouceNotFound(true);
        }

        if (userData && userData[0]) {
            setUserNotFound(false);
            if (userData.length > 1) {
                navigate(`/users?searchparam=${encodeURI(searchTerm)}`);
            } else {
                const userUrl: string =
                    tabIndex > -1
                        ? `/user/?email=${encodeURI(userData[0].email)}&imkey=${
                              userData[0].imKey
                          }&firstName=${userData[0].firstName}&lastName=${
                              userData[0].lastName
                          }&tabIndex=${tabIndex}`
                        : `/user?email=${encodeURI(userData[0].email)}&imkey=${
                              userData[0].imKey
                          }&firstName=${userData[0].firstName}&lastName=${userData[0].lastName}`;
                navigate(userUrl);
            }
        } else if (userData && !userData[0]) {
            setUserNotFound(true);
        }

        if (sessionData && sessionData[0]) {
            setSessionNotFound(false);
            if (sessionData.length > 1) {
                setSessionNotFound(true);
                return;
            }
            const sessionUrl: string =
                tabIndex > -1
                    ? `/session/${sessionData[0].eventnumber}/${tabIndex}`
                    : `/session/${sessionData[0].eventnumber}`;
            navigate(sessionUrl);
        } else if (sessionData && !sessionData[0]) {
            setSessionNotFound(true);
        }
        // eslint-disable-next-line
    }, [resources, userData, sessionData]);

    const resourceCallback = (email: string, tabIndex: number) => {
        logEvent("UserExecutedFastAccessLink", null);
        setTabIndex(tabIndex);
        setIsBio(false);
        if (validateEmail(email)) {
            setSearchTerm(email);
            runSearch(email);
        } else {
            setSearchTerm("");
            navigate(`/resources/1?searchparam=${encodeURI(email)}`);
        }
    };

    const userCallback = (user: string, tabIndex: number) => {
        logEvent("UserExecutedFastAccessLink", null);
        setTabIndex(tabIndex);
        const payload = {
            keywords: [user],
        };

        if (validateEmail(user)) {
            setSearchTerm(user);
            runUserSearch(payload);
        } else {
            setSearchTerm("");
            navigate(`/users?searchparam=${encodeURI(user)}`);
        }
    };

    const sessionCallback = (sessionId: string, tabIndex: number) => {
        logEvent("UserExecutedFastAccessLink", null);
        setTabIndex(tabIndex);
        const payload = {
            keyword: sessionId,
        };
        runSessionSearch(payload);
    };

    const registrationCallback = (email: string, tabIndex: number) => {
        logEvent("UserExecutedFastAccessLink", null);
        setTabIndex(0);
        navigate(`/registrations/${encodeURI(email)}`);
    };

    const productSalesCallback = (email: string, tabIndex: number) => {
        logEvent("UserExecutedFastAccessLink", null);
        setTabIndex(0);
        navigate(`/assessments/3?searchparam=${encodeURI(email)}`);
    };

    const validateEmail = (email: string) => {
        const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (email.match(validRegex)) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <Box sx={{ border: "1px solid #E0E0E0", borderRadius: "4px", padding: 2 }}>
            <Stack direction={"column"}>
                <Typography sx={{ marginLeft: "5px" }} variant="h6" fontWeight={"bold"}>
                    Fast Access
                </Typography>
                <FastAccessSegment
                    title={"Session"}
                    label={"Enter PR Code or ID"}
                    GridItemWidth={gridItemWidth}
                    GridTitleWidth={gridTitleWidth}
                    callbackFunction={sessionCallback}
                    isLoading={sessionLoading}
                    isError={sessionError || sessionNotFound}
                    buttons={[
                        { label: "Open", tabIndex: 0, mode: "primary" },
                        { label: "Assessments", tabIndex: 10, mode: "secondary" },
                        { label: "Files", tabIndex: 7, mode: "secondary" },
                        { label: "Resources", tabIndex: 5, mode: "secondary" },
                        { label: "Documents", tabIndex: 6, mode: "secondary" },
                    ]}
                />

                <FastAccessSegment
                    title={"Registration"}
                    label={"Enter Email"}
                    GridItemWidth={gridItemWidth}
                    GridTitleWidth={gridTitleWidth}
                    callbackFunction={registrationCallback}
                    isLoading={false}
                    isError={false}
                    buttons={[{ label: "Search", tabIndex: -1, mode: "primary" }]}
                />

                <FastAccessSegment
                    title={"Product Sale"}
                    label={"Enter Email"}
                    GridItemWidth={gridItemWidth}
                    GridTitleWidth={gridTitleWidth}
                    callbackFunction={productSalesCallback}
                    isLoading={false}
                    isError={false}
                    buttons={[{ label: "Search", tabIndex: -1, mode: "primary" }]}
                />

                <FastAccessSegment
                    title={"Resource"}
                    label={"Enter Email"}
                    GridItemWidth={gridItemWidth}
                    GridTitleWidth={gridTitleWidth}
                    callbackFunction={resourceCallback}
                    isLoading={isBio ? false : resourceLoading}
                    isError={isBio ? false : resourceError || resourceNotFound}
                    buttons={[
                        { label: "Open", tabIndex: 0, mode: "primary" },
                        { label: "Attributes", tabIndex: 1, mode: "secondary" },
                        { label: "Biography", tabIndex: 2, mode: "secondary" },
                        { label: "Work Pools", tabIndex: 3, mode: "secondary" },
                    ]}
                />

                <FastAccessSegment
                    title={"User"}
                    label={"Enter Email"}
                    GridItemWidth={gridItemWidth}
                    GridTitleWidth={gridTitleWidth}
                    callbackFunction={userCallback}
                    isLoading={userLoading}
                    isError={userError || userNotFound}
                    buttons={[
                        { label: "Open", tabIndex: 2, mode: "primary" },
                        { label: "Accounts", tabIndex: 0, mode: "secondary" },
                        { label: "Logins", tabIndex: 3, mode: "secondary" },
                        { label: "Emails", tabIndex: 1, mode: "secondary" },
                    ]}
                />
            </Stack>
        </Box>
    );
};

export default FastAccess;
